import { useServices } from '@/hooks';
import { css } from '@emotion/css';
import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { Navigate, useLocation } from 'react-router';
import { RouteTemplates } from '../../RouteTemplates';
import { isSxArray } from '../../utils';
import { Welcome } from './Welcome';

interface LocationState {
  referrer?: string;
}

export interface WelcomePageProps {
  sx?: SxProps;
  className?: string;
}

export const WelcomePage = observer(({ sx = [], className }: WelcomePageProps) => {
  const { user } = useServices();
  const childrenClassName = css([{ width: '100%', height: '100%' }]);
  const location = useLocation();
  const state = (location.state as LocationState) ?? {};
  const referrer = state?.referrer;

  if (user.currentUser != null) {
    return <Navigate to={referrer ?? RouteTemplates.planners} replace />;
  }

  return (
    <Box
      className={className}
      sx={[
        {
          width: '100%',
          height: '100%'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <Welcome className={childrenClassName} />
    </Box>
  );
});
