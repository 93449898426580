import { Day } from '@/models';
import { Term } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/term_pb';
import { List, ListItem, ListItemText, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { ScheduleCycleTermsListAddItem } from './ScheduleCycleTermsListAddItem';
import { ScheduleCycleTermsListItem } from './ScheduleCycleTermsListItem';

export interface ScheduleCycleTermsListProps {
  sx?: SxProps;
  className?: string;
  terms: Term[];
  minDate: Date;
  maxDate: Date;
  onAddOrUpdateTerm: (id: string | undefined, name: string, startDay: Day, endDay: Day) => void;
  onDeleteTerm: (id: string) => void;
  isInitialTerms: boolean;
  isReadOnly?: boolean;
}

export const ScheduleCycleTermsList = observer(
  ({
    sx = [],
    className,
    terms,
    onAddOrUpdateTerm,
    onDeleteTerm,
    minDate,
    maxDate,
    isInitialTerms,
    isReadOnly = false
  }: ScheduleCycleTermsListProps) => {
    const strings = LocalizedStrings.scheduleCycle.term.list;

    return (
      <List sx={sx} className={className} dense>
        <Typography
          variant="subtitle2"
          sx={{
            color: (theme) => theme.palette.text.secondary,
            px: 2
          }}
        >
          {strings.title()}
        </Typography>
        {isInitialTerms && (
          <Typography
            variant="caption"
            sx={{
              color: (theme) => theme.palette.text.secondary,
              px: 2
            }}
          >
            {strings.subtitle()}
          </Typography>
        )}
        {terms.map((term) => (
          <ScheduleCycleTermsListItem
            key={term.id}
            term={term}
            onEdit={(id, name, startDay, endDay) => onAddOrUpdateTerm(id, name, startDay, endDay)}
            onDelete={(id) => onDeleteTerm(id)}
            minDate={minDate}
            maxDate={maxDate}
            isReadOnly={isReadOnly}
          />
        ))}
        {!isReadOnly && (
          <ScheduleCycleTermsListAddItem
            onCreate={(name, startDay, endDay) => onAddOrUpdateTerm(undefined, name, startDay, endDay)}
            minDate={minDate}
            maxDate={maxDate}
          />
        )}
        {isReadOnly && terms.length === 0 && (
          <ListItem>
            <ListItemText primary={strings.emptyLabel()} slotProps={{ primary: { color: 'textSecondary' } }} />
          </ListItem>
        )}
      </List>
    );
  }
);
