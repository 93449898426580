import { LoginPage, NoPlannerPage, OnboardingScreen, StartTrialPage, WelcomePage } from '@/components';
import { Navigate, useLocation, useRoutes } from 'react-router';
import { BackgroundLocationState } from '../BackgroundLocationState';
import { InitialStateLayout } from './InitialStateLayout';
import { OnboardedRoute } from './OnboardedRoute';
import { OnboardedRoutes } from './OnboardedRoutes';
import { OnboardingLayout } from './OnboardingLayout';

export function MainRoutes() {
  const location = useLocation();
  const state = (location.state ?? {}) as BackgroundLocationState;

  return useRoutes(
    [
      { path: 'start-trial', element: <StartTrialPage /> },
      {
        path: '',
        element: <InitialStateLayout />,
        children: [
          { path: 'login', element: <LoginPage /> },

          {
            path: 'onboarding',
            element: (
              <OnboardingLayout>
                <OnboardingScreen />
              </OnboardingLayout>
            )
          },
          { path: 'noPlanner', element: <NoPlannerPage /> },
          {
            path: 'welcome',
            element: <Navigate to="/" replace />
          },
          {
            path: '',
            element: <WelcomePage />
          },
          { path: '*', element: <OnboardedRoute />, children: OnboardedRoutes }
        ]
      }
    ],
    state.backgroundLocation ?? location
  );
}
