import { useNavigateAsync, useServices, useSync } from '@/hooks';
import { SchoolInformation } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/school_information_pb';
import { SchoolSharingMode } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/school_sharing_mode_pb';
import { NotEntitledReason } from '@buf/studyo_studyo-today-subscriptions.bufbuild_es/studyo/today/subscriptions/v1/resources/not_entitled_reason_pb';
import { Browser } from '@capacitor/browser';
import { Box } from '@mui/material';
import { chain } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';
import LocalizedStrings from 'strings';
import { SchoolEntitlementLoadable } from '../../stores';
import { UserDashboardBanner } from '../shared';
import { SubscriptionsDialog } from './SubscriptionsDialog';

interface LocationState {
  subscriptionAlertBannerShowSubscriptionDialog?: string;
}

export interface SubscriptionAlertBannerProps {
  plannerId: string;
}

export const SubscriptionAlertBanner = observer(({ plannerId }: SubscriptionAlertBannerProps) => {
  const { userStore } = useServices();
  const planner = userStore.getPlannerForId(plannerId);
  const strings = LocalizedStrings.subscriptions.schoolEntitlementBanner;

  const location = useLocation();
  const state = (location.state || {}) as LocationState;
  const navigate = useNavigateAsync();

  const hasSubscription = userStore.subscription.hasData ? userStore.subscription.data != null : false;

  const sharedSchools = useMemo(
    () =>
      chain(planner?.schoolIds ?? [])
        .map((id) => userStore.getSchoolForId(id))
        .compact()
        .filter(
          (school) =>
            school.school != null &&
            // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
            school.school.sharingMode === SchoolSharingMode.SHARED &&
            !school.school.isArchived &&
            !(planner?.ignoredSchoolIds.includes(school.school.id) ?? true)
        )
        .value(),
    [planner?.schoolIds]
  );

  const schoolEntitlementsLoadables = useMemo(
    () =>
      new Map<string, SchoolEntitlementLoadable>(
        sharedSchools.map((school) => [
          school.school!.id,
          userStore.getSchoolSharedSchoolEntitlement(school.school!.id)
        ])
      ),
    [sharedSchools]
  );

  async function reloadSchoolEntitlementsLoadables() {
    await Promise.all(Array.from(schoolEntitlementsLoadables.values()).map((l) => l.fetch(true)));
  }

  useSync(`subscription-alert-banner-${plannerId}`, () => reloadSchoolEntitlementsLoadables(), [
    schoolEntitlementsLoadables
  ]);

  useEffect(() => {
    void reloadSchoolEntitlementsLoadables();
  }, [schoolEntitlementsLoadables]);

  async function showManageSubscription() {
    const url = await userStore.getManageSubscriptionUrl(window.location.href);
    await Browser.open({ url });
  }

  function showSubscribeDialog(schoolId: string) {
    const newState: LocationState = { ...state, subscriptionAlertBannerShowSubscriptionDialog: schoolId };
    void navigate(location, { state: newState });
  }

  function renderSchoolBanner(school: SchoolInformation) {
    const id = school.school!.id;
    const loadable = schoolEntitlementsLoadables.get(id);

    if (loadable?.hasData !== true) {
      return null;
    }

    const canManageSubscription = school.administrators.some((a) => a.userId === userStore.user.userId);
    const schoolEntitlement = loadable.data;
    const schoolName = school.school!.name;

    if (schoolEntitlement.isTrial) {
      return (
        <UserDashboardBanner
          className="banner"
          key={id}
          identifier={`shared-school-entitlement-${id}`}
          isVisible
          canDismissPermanently={false}
          title={strings.trial(schoolName)}
          color="info"
          actions={
            canManageSubscription
              ? [{ case: 'text', value: strings.manageSubscriptionsButtonTitle(), onClick: showManageSubscription }]
              : undefined
          }
        />
      );
    }

    if (!schoolEntitlement.isEntitled) {
      return (
        <UserDashboardBanner
          key={id}
          className="banner"
          identifier={`shared-school-entitlement-${id}`}
          isVisible
          canDismissPermanently={false}
          title={messageForNotEntitled(schoolName, schoolEntitlement.notEntitledReasons)}
          color="error"
          actions={
            canManageSubscription
              ? hasSubscription
                ? [{ case: 'text', value: strings.manageSubscriptionsButtonTitle(), onClick: showManageSubscription }]
                : [
                    {
                      case: 'text',
                      value: strings.subscribeButtonTitle(),
                      onClick: () => showSubscribeDialog(school.school?.id ?? '')
                    }
                  ]
              : undefined
          }
        />
      );
    }

    return null;
  }

  return (
    <Box>
      {sharedSchools.map(renderSchoolBanner)}

      {state.subscriptionAlertBannerShowSubscriptionDialog != null && <SubscriptionsDialog isOpen={true} />}
    </Box>
  );
});

function messageForNotEntitled(schoolName: string, reasons: NotEntitledReason[]): string {
  const strings = LocalizedStrings.subscriptions.schoolEntitlementBanner;

  // For now, we will only display the first reason, if more
  switch (reasons[0]) {
    case NotEntitledReason.EXPIRED_TRIAL:
      return strings.expiredTrial(schoolName);

    case NotEntitledReason.NO_SUBSCRIPTION:
    case NotEntitledReason.CANCELED_SUBSCRIPTION:
      return strings.noSubscription(schoolName);

    case NotEntitledReason.EXPIRED_SUBSCRIPTION:
      return strings.expiredSubscription(schoolName);

    case NotEntitledReason.LIMITS_EXCEEDED:
      return strings.limitsExceeded(schoolName);

    case NotEntitledReason.UNSPECIFIED:
    case null:
      return strings.invalidSubscription(schoolName);
  }
}
