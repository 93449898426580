import { ServiceContainer } from '@/providers';
import { UserPersona } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/user_persona_pb';
import { captureException } from '@sentry/react';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { AppBaseOnboardingScreenViewModel, BaseOnboardingScreenViewModel } from './BaseOnboardingScreenViewModel';
import { OnboardingViewModel } from './OnboardingViewModel';

export interface UserPersonaStepViewModel extends BaseOnboardingScreenViewModel {
  readonly name: string;
  readonly pictureUrl?: string;
  persona?: UserPersona;
  readonly error?: string;
  showUseCode(): void;
}

export class AppUserPersonaStepViewModel extends AppBaseOnboardingScreenViewModel implements UserPersonaStepViewModel {
  @observable private _persona: UserPersona | undefined;
  @observable private _error: string | undefined;

  constructor(
    onboardingState: OnboardingViewModel,
    private readonly _user = ServiceContainer.services.user,
    private readonly _userStore = ServiceContainer.services.userStore,
    private readonly _analytics = ServiceContainer.services.analytics,
    private readonly _localization = ServiceContainer.services.localization
  ) {
    super(onboardingState);
    this._persona = this._user.currentUser?.persona;
    makeObservable(this);
  }

  @computed
  get name(): string {
    return this._user.currentUser?.fullName ?? '';
  }

  @computed
  get pictureUrl(): string | undefined {
    return this._user.currentUser?.pictureUrl;
  }

  @computed
  get persona(): UserPersona | undefined {
    return this._persona;
  }

  set persona(value: UserPersona | undefined) {
    this._persona = value;
    void this._analytics.logAdConversionOnce('persona-selected');
  }

  @computed
  get error(): string | undefined {
    return this._error;
  }

  @computed
  get canSubmit(): boolean {
    return !this._isSubmitting && this.persona != null && this._persona !== UserPersona.UNSPECIFIED;
  }

  @action
  async submit() {
    if (this._persona == null) {
      throw new Error('Should not be able to submit');
    }

    this._isSubmitting = true;
    this._error = undefined;

    try {
      await this._userStore.assignPersona(this.persona!);
      this._onboardingState.userPersona = this._persona!;

      switch (this._persona) {
        case UserPersona.STUDENT:
          this._onboardingState.displayScreenOfKind('student-options');
          break;

        case UserPersona.PARENT:
          this._onboardingState.displayScreenOfKind('parent-options');
          break;

        default:
          this._onboardingState.displayScreenOfKind('teacher-options');
          break;
      }
    } catch (e) {
      captureException(e);
      const error = e as Error;
      runInAction(() => (this._error = error.message));
    } finally {
      runInAction(() => (this._isSubmitting = false));
    }
  }

  showUseCode() {
    this._onboardingState.displayScreenOfKind('use-code');
  }
}
