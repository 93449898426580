import { AuthProvider } from '@/models';
import { AutoDetectPersonaAndCreatePlannerResponse, UserTransportService } from '@/transports';
import { UserProfile } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/user_profile_pb';
import { User as FirebaseUser } from '@capacitor-firebase/authentication';
import { captureException } from '@sentry/react';
import { action, autorun, computed, makeObservable, observable, runInAction } from 'mobx';
import { AnalyticsService, AuthenticationService, FirebaseContract, SignInActionKind, UserService } from '../contracts';

export class AppAuthenticationService implements AuthenticationService {
  @observable private _isInitialized = false;
  @observable private _error: Error | undefined;

  constructor(
    private readonly _analytics: AnalyticsService,
    private readonly _firebase: FirebaseContract,
    private readonly _user: UserService,
    private readonly _userTransport: UserTransportService
  ) {
    makeObservable(this);

    autorun(() => {
      if (this._firebase.isInitialized) {
        void this.onInit(this._firebase.user);
      }
    });
  }

  @computed
  get isInitialized(): boolean {
    return this._isInitialized;
  }

  @computed
  get error(): Error | undefined {
    return this._error;
  }

  @action
  signIn(authProvider: AuthProvider, actionKind: SignInActionKind): Promise<void> {
    switch (actionKind) {
      case 'link':
        return this._firebase.linkCurrentUser(authProvider);
      case 'sign-in':
        return this._firebase.signIn(authProvider);
    }
  }

  @action
  async signOut(): Promise<void> {
    this._error = undefined;

    try {
      await this._firebase.signOut();
    } catch (error) {
      captureException(error);
      runInAction(() => (this._error = error as Error));
    }
  }

  getCurrentUserProfile(): Promise<UserProfile> {
    return this._userTransport.getCurrentUserProfile();
  }

  autoDetectPersonaAndCreatePlanner(): Promise<AutoDetectPersonaAndCreatePlannerResponse> {
    return this._userTransport.autoDetectPersonaAndCreatePlanner();
  }

  private async onInit(firebaseUser: FirebaseUser | undefined): Promise<void> {
    try {
      runInAction(() => {
        this._isInitialized = false;
        this._error = undefined;
      });

      await this.updateUser(firebaseUser);

      runInAction(() => (this._isInitialized = true));
    } catch (error) {
      captureException(error);
      runInAction(() => (this._error = error as Error));
    }
  }

  @action
  private async updateUser(firebaseUser: FirebaseUser | undefined) {
    this._error = undefined;
    let user: UserProfile | undefined = undefined;

    if (firebaseUser != null) {
      try {
        let userProfile = await this.getCurrentUserProfile();
        const { personaAutoDetectedAndPlannerCreated, sharedParticipatingSchoolIds } =
          await this.autoDetectPersonaAndCreatePlanner();

        if (personaAutoDetectedAndPlannerCreated) {
          // If a planner was created, we fetch a new UserProfile to get the persona that was set.
          userProfile = await this.getCurrentUserProfile();
          await this._analytics.logOnboardingCompleted(sharedParticipatingSchoolIds);
        }

        user = userProfile;
      } catch (e) {
        captureException(e);
        const error = e as Error;
        const message = 'Failed to build User from Firebase: ' + error.message;
        this._firebase.logError(message);
        runInAction(() => (this._error = new Error(message)));
      }
    }

    runInAction(() => {
      this._user.currentUser = user;
    });
  }
}
