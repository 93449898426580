import { useActivePlannerId, useNavigateAsync, useServices } from '@/hooks';
import { UserDashboardCalendarItemState, UserDashboardCalendarPlannedWorkInfo } from '@/viewmodels';
import { Box, CardActionArea, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useLocation } from 'react-router';
import { BackgroundLocationState } from '../../../../BackgroundLocationState';
import { isSxArray } from '../../../../utils';
import { MultilineTooltipTitle } from '../../../utils';
import { ContextMenuHandler } from '../../ContextMenuHandler';
import { PlannedWorkIcon } from '../../PlannedWorkIcon';
import { formatCalendarItemTimesLong, formatItemTime } from '../shared';

export interface UserDashboardCalendarMonthPlannedWorkViewProps {
  sx?: SxProps;
  className?: string;
  plannedWork: UserDashboardCalendarPlannedWorkInfo;
}

export const UserDashboardCalendarMonthPlannedWorkView = observer(
  ({ sx = [], className, plannedWork }: UserDashboardCalendarMonthPlannedWorkViewProps) => {
    const { route } = useServices();
    const plannerId = useActivePlannerId();
    const theme = useTheme();
    const color = plannedWork.color || theme.palette.action.focus;
    const [showTooltip, setShowTooltip] = useState(false);

    const location = useLocation();
    const state = (location.state ?? {}) as BackgroundLocationState;
    const navigate = useNavigateAsync();

    function showWorkDetails() {
      const newLocation = route.resolvePlannerWorkLocation(plannerId, plannedWork.workId);
      void navigate(newLocation, { state: { backgroundLocation: location } });
    }

    return (
      <ContextMenuHandler actions={plannedWork.contextMenuActions()}>
        {(contextMenuHandler) => (
          <Tooltip
            title={
              <MultilineTooltipTitle
                lines={[plannedWork.title, formatCalendarItemTimesLong(plannedWork.startTime, plannedWork.endTime)]}
              />
            }
            disableInteractive
            disableHoverListener
            open={showTooltip && state.backgroundLocation == null}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            <Box
              className={className}
              sx={[
                {
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'stretch'
                },
                ...(isSxArray(sx) ? sx : [sx])
              ]}
            >
              <CardActionArea
                sx={{ borderRadius: 0.5, px: 0.5, py: '2px' }}
                onClick={showWorkDetails}
                onContextMenu={contextMenuHandler}
              >
                <Stack
                  direction="row"
                  spacing={0.5}
                  sx={{
                    alignItems: 'center'
                  }}
                >
                  <PlannedWorkIcon color={color} icon={plannedWork.icon} sx={{ height: 20 }} />

                  <Typography
                    noWrap
                    color={getColorForTitle(plannedWork.state)}
                    sx={{
                      fontSize: 12,
                      flex: 1
                    }}
                  >
                    {plannedWork.title}
                  </Typography>
                  <Typography
                    variant="caption"
                    noWrap
                    sx={{
                      color: theme.palette.text.secondary
                    }}
                  >
                    {formatItemTime(plannedWork.startTime)}
                  </Typography>
                </Stack>
              </CardActionArea>
            </Box>
          </Tooltip>
        )}
      </ContextMenuHandler>
    );
  }
);

function getColorForTitle(state: UserDashboardCalendarItemState) {
  switch (state) {
    case 'completed':
      return 'textSecondary';
    case 'active':
      return 'primary';
    case 'late':
      return 'error';
    default:
      return 'textPrimary';
  }
}
