import { useActivePlannerId, useNavigateAsync, useServices } from '@/hooks';
import { ConnectedAppKind } from '@/models';
import { ConnectedAppUserDashboardViewModel } from '@/viewmodels';
import SyncErrorIcon from '@mui/icons-material/ErrorRounded';
import EditIcon from '@mui/icons-material/ManageAccountsRounded';
import DisconnectIcon from '@mui/icons-material/RemoveCircleRounded';
import SubscribeIcon from '@mui/icons-material/SubscriptionsRounded';
import WarningIcon from '@mui/icons-material/WarningRounded';
import {
  Box,
  CircularProgress,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { isSxArray } from '../../utils';
import { SubscriptionsDialog } from '../subscriptions';
import { ResponsiveButton } from '../utils';
import { ConnectedAppConnectButton } from './ConnectedAppConnectButton';
import { ConnectedAppEditDialog } from './ConnectedAppEditDialog';
import { ConnectedAppIcon } from './ConnectedAppIcon';
import { SyncInfo } from './SyncInfo';

interface LocationState {
  connectedAppShowEdit?: { kind: ConnectedAppKind; id: string; startSync: boolean };
  connectedAppShowSubscription?: { kind: ConnectedAppKind; id: string };
}

export interface ConnectedAppProps {
  viewModel: ConnectedAppUserDashboardViewModel;
  isHeader: boolean;
  className?: string;
  sx?: SxProps;
}

export const ConnectedApp = observer(({ className, sx = [], viewModel, isHeader }: ConnectedAppProps) => {
  const { localization, userStore } = useServices();
  const strings = localization.localizedStrings.connectedApps;
  const plannerId = useActivePlannerId();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const location = useLocation();
  const state = (location.state ?? {}) as LocationState;
  const navigate = useNavigateAsync();

  const [isConnecting, setIsConnecting] = useState(false);

  function subscribe() {
    const newState: LocationState = {
      ...state,
      connectedAppShowSubscription: { kind: viewModel.kind, id: viewModel.userDashboardInfo.id }
    };
    void navigate(location, { state: newState });
  }

  function edit(startSync: boolean) {
    const newState: LocationState = {
      ...state,
      connectedAppShowEdit: { kind: viewModel.kind, id: viewModel.userDashboardInfo.id, startSync }
    };
    void navigate(location, { state: newState });
  }

  async function connect() {
    setIsConnecting(true);
    await viewModel.connect();
    setIsConnecting(false);
  }

  const title = useMemo(() => {
    let title = '';

    if (isHeader) {
      return strings.connectedAppTitle(viewModel.kind);
    } else if (viewModel.userDashboardInfo.kind === 'school') {
      const school = userStore.getSchoolForId(viewModel.userDashboardInfo.id)?.school;
      if (school == null) {
        return '';
      }

      title = school.subtitle.length > 0 ? `${school.name} (${school.subtitle})` : school.name;
    } else {
      title = userStore.getPlannerForId(viewModel.userDashboardInfo.id)!.title;
    }

    return viewModel.isLegacy ? strings.legacyConnectionTitle(title) : title;
  }, [isHeader, viewModel]);

  return (
    <>
      <Stack
        direction="row"
        className={className}
        sx={[
          {
            alignItems: 'center',
            justifyContent: 'space-between'
          },
          ...(isSxArray(sx) ? sx : [sx])
        ]}
      >
        <ListItem sx={{ flex: 1 }} disableGutters={isMobile} dense={!isHeader}>
          <ListItemIcon>
            {(isHeader || !viewModel.isLegacy) && (
              <ConnectedAppIcon kind={viewModel.kind} style={{ opacity: isHeader ? 1 : 0 }} />
            )}
            {!isHeader && viewModel.isLegacy && <WarningIcon sx={{ width: 32 }} fontSize="medium" color="warning" />}
          </ListItemIcon>

          <ListItemText
            sx={{ mr: 1 }}
            primary={
              <Stack
                direction="row"
                spacing={0.5}
                sx={{
                  alignItems: 'center'
                }}
              >
                {viewModel.hasSyncError ? (
                  <SyncErrorIcon color="error" fontSize="small" />
                ) : (
                  viewModel.warnings.length > 0 && <WarningIcon color="warning" fontSize="small" />
                )}
                <Typography
                  variant={isHeader ? 'body1' : 'subtitle2'}
                  sx={{
                    fontWeight: isHeader ? '600' : undefined
                  }}
                >
                  {title}
                </Typography>
              </Stack>
            }
            slotProps={{
              primary: { component: 'span' },
              secondary: { component: 'span' }
            }}
            secondary={
              viewModel.warnings.length > 0 ? (
                viewModel.warnings.map((warning, i) => (
                  <Typography key={`warning-${i}`} variant="inherit">
                    {warning}
                  </Typography>
                ))
              ) : viewModel.hasSyncError ? (
                strings.syncError
              ) : viewModel.canSync ? (
                <SyncInfo viewModel={viewModel.syncInfo} disabled={viewModel.isApplying} textVariant="inherit" />
              ) : (
                strings.notConnected
              )
            }
          />
        </ListItem>

        {!viewModel.isReadOnly && (
          <Box>
            {!viewModel.isLegacy && !viewModel.hasSyncError ? (
              !viewModel.isEntitled ? (
                // Subscribe button
                <ResponsiveButton
                  smallScreenWidth="sm"
                  label={strings.subscribeLabel}
                  icon={<SubscribeIcon />}
                  variant="contained-grey"
                  color="primary"
                  disabled={!viewModel.isActionEnabled}
                  loading={viewModel.isApplying}
                  onClick={() => subscribe()}
                />
              ) : viewModel.canEdit ? (
                // Edit button
                <ResponsiveButton
                  smallScreenWidth="sm"
                  label={strings.editLabel}
                  icon={<EditIcon />}
                  variant="contained-grey"
                  color="primary"
                  disabled={!viewModel.isActionEnabled}
                  loading={viewModel.isApplying}
                  onClick={() => edit(false)}
                />
              ) : !viewModel.isInitialized || isConnecting ? (
                <CircularProgress size={18} />
              ) : (
                !viewModel.isConnected && (
                  // Connect button
                  <ConnectedAppConnectButton kind={viewModel.kind} onClick={() => void connect()} />
                )
              )
            ) : (
              <ResponsiveButton
                smallScreenWidth="sm"
                label={strings.disconnect}
                icon={<DisconnectIcon />}
                variant="contained-grey"
                color="error"
                disabled={!viewModel.isActionEnabled}
                loading={viewModel.isApplying}
                onClick={() => void viewModel.disconnect()}
              />
            )}
          </Box>
        )}
      </Stack>
      {state.connectedAppShowEdit != null &&
        state.connectedAppShowEdit.kind === viewModel.kind &&
        state.connectedAppShowEdit.id === viewModel.userDashboardInfo.id && (
          <ConnectedAppEditDialog
            isOpen={true}
            connectedAppKind={viewModel.kind}
            plannerId={plannerId}
            schoolId={viewModel.userDashboardInfo.kind === 'school' ? viewModel.userDashboardInfo.id : undefined}
            startSync={state.connectedAppShowEdit.startSync}
          />
        )}
      {state.connectedAppShowSubscription != null &&
        state.connectedAppShowSubscription.kind === viewModel.kind &&
        state.connectedAppShowSubscription.id === viewModel.userDashboardInfo.id && (
          <SubscriptionsDialog isOpen={true} />
        )}
    </>
  );
});
