import { minutesToMilliseconds } from 'date-fns';
import { reaction } from 'mobx';
import { CookiesService, EnvironmentService, FirebaseContract, UserService } from '../contracts';

export class AppCookiesService implements CookiesService {
  constructor(
    private readonly _environment: EnvironmentService,
    private readonly _firebase: FirebaseContract,
    private readonly _user: UserService
  ) {
    reaction(
      () => this._user.currentUser,
      () => void this.fetchFilesCookieIfNeeded()
    );

    // Updating cookies periodically every 15 minutes. Prevents the cookie from expiring if window is left open.
    // The cookie is valid for 50 minutes, but better be safe.
    setTimeout(() => void this.fetchCookiesIfNeeded(), minutesToMilliseconds(15));
  }

  async fetchCookiesIfNeeded(): Promise<void> {
    await Promise.all([this.fetchFilesCookieIfNeeded()]);
  }

  private async fetchFilesCookieIfNeeded() {
    await fetch(`${this._environment.fileHostBasename}/session`, {
      method: 'POST',
      credentials: 'include',
      headers: new Headers({
        Authorization: `Bearer ${this._firebase.idToken}`
      })
    });
  }
}
