import { Menu, MenuProps } from '@mui/material';
import { useBackButtonListener } from '../../hooks';

export type ActionMenuProps = MenuProps;

export const ActionMenu = (props: ActionMenuProps) => {
  useBackButtonListener((e) => {
    if (props.open) {
      e.stopPropagation();
      props.onClose?.({}, 'escapeKeyDown');
    }
  });

  return (
    <Menu {...props} slotProps={{ ...props.slotProps, paper: { ...props.slotProps?.paper, sx: { minWidth: 250 } } }} />
  );
};
