import { OnboardingStudentNoSchoolAccountViewModel } from '@/viewmodels';
import { ArrowBackRounded } from '@mui/icons-material';
import RetryIcon from '@mui/icons-material/RefreshRounded';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import LocalizedStrings from 'strings';

export interface OnboardingStudentNoSchoolFoundProps {
  sx?: SxProps;
  className?: string;
  viewModel: OnboardingStudentNoSchoolAccountViewModel;
}

export const OnboardingStudentNoSchoolFound = observer(
  ({ sx, className, viewModel }: OnboardingStudentNoSchoolFoundProps) => {
    const [isRetrying, setIsRetrying] = useState(false);
    const strings = LocalizedStrings.onboarding.studentNoSchoolFound;

    const retry = async () => {
      try {
        setIsRetrying(true);
        await viewModel.retry();
      } finally {
        setIsRetrying(false);
      }
    };

    return (
      <Box sx={sx} className={className} width="100%" display="flex" flexDirection="column">
        <Stack spacing={2}>
          <Stack spacing={1}>
            <Typography variant="h6">{strings.title()}</Typography>
            <Typography color="textSecondary">{strings.subtitle(viewModel.email)}</Typography>
          </Stack>

          <Stack spacing={1}>
            <LoadingButton
              loading={isRetrying}
              disabled={isRetrying}
              variant="contained"
              startIcon={<RetryIcon />}
              onClick={() => void retry()}
            >
              {strings.retryButtonTitle()}
            </LoadingButton>

            <Button disabled={isRetrying} startIcon={<ArrowBackRounded />} onClick={() => viewModel.goBack()}>
              {strings.backButtonTitle()}
            </Button>
          </Stack>
        </Stack>
      </Box>
    );
  }
);
