import { useServices } from '@/hooks';
import { ListItemButton, ListItemText } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router';

export interface UserDashboardPickerPlannerProps {
  id: string;
  title: string;
  sx?: SxProps;
  className?: string;
  onSelect: () => void;
  isSelected: boolean;
}

export const UserDashboardPickerPlanner = observer(
  ({ id, title, sx = [], className, onSelect, isSelected }: UserDashboardPickerPlannerProps) => {
    const { route } = useServices();
    const url = route.resolvePlannerLocation(id);

    return (
      <ListItemButton
        sx={{ ...sx, borderRadius: 1 }}
        className={className}
        component={Link}
        to={url}
        dense
        onClick={onSelect}
        selected={isSelected}
      >
        <ListItemText primary={title} />
      </ListItemButton>
    );
  }
);
