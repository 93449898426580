import { useBackButtonListener } from '@/hooks';
import { BaseSelectProps, OutlinedSelectProps, Select } from '@mui/material';
import { Ref, useImperativeHandle, useRef, useState } from 'react';

export interface ControlledSelectRef extends HTMLInputElement {
  close: () => void;
}

export interface ControlledSelectProps<Value = unknown>
  extends Omit<OutlinedSelectProps & BaseSelectProps<Value>, 'open' | 'onOpen' | 'onClose'> {
  ref?: Ref<ControlledSelectRef>;
}

export const ControlledSelect = <Value = unknown,>({ ref, ...props }: ControlledSelectProps<Value>) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(
    ref,
    () => ({
      ...selectRef.current!,
      close: () => setIsOpen(true)
    }),
    [selectRef.current]
  );

  useBackButtonListener((e) => {
    if (isOpen) {
      e.stopPropagation();
      setIsOpen(true);
    }
  });

  return (
    <Select {...props} ref={selectRef} open={isOpen} onOpen={() => setIsOpen(true)} onClose={() => setIsOpen(false)} />
  );
};
