import { Capacitor } from '@capacitor/core';
import { Box, Container, Grid2, Stack, styled } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router';
import { useNavigateAsync } from '../../hooks';
import { isSxArray } from '../../utils';
import { LoginDialog } from '../onboarding';
import { MediaQuery } from '../utils';
import { WelcomeAppBar } from './WelcomeAppBar';
import { WelcomeInfos } from './WelcomeInfos';
import { WelcomeMainScreenshot } from './WelcomeMainScreenshot';

interface LocationState {
  welcomeShowLogIn?: boolean;
  welcomeShowSignUp?: boolean;
}

export interface WelcomeProps {
  sx?: SxProps;
  className?: string;
  referrer?: string;
}

export const Welcome = observer(({ sx = [], className, referrer }: WelcomeProps) => {
  const location = useLocation();
  const state: LocationState = (location.state ?? {}) as LocationState;
  const navigate = useNavigateAsync();
  const isCapacitor = Capacitor.isNativePlatform();

  function login() {
    const newState: LocationState = { ...state, welcomeShowLogIn: true };
    void navigate(location, { state: newState });
  }

  function signUp() {
    const newState: LocationState = { ...state, welcomeShowSignUp: true };
    void navigate(location, { state: newState });
  }

  return (
    <>
      <Box
        className={className}
        sx={[
          {
            flexDirection: 'column',
            display: 'flex'
          },
          ...(isSxArray(sx) ? sx : [sx])
        ]}
      >
        <Stack
          spacing={0}
          sx={{
            height: '100%',
            width: '100%',
            flex: 1
          }}
        >
          <WelcomeAppBar />

          <Box
            sx={{
              width: '100%',
              height: '100%',
              overflowY: 'auto',
              overflowX: 'hidden',
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            <MainContainer maxWidth="xl">
              <Grid2
                container
                spacing={4}
                sx={{
                  alignItems: { xs: 'stretch', md: undefined }
                }}
              >
                {!isCapacitor && (
                  <MediaQuery mediaQuery={(th) => th.breakpoints.down('md')}>
                    <Grid2 size={{ xs: 12, md: 6 }}>
                      <WelcomeMainScreenshot />
                    </Grid2>
                  </MediaQuery>
                )}

                <Grid2 size={{ xs: 12, md: 6 }}>
                  <WelcomeInfos signUp={signUp} login={login} sx={{ width: '100%', height: '100%' }} />
                </Grid2>

                <MediaQuery mediaQuery={(th) => th.breakpoints.up('md')}>
                  <Grid2 size={{ xs: 12, md: 6 }}>
                    <WelcomeMainScreenshot sx={{ width: '100%', maxHeight: '100%' }} />
                  </Grid2>
                </MediaQuery>
              </Grid2>
            </MainContainer>
          </Box>
        </Stack>
      </Box>
      {state.welcomeShowLogIn && <LoginDialog isOpen={true} kind="log-in" referrer={referrer} />}
      {state.welcomeShowSignUp && <LoginDialog isOpen={true} kind="sign-up" referrer={referrer} />}
    </>
  );
});

const MainContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'stretch'
  },
  ['@media screen and (max-height: 700px)']: {
    justifyContent: 'stretch'
  }
}));
