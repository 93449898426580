import { useNavigateAsync, useOptionalActivePlannerId, useServices, useViewModel } from '@/hooks';
import { UserDashboardInfo } from '@/models';
import { Alert, Stack } from '@mui/material';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { Dialog } from '../../../utils';
import { ScheduleCycleCreate } from './ScheduleCycleCreate';

export interface ScheduleCycleCreateDialogProps {
  isOpen: boolean;
  userDashboard: UserDashboardInfo;
  onClose?: () => void;
}

export const ScheduleCycleCreateDialog = observer(
  ({ isOpen, userDashboard, onClose }: ScheduleCycleCreateDialogProps) => {
    const { route } = useServices();
    const plannerId = useOptionalActivePlannerId();
    const navigate = useNavigateAsync();

    function onDismiss(scheduleId: string | undefined) {
      if (onClose != null) {
        onClose();
        return;
      }

      if (scheduleId != null && plannerId != null) {
        const scheduleRoute = route.resolvePlannerScheduleConfigLocation(scheduleId, undefined, plannerId);
        void navigate(scheduleRoute, { replace: true });
      } else {
        void navigate(-1);
      }
    }

    const viewModel = useViewModel(
      (viewModels) =>
        viewModels.createScheduleCreateDialogViewModel(userDashboard.id, userDashboard.kind, plannerId, onDismiss),
      [plannerId, userDashboard]
    );

    return (
      <Dialog
        viewModel={viewModel}
        isOpen={isOpen}
        title={LocalizedStrings.scheduleCycle.create.title()}
        width="md"
        renderData={() => (
          <Stack spacing={2}>
            <ScheduleCycleCreate viewModel={viewModel} />

            {viewModel.createError != null && (
              <Alert variant="filled" severity="error">
                {LocalizedStrings.scheduleCycle.create.errorMessage()}
              </Alert>
            )}
          </Stack>
        )}
      />
    );
  }
);
