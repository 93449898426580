import { useBackButtonListener, useServices, useViewModel } from '@/hooks';
import { ConfirmationDialogAction, ConfirmationDialogChoice } from '@/viewmodels';
import { Checkbox, FormControlLabel, FormGroup, Stack } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Dialog } from '../dialog';
import { ConfirmationDialogMessage } from './ConfirmationDialogMessage';

export interface ConfirmationDialogWithChoicesProps {
  isOpen: boolean;

  /**
   * The confirmation message.
   */
  message: string;

  /**
   * Indicates whether to display the cancel button. Optional. Default is `true`.
   */
  displayCancelButton?: boolean;

  /**
   * The dialog title. Optional. Default is `UtilsStrings.confirmationDialogTitle`.
   */
  title?: string;

  /**
   * The confirm button label. Optional. Default is `UtilsStrings.confirmationDialogConfirmButton`.
   */
  confirmButtonLabel?: string;

  /**
   * The cancel button label. Optional. Default is `UtilsStrings.confirmationDialogCancelButton`.
   */
  cancelButtonLabel?: string;

  /**
   * Additional actions to display. Optional.
   */
  additionalActions?: ConfirmationDialogAction[];

  /**
   * Choices displayed to the user in the dialog.
   */
  choices: ConfirmationDialogChoice[];

  requiresAtLeastOneSelection?: boolean;

  /**
   * Keys of choices initially selected. Optional.
   */
  initialSelectedChoicesKeys?: string[];

  /**
   * If true, the confirm button will be shown in red.
   */
  isDestructive?: boolean;

  onSubmit: (result: string[] | undefined) => void;
}

export const ConfirmationDialogWithChoices = observer(
  ({
    isOpen,
    message,
    displayCancelButton = true,
    title,
    confirmButtonLabel,
    cancelButtonLabel,
    additionalActions,
    onSubmit,
    choices,
    initialSelectedChoicesKeys,
    isDestructive,
    requiresAtLeastOneSelection
  }: ConfirmationDialogWithChoicesProps) => {
    const { localization } = useServices();
    const strings = localization.localizedStrings.utils;

    function onSuccess(selectedChoices: string[]) {
      onSubmit(selectedChoices);
      return Promise.resolve();
    }

    function onCancel() {
      onSubmit(undefined);
      return Promise.resolve();
    }

    const viewModel = useViewModel((viewModels) =>
      viewModels.createConfirmationDialogWithChoicesViewModel(
        onSuccess,
        onCancel,
        displayCancelButton,
        confirmButtonLabel,
        cancelButtonLabel,
        additionalActions,
        choices,
        requiresAtLeastOneSelection,
        initialSelectedChoicesKeys,
        isDestructive
      )
    );

    useBackButtonListener((e) => {
      if (isOpen) {
        e.stopPropagation();
        void viewModel.dismiss();
      }
    });

    return (
      <Dialog
        isOpen={isOpen}
        viewModel={viewModel}
        title={title ?? strings.confirmationDialogTitle}
        width="sm"
        renderData={() => (
          <Stack direction="column" spacing={2}>
            <ConfirmationDialogMessage message={message} />

            <FormGroup>
              {viewModel.choices.map((c) => (
                <FormControlLabel
                  key={c.key}
                  control={<Checkbox checked={viewModel.selectedKeys.includes(c.key)} />}
                  onChange={() => viewModel.toggleSelectionForKey(c.key)}
                  label={<span dangerouslySetInnerHTML={{ __html: c.title }} />}
                />
              ))}
            </FormGroup>
          </Stack>
        )}
      />
    );
  }
);
