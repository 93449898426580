import { Day } from '@/models';
import { AddRounded } from '@mui/icons-material';
import { ListItemButton, ListItemIcon, ListItemText, Popover } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import LocalizedStrings from 'strings';
import { useBackButtonListener } from '../../../../../hooks';
import { ScheduleCycleTermEdit } from './ScheduleCycleTermEdit';

export interface ScheduleCycleTermsListAddItemProps {
  sx?: SxProps;
  className?: string;
  onCreate: (name: string, startDay: Day, endDay: Day) => void;
  minDate: Date;
  maxDate: Date;
}

export const ScheduleCycleTermsListAddItem = observer(
  ({ sx = [], className, onCreate, minDate, maxDate }: ScheduleCycleTermsListAddItemProps) => {
    const [addButtonRef, setAddButtonRef] = useState<HTMLDivElement | null>(null);

    useBackButtonListener((e) => {
      if (addButtonRef != null) {
        e.stopPropagation();
        setAddButtonRef(null);
      }
    });

    return (
      <>
        <ListItemButton
          onClick={(e) => {
            setAddButtonRef(e.currentTarget);
          }}
          sx={{ ...sx, borderRadius: 1 }}
          className={className}
        >
          <ListItemIcon>
            <AddRounded />
          </ListItemIcon>
          <ListItemText>{LocalizedStrings.scheduleCycle.term.list.addButtonTitle()}</ListItemText>
        </ListItemButton>

        {addButtonRef != null && (
          <Popover
            open={true}
            anchorEl={addButtonRef}
            slotProps={{ paper: { sx: { width: 400 } } }}
            onClose={() => setAddButtonRef(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
          >
            <ScheduleCycleTermEdit
              term={undefined}
              onSave={(_, name, startDay, endDay) => {
                onCreate(name, startDay, endDay);
                setAddButtonRef(null);
              }}
              minDate={minDate}
              maxDate={maxDate}
              dismiss={() => setAddButtonRef(null)}
            />
          </Popover>
        )}
      </>
    );
  }
);
