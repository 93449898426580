import { Entitlement } from '@buf/studyo_studyo-today-subscriptions.bufbuild_es/studyo/today/subscriptions/v1/resources/entitlement_pb';
import { IconButton, IconButtonProps, Tooltip } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import LocalizedStrings from 'strings';
import { useServices } from '../../hooks';

export interface RequiresTodaySchoolsSubscriptionIconButtonProps extends IconButtonProps {
  requiresSubscription?: boolean;
}

export const RequiresTodaySchoolsSubscriptionIconButton = observer(
  ({ disabled = false, requiresSubscription = true, ...props }: RequiresTodaySchoolsSubscriptionIconButtonProps) => {
    const { userStore } = useServices();
    const params = useParams();
    const schoolId = params.schoolId ?? '';
    const hasSchool = schoolId.length > 0;
    const [isEntitled, setIsEntitled] = useState<boolean | null>(null);

    const entitlementLoadable = useMemo(
      () => (hasSchool ? userStore.getSchoolSharedSchoolEntitlement(schoolId) : undefined),
      [schoolId, userStore]
    );

    useEffect(() => {
      const fetchIsEntitled = async () => {
        if (!requiresSubscription) {
          setIsEntitled(true);
          return;
        }

        await entitlementLoadable?.fetch(true);

        if (entitlementLoadable != null) {
          if (entitlementLoadable.hasData) {
            setIsEntitled(entitlementLoadable.data.isEntitled);
          } else {
            setIsEntitled(false);
          }
        } else {
          await userStore.subscription.fetch(true);

          if (userStore.subscription.hasData) {
            const subscription = userStore.subscription.data!.subscription;
            setIsEntitled(subscription.isActive && subscription.entitlements.includes(Entitlement.SHARED_SCHOOLS));
          } else {
            setIsEntitled(false);
          }
        }
      };

      void fetchIsEntitled();
    }, [schoolId]);

    return (
      <Tooltip
        title={isEntitled === false ? LocalizedStrings.utils.requiresTodaySchoolsSubscriptionButtonTooltip() : ''}
        disableInteractive
      >
        <span>
          <IconButton {...props} disabled={isEntitled !== true || disabled} />
        </span>
      </Tooltip>
    );
  }
);
