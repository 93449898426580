import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router';

export function useActivePlannerId(): string {
  const location = useLocation();
  const params = useParams();

  return useMemo(() => {
    const plannerId = params.plannerId;
    if (plannerId == null) {
      throw new Error('Cannot find active plannerId.');
    }
    return plannerId;
  }, [location, params]);
}

export function useOptionalActivePlannerId(): string | undefined {
  const location = useLocation();
  const params = useParams();

  return useMemo(() => {
    return params.plannerId;
  }, [location, params]);
}
