import { useActivePlannerId, useNavigateAsync, useServices } from '@/hooks';
import { WorkDetailsViewModel } from '@/viewmodels';
import { CustomActionEffect } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/custom_action_effect_pb';
import { CustomWorkAction } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/custom_work_action_pb';
import { Box, Button, Card, Divider, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { WorkIcon } from '../../lists';
import { RichTextViewer } from '../../utils';
import { WorkDetailsDueDate } from './WorkDetailsDueDate';

export interface WorkDescriptionCardProps {
  viewModel: WorkDetailsViewModel;
  className?: string;
  sx?: SxProps;
}

export const WorkDescriptionCard = observer(({ viewModel, className, sx }: WorkDescriptionCardProps) => {
  const { localization, route } = useServices();
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const plannerId = useActivePlannerId();
  const strings = localization.localizedStrings.work.details;
  const navigate = useNavigateAsync();

  async function performCustomAction(action: CustomWorkAction) {
    switch (action.effect) {
      case CustomActionEffect.CONNECT_GOOGLE_CALENDAR:
      case CustomActionEffect.CONNECT_GOOGLE_CLASSROOM: {
        const destination = route.resolvePlannerSettingsLocation('connected-apps', plannerId);
        await navigate(destination);
        break;
      }

      case CustomActionEffect.CREATE_SCHEDULE: {
        const targetLocation = route.resolvePlannerSettingsLocation('schedules', plannerId);
        await navigate(targetLocation, { replace: true });
        break;
      }

      default:
        await viewModel.performCustomAction(action.effect);
        break;
    }
  }

  return (
    <Card className={className} sx={{ ...sx, p: 2 }} elevation={theme.palette.mode === 'light' ? 1 : 8}>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        spacing={2}
        sx={{
          display: 'flex',
          alignItems: 'stretch'
        }}
      >
        <Stack
          direction="column"
          spacing={1}
          sx={{
            flex: 1
          }}
        >
          <Stack
            direction="row"
            spacing={1}
            sx={{
              display: 'flex',
              flex: 1,
              height: '100%'
            }}
          >
            <WorkIcon icon={viewModel.workIcon} color={viewModel.courseColor} size={40} displayCourseSectionColor />
            <Stack direction="column" sx={{ flex: 1 }}>
              <Stack
                direction="column"
                sx={{
                  flex: 1
                }}
              >
                <Typography variant="h6">{viewModel.workTitle}</Typography>

                {viewModel.workDescription?.text.length === 0 ? (
                  <Typography
                    variant="body2"
                    sx={{
                      color: theme.palette.text.secondary
                    }}
                  >
                    {strings.noDescription}
                  </Typography>
                ) : (
                  <RichTextViewer
                    id="work-details"
                    label={strings.instructionsTitle}
                    content={viewModel.workDescription}
                    textSize="small"
                    sx={{ maxHeight: 300 }}
                  />
                )}
              </Stack>

              {/* Custom actions */}
              {!viewModel.isReadOnly && viewModel.customActions.length > 0 && (
                <Stack
                  direction={isExtraSmallScreen ? 'column' : 'row'}
                  sx={{
                    rowGap: 1,
                    columnGap: 1,
                    paddingTop: 2,
                    flexWrap: 'wrap'
                  }}
                >
                  {viewModel.customActions.map((action) => (
                    <Button
                      key={`custom-action-${action.effect}`}
                      variant="contained-grey"
                      fullWidth={isExtraSmallScreen}
                      disabled={viewModel.isCompleted || viewModel.isCancelled}
                      onClick={() => void performCustomAction(action)}
                    >
                      {action.label}
                    </Button>
                  ))}
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>

        <Box>
          <Divider orientation={isSmallScreen ? 'horizontal' : 'vertical'} />
        </Box>

        <WorkDetailsDueDate
          sx={{ minWidth: 180 }}
          viewModel={viewModel.dueDateViewModel}
          disabled={viewModel.isApplying}
        />
      </Stack>
    </Card>
  );
});
