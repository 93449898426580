import { useNavigateAsync, useServices } from '@/hooks';
import { StudentConnectionState } from '@/models';
import { Box, Button, Stack, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router';
import { TeacherInviteStudentsDialog } from '../../teacher';

interface LocationState {
  studentDetailsNotConnectedShowInvite?: boolean;
}

export interface StudentDetailsNotConnectedProps {
  sx?: SxProps;
  className?: string;
  connectionState: StudentConnectionState;
}

export const StudentDetailsNotConnected = observer(
  ({ sx = [], className, connectionState }: StudentDetailsNotConnectedProps) => {
    const { localization } = useServices();
    const strings = localization.localizedStrings.schoolCourseSection;
    const theme = useTheme();

    const location = useLocation();
    const state = (location.state ?? {}) as LocationState;
    const navigate = useNavigateAsync();

    function requestAccess() {
      const newState: LocationState = { ...state, studentDetailsNotConnectedShowInvite: true };
      void navigate(location, { state: newState });
    }

    const message =
      connectionState === 'not-connected'
        ? strings.studentDetailsNotConnectedMessage
        : strings.studentDetailsConnectedNoAccessMessage;

    return (
      <Box sx={sx} className={className}>
        <Stack
          direction="column"
          spacing={3}
          sx={{
            py: 6,
            px: 3,
            alignItems: 'center'
          }}
        >
          <div
            style={{
              color: theme.palette.text.secondary,
              fontWeight: '500',
              fontSize: theme.typography.body2.fontSize,
              textAlign: 'center'
            }}
            dangerouslySetInnerHTML={{ __html: message }}
          />

          {connectionState === 'connected-no-access' && (
            <Button variant="contained" onClick={requestAccess}>
              {strings.studentDetailsRequestPlannerAccessButtonTitle}
            </Button>
          )}
        </Stack>
        {(state.studentDetailsNotConnectedShowInvite ?? false) && <TeacherInviteStudentsDialog isOpen={true} />}
      </Box>
    );
  }
);
