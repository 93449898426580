import { EditableAttachmentInfo } from '@/models';
import { List, Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { WorkAttachment } from '../details';

export interface WorkEditAttachmentsListProps {
  sx?: SxProps;
  className?: string;
  attachments: EditableAttachmentInfo[];
}

export const WorkEditAttachmentsList = observer(({ sx = [], className, attachments }: WorkEditAttachmentsListProps) => {
  return (
    <List sx={sx} className={className}>
      <Stack spacing={1}>
        {attachments.map((a, i) => (
          <WorkAttachment key={i} attachment={a.initialModel} onRemove={() => a.markAsDeleted()} />
        ))}
      </Stack>
    </List>
  );
});
