import { differenceInMinutesBetweenTimeOfDays, TimeOfDay } from '@/models';
import { Theme } from '@mui/material';
import LocalizedStrings from 'strings';
import { formatCalendarItemTimes } from '../../shared';
import { updateDocumentElement } from '../../utils';
import { PlannedWorkEditComponentIds } from './PlannedWorkEditComponentIds';

export function updateStyleOfPlannedWorkEditInfoViewForTemporaryValues(
  id: string,
  startTime: TimeOfDay,
  endTime: TimeOfDay,
  theme: Theme
) {
  const strings = LocalizedStrings.dateTime;
  const duration = Math.abs(differenceInMinutesBetweenTimeOfDays(startTime, endTime));
  const isSmall = duration < 35;
  const showIcon = duration >= 20;

  // Using "display: none" to hide elements instead of having conditions in component as we need to manipulate their
  // presence while an interaction is happening.
  updateDocumentElement(`${PlannedWorkEditComponentIds.infoViewIcon}-${id}`, (element) => {
    element.style.display = !showIcon ? 'none' : 'flex';
  });

  updateDocumentElement(`${PlannedWorkEditComponentIds.infoViewLabelStack}-${id}`, (element) => {
    element.style.marginLeft = !showIcon ? theme.spacing(0.5) : '0px';
    element.style.justifyContent = isSmall ? 'center' : 'flex-start';
  });

  updateDocumentElement(`${PlannedWorkEditComponentIds.infoViewStartTimeLabel}-${id}`, (label) => {
    label.textContent = formatCalendarItemTimes(startTime, endTime, false);
    label.style.display = isSmall ? 'none' : 'block';
  });

  updateDocumentElement(`${PlannedWorkEditComponentIds.infoViewDurationLabel}-${id}`, (label) => {
    label.textContent = strings.shortDurationFormat(duration);
  });

  updateDocumentElement(`${PlannedWorkEditComponentIds.infoViewCompletedLabel}-${id}`, (label) => {
    label.style.display = isSmall ? 'none' : 'block';
  });
}
