import ErrorIcon from '@mui/icons-material/ErrorOutlineRounded';
import RetryIcon from '@mui/icons-material/RefreshRounded';
import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useNavigateAsync, useServices } from '../../hooks';
import { PlannerLoadable } from '../../stores';
import { isError } from '../../utils';
import { Splash } from '../Splash';

export interface LoadingPlannerScreenProps {
  plannerLoadable: PlannerLoadable;
}

export const LoadingPlannerScreen = observer(({ plannerLoadable }: LoadingPlannerScreenProps) => {
  const { localization } = useServices();
  const strings = localization.localizedStrings.utils;
  const location = useLocation();
  const navigate = useNavigateAsync();

  useEffect(() => {
    void plannerLoadable.fetch(true);
  }, [plannerLoadable]);

  useEffect(() => {
    const disposer = when(
      () => plannerLoadable.hasData,
      () => void navigate(location, { replace: true })
    );

    return () => disposer();
  }, [plannerLoadable]);

  return (
    <Splash width="100%" height="100%" logoIsLink={false} showOptions={false}>
      {isError(plannerLoadable.state) ? (
        <Stack spacing={2}>
          <ErrorIcon color="error" fontSize="large" sx={{ alignSelf: 'center' }} />

          <Typography
            variant="subtitle2"
            sx={{
              textAlign: 'center',
              color: (theme) => theme.palette.error.main
            }}
          >
            {strings.loadingPlannerErrorMessage}
          </Typography>

          <Button variant="contained" startIcon={<RetryIcon />} onClick={() => void plannerLoadable.fetch(true)}>
            {strings.loadingPlannerErrorRetryLabel}
          </Button>

          <Button onClick={() => void navigate('/')}>{strings.loadingPlannerErrorGoHomeLabel}</Button>
        </Stack>
      ) : (
        <>
          <Typography>{strings.loadingPlannerLabel}</Typography>
          <CircularProgress sx={{ mt: 3 }} size={32} />
        </>
      )}
    </Splash>
  );
});
