import {
  ScheduleCycleActivitySchedules,
  ScheduleCycleBellTimesPage,
  ScheduleCycleDetailsEditPage,
  ScheduleCycleMasterSchedulePage,
  ScheduleCyclePage,
  ScheduleCycleSpecialDaysPage
} from '@/components';
import { RouteObject } from 'react-router';
import { PlannerScheduleCycleList } from '../components/planner/settings/PlannerScheduleCycleList';

export const ScheduleCycleConfigChildrenRoutes: RouteObject[] = [
  { path: 'activity-schedules', element: <ScheduleCycleActivitySchedules filters={undefined} /> },
  { path: 'specialdays', element: <ScheduleCycleSpecialDaysPage /> },
  { path: 'belltimes', element: <ScheduleCycleBellTimesPage /> },
  { path: 'master-schedule', element: <ScheduleCycleMasterSchedulePage /> },
  { path: '', element: <ScheduleCycleDetailsEditPage /> }
];

export const ScheduleCycleConfigRoutes: RouteObject[] = [
  {
    path: ':scheduleCycleId',
    element: <ScheduleCyclePage />,
    children: ScheduleCycleConfigChildrenRoutes
  },
  {
    path: ':scheduleCycleId/s/:schoolId',
    element: <ScheduleCyclePage />,
    children: ScheduleCycleConfigChildrenRoutes
  },
  { path: '', element: <PlannerScheduleCycleList /> }
];
