import { AppCustomEvent, CustomEvent } from './CustomEvent';

type EventCallback = (event: CustomEvent) => void;

export class EventEmitter {
  private events: Map<string, EventCallback[]>;

  constructor() {
    this.events = new Map();
  }

  on(eventType: string, callback: EventCallback) {
    if (!this.events.has(eventType)) {
      this.events.set(eventType, []);
    }
    this.events.get(eventType)!.push(callback);
  }

  off(eventType: string, callback: EventCallback) {
    const callbacks = this.events.get(eventType);
    if (callbacks) {
      this.events.set(
        eventType,
        callbacks.filter((cb) => cb !== callback)
      );
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  emit(eventType: string, payload?: any) {
    const event: CustomEvent = new AppCustomEvent(eventType, payload);

    const callbacks = this.events.get(eventType) ?? [];
    for (const callback of callbacks.toReversed()) {
      if (!event.isPropagationStopped) {
        callback(event);
      }
    }
  }
}
