import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';
import LocalizedStrings from 'strings';
import { useSync, useViewModel } from '../../../../hooks';
import { UpdatablePresenter } from '../../../utils';
import { AdminContentCard } from '../../shared';
import { AdminSchoolClassesTable } from './AdminSchoolClassesTable';

export interface AdminSchoolClassesListPageProps {
  sx?: SxProps;
  className?: string;
}

export const AdminSchoolClassesListPage = observer(({ sx = [], className }: AdminSchoolClassesListPageProps) => {
  const params = useParams();
  const schoolId = params.schoolId ?? '';

  const viewModel = useViewModel((viewModels) => viewModels.createAdminClassesListViewModel(schoolId), [schoolId]);
  const strings = LocalizedStrings.admin.classes.list;

  useSync('admin-classes-list', () => viewModel.reloadData(), [viewModel]);

  return (
    <Box sx={sx} className={className}>
      <AdminContentCard
        title={strings.title()}
        searchValue={viewModel.hasData ? viewModel.searchText : undefined}
        onSearch={(value) => (viewModel.searchText = value)}
      >
        <UpdatablePresenter
          sx={{ px: 2, pt: 1, overflow: 'hidden' }}
          viewModel={viewModel}
          renderData={() => (
            <Box
              sx={{
                flex: 1,
                overflow: 'hidden'
              }}
            >
              <AdminSchoolClassesTable sx={{ width: '100%' }} viewModel={viewModel} />
            </Box>
          )}
        />
      </AdminContentCard>
    </Box>
  );
});
