import { ScheduleCycleActivitySchedulesActivityInfo, ScheduleCycleActivitySchedulesCoursesFilter } from '@/viewmodels';
import { Activity } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/activity_pb';
import { AddRounded } from '@mui/icons-material';
import { List, ListItemButton, ListItemIcon, ListItemText, Popover, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import LocalizedStrings from 'strings';
import { useBackButtonListener } from '../../../../../hooks';
import { ScheduleCycleActivityScheduleEdit } from './ScheduleCycleActivityScheduleEdit';
import { ScheduleCycleActivitySchedulesListItem } from './ScheduleCycleActivitySchedulesListItem';

export interface ScheduleCycleActivitySchedulesListProps {
  sx?: SxProps;
  className?: string;
  activity: Activity;
  activitySchedules: ScheduleCycleActivitySchedulesActivityInfo[];
  removeActivitySchedule: (scheduleId: string) => void;
  isReadOnly: boolean;
  filters: ScheduleCycleActivitySchedulesCoursesFilter | undefined;
}

export const ScheduleCycleActivitySchedulesList = observer(
  ({
    sx = [],
    className,
    activity,
    activitySchedules,
    removeActivitySchedule,
    isReadOnly,
    filters
  }: ScheduleCycleActivitySchedulesListProps) => {
    const [addActivityScheduleRef, setAddActivityScheduleRef] = useState<HTMLDivElement | undefined>();
    const strings = LocalizedStrings.scheduleCycle.edit.activitySchedules;

    useBackButtonListener((e) => {
      if (addActivityScheduleRef != null) {
        e.stopPropagation();
        setAddActivityScheduleRef(undefined);
      }
    });

    return (
      <List sx={{ ...sx, overflowY: 'auto' }} dense className={className}>
        {activitySchedules.map((activitySchedule) => (
          <ScheduleCycleActivitySchedulesListItem
            key={activitySchedule.id}
            activity={activity}
            activitySchedule={activitySchedule}
            removeActivitySchedule={(id) => removeActivitySchedule(id)}
            isReadOnly={isReadOnly}
            filters={filters}
          />
        ))}
        {activitySchedules.length === 0 && (
          <Typography
            variant="body1"
            sx={{
              p: 2,
              fontStyle: 'italic',
              color: (theme) => theme.palette.text.secondary
            }}
          >
            {strings.noSchedulesForCourseMessage()}
          </Typography>
        )}
        {!isReadOnly && (
          <ListItemButton className="rounded" onClick={(e) => setAddActivityScheduleRef(e.currentTarget)}>
            <ListItemIcon>
              <AddRounded />
            </ListItemIcon>

            <ListItemText primary={strings.addActivityScheduleButtonTitle()} />
          </ListItemButton>
        )}
        {addActivityScheduleRef != null && (
          <Popover
            open={true}
            anchorEl={addActivityScheduleRef}
            onClose={() => setAddActivityScheduleRef(undefined)}
            slotProps={{ paper: { sx: { width: 400, height: 600 } } }}
          >
            <ScheduleCycleActivityScheduleEdit
              activityScheduleId={undefined}
              activity={activity}
              sx={{ height: '100%', width: '100%' }}
              dismiss={() => setAddActivityScheduleRef(undefined)}
              filters={filters}
            />
          </Popover>
        )}
      </List>
    );
  }
);
