import { useNavigateAsync, useServices } from '@/hooks';
import { Button, Checkbox, Divider, FormControlLabel, FormGroup, Stack, styled, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router';
import LocalizedStrings from 'strings';
import { RouteTemplates } from '../RouteTemplates';
import { Splash } from './Splash';
import { Login, LoginDialog } from './onboarding';

interface LocationState {
  referrer?: string;
  showSignUp?: boolean;
}

export interface LoginPageProps {
  sx?: SxProps;
  className?: string;
}

export const LoginPage = observer(({ sx, className }: LoginPageProps) => {
  const { user } = useServices();
  const location = useLocation();
  const state = (location.state as LocationState) ?? {};
  const navigate = useNavigateAsync();

  const strings = LocalizedStrings.loginPage;
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);

  if (user.currentUser != null || state.referrer == null) {
    return <Navigate to={state.referrer ?? RouteTemplates.root} replace />;
  }

  function showSignUp() {
    const newState: LocationState = { ...state, showSignUp: true };
    void navigate(location, { state: newState });
  }

  return (
    <Splash sx={sx} className={className} width="100%" height="100%" contentWidth={500}>
      <Stack spacing={3}>
        <Typography variant="h5" sx={{ fontWeight: 700 }}>
          {strings.loginTitle()}
        </Typography>

        <Login referrer={state.referrer} sx={{ alignItems: 'stretch' }} />

        <div>
          <Divider variant="middle" />
        </div>

        <Stack sx={{ width: '100%' }} spacing={2}>
          <Typography variant="body1" sx={{ fontWeight: '700' }}>
            {strings.signUpTitle()}
          </Typography>

          <Stack spacing={1}>
            <Button
              sx={{ alignSelf: 'flex-start' }}
              variant="contained-grey"
              disabled={!hasAcceptedTerms}
              onClick={showSignUp}
            >
              {strings.createAccountButtonTitle()}
            </Button>
            <FormGroup sx={{ maxWidth: { xs: '75%', md: '110%' } }}>
              <FormControlLabel
                sx={{
                  borderRadius: 1
                }}
                control={<Checkbox />}
                label={
                  <SmallText
                    pr={1}
                    dangerouslySetInnerHTML={{ __html: strings.privacyPolicyConfirmMessage('inherit') }}
                  />
                }
                checked={hasAcceptedTerms}
                onChange={(_, checked) => setHasAcceptedTerms(checked)}
              />
            </FormGroup>
          </Stack>
        </Stack>
      </Stack>

      {state.showSignUp === true && <LoginDialog isOpen={true} kind="sign-up" referrer={state.referrer} />}
    </Splash>
  );
});

const SmallText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  font: theme.typography.caption.font,
  fontFamily: theme.typography.caption.fontFamily,
  fontSize: theme.typography.caption.fontSize,
  fontWeight: theme.typography.caption.fontWeight
}));
