import { useServices } from '@/hooks';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import {
  Button,
  Checkbox,
  colors,
  darken,
  FormControlLabel,
  FormGroup,
  Link,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { isSxArray } from '../../utils';
import { TypographyParagraphs } from '../utils';

export interface WelcomeInfosProps {
  sx?: SxProps;
  className?: string;
  login: () => void;
  signUp: () => void;
}

export const WelcomeInfos = observer(({ sx = [], className, login, signUp }: WelcomeInfosProps) => {
  const { localization, environment } = useServices();
  const theme = useTheme();
  const strings = localization.localizedStrings.welcome;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const [showPrivacyAlert, setShowPrivacyAlert] = useState(false);

  function onSignUpClick() {
    if (hasAcceptedTerms) {
      signUp();
    } else {
      setShowPrivacyAlert(true);
    }
  }

  return (
    <Stack
      spacing={{ xs: 4, md: 6 }}
      className={className}
      sx={[
        {
          justifyContent: 'center'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <Stack
        spacing={2}
        sx={{
          justifyContent: 'center'
        }}
      >
        <TagLine variant={'h3'} fontWeight="500">
          {strings.title}
        </TagLine>

        <MainFeaturesList variant="h6" color="textSecondary">
          {strings.featuresDescription}
        </MainFeaturesList>

        <Button
          color="primary"
          href={strings.learnMoreUrl}
          target="_blank"
          rel="noreferrer"
          sx={{ mt: 3, alignSelf: { xs: 'center', md: 'flex-start' } }}
        >
          {strings.learnMoreButtonLabel}
        </Button>
      </Stack>
      <Stack
        spacing={2}
        sx={{
          alignItems: { xs: 'center', md: 'flex-start' },
          pb: { xs: 2, md: 0 }
        }}
      >
        <SignUpButton size="large" variant="contained" endIcon={<ChevronRightRoundedIcon />} onClick={onSignUpClick}>
          {strings.mainSignUpButtonTitle}
        </SignUpButton>

        {!hasAcceptedTerms && showPrivacyAlert && (
          <SmallText sx={{ color: theme.palette.error.main }}>{strings.privacyPolicyAlertText}</SmallText>
        )}

        <FormGroup sx={{ maxWidth: { xs: '75%', md: '110%' } }}>
          <FormControlLabel
            sx={{
              borderRadius: 1
            }}
            control={<Checkbox />}
            label={
              <SmallText pr={1} dangerouslySetInnerHTML={{ __html: strings.privacyPolicyConfirmMessage('inherit') }} />
            }
            checked={hasAcceptedTerms}
            onChange={(_, checked) => setHasAcceptedTerms(checked)}
          />
        </FormGroup>

        <Stack
          spacing={1}
          sx={{
            alignItems: 'flex-start'
          }}
        >
          <LoginText variant="subtitle1" color="textSecondary" textAlign="center" pt={{ xs: 1, md: 4 }}>
            {`${strings.existingAccountMessage} `}
            {isMobile && <br />}
            <Link
              component="button"
              sx={{
                color: 'inherit',
                textDecorationColor: 'inherit',
                font: 'inherit',
                verticalAlign: 'baseline'
              }}
              onClick={login}
            >
              {strings.mainSignInButtonTitle}
            </Link>
          </LoginText>

          <SmallText>{`${environment.version}.${environment.buildNumber}-${environment.environment}`}</SmallText>
          <SmallText>© 2020-2025 Intuitic Inc.</SmallText>
          <SmallText>
            <Link href="#" onClick={() => window.displayPreferenceModal()}>
              {strings.cookiePreferencesLabel}
            </Link>
          </SmallText>
        </Stack>
      </Stack>
    </Stack>
  );
});

const TagLine = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.h4.fontSize,
    textAlign: 'center'
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: theme.typography.h5.fontSize,
    textAlign: 'center'
  },
  ['@media screen and (max-height: 600px)']: {
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.h6.fontSize
    },
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.h3.fontSize
    }
  }
}));

const MainFeaturesList = styled(TypographyParagraphs)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.body1.fontSize,
    textAlign: 'center'
  },
  ['@media screen and (max-height: 600px)']: {
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.body2.fontSize
    }
  }
}));

const signUpButtonColor = colors.green[700];
const SignUpButton = styled(Button)(({ theme }) => ({
  backgroundColor: signUpButtonColor,
  color: theme.palette.getContrastText(signUpButtonColor),

  [':hover']: {
    backgroundColor: darken(signUpButtonColor, 0.3)
  }
}));

const LoginText = styled(Typography)(({ theme }) => ({
  ['@media screen and (max-height: 600px)']: {
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.caption.fontSize
    }
  }
}));

const SmallText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  font: theme.typography.caption.font,
  fontFamily: theme.typography.caption.fontFamily,
  fontSize: theme.typography.caption.fontSize,
  fontWeight: theme.typography.caption.fontWeight,
  textAlign: 'center'
}));
