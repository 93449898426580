import { useActivePlannerId, useNavigateAsync, useServices, useViewModel } from '@/hooks';
import { PlannerRelationshipKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_relationship_kind_pb';
import { observer } from 'mobx-react-lite';
import { Dialog } from '../../utils';
import { SharePlanner } from './SharePlanner';

export interface SharePlannerDialogProps {
  readonly targetRelationship: PlannerRelationshipKind;
  isOpen: boolean;
}

export const SharePlannerDialog = observer(({ targetRelationship, isOpen }: SharePlannerDialogProps) => {
  const { localization } = useServices();
  const strings = localization.localizedStrings.sharingInvitationCode;
  const navigate = useNavigateAsync();
  const plannerId = useActivePlannerId();

  function close() {
    return navigate(-1);
  }

  const viewModel = useViewModel((viewModels) =>
    viewModels.createSharePlannerDialogViewModel(targetRelationship, plannerId, close)
  );

  return (
    <Dialog
      viewModel={viewModel}
      isOpen={isOpen}
      title={strings.sharePlannerTitle(viewModel.userRelationshipKind)}
      width="sm"
      renderData={() => <SharePlanner viewModel={viewModel} />}
    />
  );
});
