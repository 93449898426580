import { useServices, useSync, useViewModel } from '@/hooks';
import { getOptionalDashConcatenation } from '@/models';
import { AdminAccountClassesListKind } from '@/viewmodels';
import { SchoolRounded, YoutubeSearchedForRounded } from '@mui/icons-material';
import { Box, List, ListItem, ListItemIcon, ListItemText, Stack, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { Observer, observer } from 'mobx-react-lite';
import { Link } from 'react-router';
import LocalizedStrings from 'strings';
import { UpdatablePresenter } from '../../utils';
import { AdminContentCard } from '../shared';

export interface AdminAccountClassesListProps {
  sx?: SxProps;
  className?: string;
  schoolId: string;
  accountId: string;
  accountKind: AdminAccountClassesListKind;
}

export const AdminAccountClassesList = observer(
  ({ sx = [], className, accountId, schoolId, accountKind }: AdminAccountClassesListProps) => {
    const { route } = useServices();
    const theme = useTheme();
    const strings = LocalizedStrings.admin.classes.accountList;

    const viewModel = useViewModel(
      (viewModels) => viewModels.createAdminAccountClassesListViewModel(accountId, schoolId, accountKind),
      [accountId, schoolId, accountKind]
    );

    useSync('account-classes-list', () => viewModel.reloadData(), [viewModel]);

    return (
      <AdminContentCard
        title={titleForAccountKind(accountKind)}
        sx={sx}
        className={className}
        searchValue={viewModel.searchText}
        onSearch={(v) => viewModel.setSearchText(v)}
      >
        <UpdatablePresenter
          viewModel={viewModel}
          renderData={() => (
            <Observer>
              {() => {
                const { filteredCourseSections, courseSections, searchText } = viewModel;

                return (
                  <Stack
                    sx={{
                      width: '100%'
                    }}
                  >
                    <List>
                      {filteredCourseSections.map((cs) => {
                        const teachers = cs.details?.teachers ?? [];
                        const subtitle = getOptionalDashConcatenation(
                          cs.section,
                          teachers.map((t) => t.fullName).join(', ')
                        );
                        const hasSubtitle = subtitle.length > 0;
                        const detailsLocation = route.resolveAdminClassDetailsLocation(cs.schoolId, cs.id);

                        return (
                          <ListItem
                            key={cs.id}
                            sx={{ color: theme.palette.text.primary, '&:hover': { textDecoration: 'underline' } }}
                            component={Link}
                            to={detailsLocation}
                          >
                            <Box
                              sx={{
                                backgroundColor: cs.color,
                                width: 4,
                                height: hasSubtitle ? 42 : 26,
                                borderRadius: 2,
                                mr: 1
                              }}
                            />
                            <ListItemText primary={cs.title} secondary={subtitle} />
                          </ListItem>
                        );
                      })}

                      {courseSections.length === 0 && (
                        <ListItem>
                          <ListItemIcon sx={{ color: theme.palette.text.secondary }}>
                            <SchoolRounded color="inherit" />
                          </ListItemIcon>
                          <ListItemText
                            primary={emptyMessageForAccountKind(accountKind)}
                            slotProps={{
                              primary: { color: theme.palette.text.secondary }
                            }}
                          />
                        </ListItem>
                      )}

                      {courseSections.length > 0 && filteredCourseSections.length === 0 && (
                        <ListItem>
                          <ListItemIcon sx={{ color: theme.palette.text.secondary }}>
                            <YoutubeSearchedForRounded color="inherit" />
                          </ListItemIcon>
                          <ListItemText
                            primary={strings.noSearchResultsMessage(searchText)}
                            slotProps={{
                              primary: { color: theme.palette.text.secondary }
                            }}
                          />
                        </ListItem>
                      )}
                    </List>
                  </Stack>
                );
              }}
            </Observer>
          )}
        />
      </AdminContentCard>
    );
  }
);

function titleForAccountKind(kind: AdminAccountClassesListKind) {
  const strings = LocalizedStrings.admin.classes.accountList;

  switch (kind) {
    case 'student':
      return strings.studentTitle();
    case 'teacher':
      return strings.teacherTitle();
  }
}

function emptyMessageForAccountKind(kind: AdminAccountClassesListKind) {
  const strings = LocalizedStrings.admin.classes.accountList;

  switch (kind) {
    case 'student':
      return strings.studentEmptyMessage();
    case 'teacher':
      return strings.teacherEmptyMessage();
  }
}
