import { useActivePlannerId, useServices, useSync, useViewModel } from '@/hooks';
import { Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { Navigate, Outlet, useParams } from 'react-router';
import { BackgroundImageScreenPaper, UpdatablePresenter } from '../../../utils';
import { SchoolCourseSectionDetailsHeader } from './SchoolCourseSectionDetailsHeader';

export interface SchoolCourseSectionDetailsProps {
  schoolId: string;
  courseSectionId: string;
  sx?: SxProps;
  className?: string;
}

export const SchoolCourseSectionDetails = observer(
  ({ courseSectionId, schoolId, sx = [], className }: SchoolCourseSectionDetailsProps) => {
    const { route } = useServices();
    const plannerId = useActivePlannerId();

    const viewModel = useViewModel(
      (viewModels) => viewModels.createCourseSectionDetailsViewModel(courseSectionId, plannerId, schoolId),
      [courseSectionId]
    );

    useSync(
      'school-course-section-details',
      async () => {
        await viewModel.reloadData();
      },
      [courseSectionId]
    );

    const params = useParams();
    const studentId = params.studentId ?? '';

    return (
      <BackgroundImageScreenPaper sx={{ ...sx, overflow: 'hidden' }} className={className}>
        <UpdatablePresenter
          viewModel={viewModel}
          sx={{ width: '100%', height: '100%' }}
          isFlex
          renderData={() => {
            if (studentId.length > 0 && !viewModel.studentsPlannerSummary.allStudents.some((s) => s.id === studentId)) {
              // If we try to navigate to a student that doesn't exist in this course, we navigate to the
              // course details root screen.
              return (
                <Navigate
                  to={route.resolveSchoolCourseSectionDetailsLocation(plannerId, schoolId, courseSectionId)}
                  replace
                />
              );
            }

            return (
              <Stack
                spacing={2}
                sx={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  width: '100%'
                }}
              >
                <SchoolCourseSectionDetailsHeader
                  viewModel={viewModel}
                  courseSectionId={courseSectionId}
                  schoolId={schoolId}
                />
                <Outlet />
              </Stack>
            );
          }}
        />
      </BackgroundImageScreenPaper>
    );
  }
);
