import { Box, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { format, set } from 'date-fns';
import { times } from 'lodash';
import { observer } from 'mobx-react-lite';
import { Ref } from 'react';

export interface DayTimesColumnProps {
  sx?: SxProps;
  className?: string;
  pointsPerHour: number;
  ref?: Ref<HTMLDivElement>;
}

export const DayTimesColumn = observer(({ sx = [], className, pointsPerHour, ref }: DayTimesColumnProps) => {
  return (
    <Box
      ref={ref}
      className={className}
      sx={{
        ...sx,
        overflowY: 'hidden',
        flex: 'none',
        display: 'flex',
        alignItems: 'flex-start',
        position: 'static'
      }}
    >
      <Box
        sx={{
          position: 'relative',
          boxSizing: 'border-box',
          marginLeft: 'auto',
          display: 'block'
        }}
      >
        {times(24).map((i) => (
          <Box
            key={i}
            sx={{
              height: pointsPerHour,
              position: 'relative',
              paddingRight: '8px',
              textAlign: 'right',
              display: 'block'
            }}
          >
            <Typography
              variant="caption"
              sx={{
                color: (theme) => theme.palette.text.secondary,
                position: 'relative',
                top: -10,
                display: i === 0 ? 'none' : 'block'
              }}
            >
              {format(set(new Date(), { hours: i, minutes: 0 }), 'p')
                .replace(/^0+/, '')
                .replace(':00', '')}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
});
