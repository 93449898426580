import { useNavigateAsync, useServices } from '@/hooks';
import { SharePlannerStatusViewModel } from '@/viewmodels';
import { PlannerRelationshipKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_relationship_kind_pb';
import {
  Avatar,
  Box,
  Button,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Stack,
  StackProps,
  Typography
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router';
import { PlannerIcon } from '../../shared';
import { ControlledSelect, UpdatablePresenter } from '../../utils';
import { SharePlannerDialog } from './SharePlannerDialog';

interface LocationState {
  sharePlannerStatusShowShareForRelationship?: PlannerRelationshipKind;
}

export interface SharePlannerStatusProps extends StackProps {
  className?: string;
  viewModel: SharePlannerStatusViewModel;
}

export const SharePlannerStatus = observer(({ className, viewModel, ...props }: SharePlannerStatusProps) => {
  const { localization } = useServices();
  const strings = localization.localizedStrings.sharingInvitationCode;

  const location = useLocation();
  const state = (location.state ?? {}) as LocationState;
  const navigate = useNavigateAsync();

  function share() {
    const newState: LocationState = {
      ...state,
      sharePlannerStatusShowShareForRelationship: viewModel.selectedPlannerRelationshipKind
    };
    void navigate(location, { state: newState });
  }

  function getPickerOptionLabelForRelationshipKind(kind: PlannerRelationshipKind) {
    switch (kind) {
      case PlannerRelationshipKind.TEACHER:
        return viewModel.existingRelationshipKinds.includes(PlannerRelationshipKind.TEACHER)
          ? strings.sharePlannerAnotherTeacherOption
          : strings.sharePlannerTeacherOption;

      case PlannerRelationshipKind.PARENT:
        return viewModel.existingRelationshipKinds.includes(PlannerRelationshipKind.PARENT)
          ? strings.sharePlannerAnotherParentOption
          : strings.sharePlannerParentOption;

      case PlannerRelationshipKind.INDIVIDUAL:
        return viewModel.userRelationshipKind === PlannerRelationshipKind.PARENT
          ? strings.sharePlannerChildOption
          : strings.sharePlannerDefaultOption;

      default:
        // Should not be an option
        return strings.sharePlannerDefaultOption;
    }
  }

  return (
    <Stack {...props} className={className} spacing={2}>
      <Box>
        <Typography variant="subtitle2">{strings.currentUsersHeader}</Typography>
        <UpdatablePresenter
          size="small"
          hasLoadingMinimumHeight={false}
          viewModel={viewModel.relationshipDetails}
          loadingMessage={strings.sharePlannerStatusLoadingMessage}
          errorMessageSelector={(error) => strings.sharePlannerStatusLoadingErrorMessage(error.message)}
          renderData={() => (
            <Box
              sx={{
                width: '100%'
              }}
            >
              <List dense>
                {viewModel.relationshipDetails.value!.map((details) => (
                  <ListItem
                    disablePadding
                    key={details.userId}
                    secondaryAction={
                      <PlannerIcon
                        relationship={viewModel.getPrimaryRelationshipKind(details.relationshipKinds)}
                        showTooltip
                        size="small"
                      />
                    }
                  >
                    <ListItemAvatar>
                      <Avatar src={details.pictureUrl} alt={viewModel.getDisplayedFullName(details)} />
                    </ListItemAvatar>

                    <ListItemText
                      primary={viewModel.getDisplayedFullName(details)}
                      secondary={viewModel.getDisplayedEmailAddress(details)}
                      slotProps={{ secondary: { sx: { wordWrap: 'break-word' } } }}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        />
      </Box>
      {viewModel.selectablePlannerRelationshipKinds.length > 0 && (
        <Stack spacing={3}>
          <Stack spacing={2}>
            <Typography variant="subtitle2">{strings.shareSectionTitle}</Typography>
            <FormControl fullWidth>
              <InputLabel>{strings.pickerLabel}</InputLabel>
              <ControlledSelect
                value={viewModel.selectedPlannerRelationshipKind}
                label={strings.pickerLabel}
                onChange={(e) =>
                  (viewModel.selectedPlannerRelationshipKind = e.target.value as PlannerRelationshipKind)
                }
              >
                {viewModel.selectablePlannerRelationshipKinds.map((kind) => (
                  <MenuItem key={`share-planner-picker-kind-${kind}`} value={kind}>
                    {getPickerOptionLabelForRelationshipKind(kind)}
                  </MenuItem>
                ))}
              </ControlledSelect>
            </FormControl>
          </Stack>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <Button variant="contained" sx={{ minWidth: 150 }} onClick={share}>
              {strings.shareButton}
            </Button>

            {state.sharePlannerStatusShowShareForRelationship && (
              <SharePlannerDialog targetRelationship={state.sharePlannerStatusShowShareForRelationship} isOpen={true} />
            )}
          </Box>
        </Stack>
      )}
    </Stack>
  );
});
