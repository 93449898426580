import { PublishedWorkEditInfo } from '@/models';
import { Note } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/note_pb';
import { Work } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_pb';
import { PartialMessage } from '@bufbuild/protobuf';
import { Location } from 'react-router';
import { NavigateFunctionAsync } from '../../utils';
import { PlannerCopyItemKind } from '../../viewmodels';
import { NavigationService, UserDashboardPlannerItemsLocationState } from '../contracts';

export class AppNavigationService implements NavigationService {
  navigateToWorkEdit(
    navigate: NavigateFunctionAsync,
    location: Location,
    id: string | undefined,
    newWorkDefaultValues?: PartialMessage<Work>
  ) {
    this.navigateWithState(navigate, location, { workEdit: { workId: id, newWorkDefaultValues } });
  }

  navigateToPlannedWorkEdit(
    navigate: NavigateFunctionAsync,
    location: Location,
    workId: string,
    id: string | undefined
  ) {
    this.navigateWithState(navigate, location, { plannedWorkEdit: { workId, id } });
  }

  navigateToNoteEdit(
    navigate: NavigateFunctionAsync,
    location: Location,
    id: string | undefined,
    newNoteDefaultValues?: PartialMessage<Note>
  ) {
    this.navigateWithState(navigate, location, { noteEdit: { id, newNoteDefaultValues } });
  }

  navigateToPublishedWorkEdit(
    navigate: NavigateFunctionAsync,
    location: Location,
    ids:
      | {
          id: string;
          schoolId: string;
        }
      | undefined,
    newPublishedWorkInfo?: Partial<PublishedWorkEditInfo>
  ) {
    this.navigateWithState(navigate, location, { publishedWorkEdit: { ids, newPublishedWorkInfo } });
  }

  navigateToDistributeItem(navigate: NavigateFunctionAsync, location: Location, kind: PlannerCopyItemKind) {
    this.navigateWithState(navigate, location, { distributeItem: { kind } });
  }

  navigateToRepeatItem(navigate: NavigateFunctionAsync, location: Location, kind: PlannerCopyItemKind) {
    this.navigateWithState(navigate, location, { repeatItem: { kind } });
  }

  private navigateWithState(
    navigate: NavigateFunctionAsync,
    location: Location,
    partialState: Partial<UserDashboardPlannerItemsLocationState>
  ) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const newState: UserDashboardPlannerItemsLocationState = {
      ...(location.state ?? {}),
      ...partialState
    };
    void navigate(location, { state: newState });
  }
}
