import { AppOnboardingViewModel, OnboardingScreenInfo, OnboardingViewModel } from '@/viewmodels';
import { Box, styled } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useMemo, useRef } from 'react';
import { Navigate } from 'react-router';
import { RouteTemplates } from '../../RouteTemplates';
import { useNavigateAsync, useServices } from '../../hooks';
import { Splash } from '../Splash';
import { MediaQuery } from '../utils';
import { OnboardingClassroomConnectionStep } from './OnboardingClassroomConnectionStep';
import { OnboardingCreateSchool } from './OnboardingCreateSchool';
import { OnboardingStepper, OnboardingStepperProps } from './OnboardingStepper';
import { OnboardingStudentNoSchoolFound } from './OnboardingStudentNoSchoolFound';
import { ParentOnboardingOptions } from './ParentOnboardingOptions';
import { SharePlannerStep } from './SharePlannerStep';
import { StudentOnboardingOptions } from './StudentOnboardingOptions';
import { TeacherOnboardingOptions } from './TeacherOnboardingOptions';
import { UseSharingInvitationCodeStep } from './UseSharingInvitationCodeStep';
import { UserPersonaStep } from './UserPersonaStep';

export const OnboardingScreen = observer(() => {
  const { route, userStore } = useServices();
  const navigate = useNavigateAsync();

  const onComplete = async (vm: OnboardingViewModel) => {
    let targetRoute: string;

    if (vm.school?.wasCreatedByUser === true) {
      targetRoute = route.resolveAdminSchoolInformationLocation(vm.school.id);
    } else if (vm.plannerId != null) {
      targetRoute = route.resolvePlannerLocation(vm.plannerId);
    } else {
      // User was auto-matched. Navigating to root and he will be redirected to its planner.
      targetRoute = RouteTemplates.root;
    }

    return navigate(targetRoute, { replace: true });
  };

  const onboardingState = useRef<OnboardingViewModel>(new AppOnboardingViewModel((vm) => onComplete(vm)));

  const hasSchools = useMemo(() => userStore.schools.length > 0, []);
  // Filtering out demo planners and planners where user has a teacher relationship. For the latter, a
  // teacher who only has access to its students' planners should be shown the "create planner screen".
  const hasPlanners = useMemo(() => userStore.nonDemoNorTeacherPlanners.length > 0, []);

  if (!hasPlanners && hasSchools) {
    return <Navigate to={RouteTemplates.noPlanner} replace />;
  }

  return (
    <>
      <MediaQuery mediaQuery={(th) => th.breakpoints.up('sm')}>
        <Splash width="100%" height="100%" contentWidth={600}>
          <StyledStepper onboardingState={onboardingState.current} renderScreen={renderScreen} />
        </Splash>
      </MediaQuery>
      <MediaQuery mediaQuery={(th) => th.breakpoints.only('xs')}>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            p: { xs: 2, sm: 3 }
          }}
        >
          <StyledStepper onboardingState={onboardingState.current} renderScreen={renderScreen} />
        </Box>
      </MediaQuery>
    </>
  );
});

function renderScreen(info: OnboardingScreenInfo) {
  switch (info.case) {
    case 'user-persona-selection':
      return <UserPersonaStep viewModel={info.viewModel} />;

    case 'student-options':
      return <StudentOnboardingOptions viewModel={info.viewModel} />;

    case 'student-no-school-account':
      return <OnboardingStudentNoSchoolFound viewModel={info.viewModel} />;

    case 'parent-options':
      return <ParentOnboardingOptions viewModel={info.viewModel} />;

    case 'teacher-options':
      return <TeacherOnboardingOptions viewModel={info.viewModel} />;

    case 'create-school':
      return <OnboardingCreateSchool viewModel={info.viewModel} />;

    case 'use-code':
      return <UseSharingInvitationCodeStep viewModel={info.viewModel} />;

    case 'classroom-connection':
      return <OnboardingClassroomConnectionStep viewModel={info.viewModel} />;

    case 'share-planner':
      return <SharePlannerStep viewModel={info.viewModel} />;
  }
}

const StyledStepper = styled((props: OnboardingStepperProps) => <OnboardingStepper {...props} />)(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column'
}));
