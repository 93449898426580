import { computed, makeObservable } from 'mobx';
import { ServiceContainer } from '../../providers';
import { AppBaseOnboardingScreenViewModel, BaseOnboardingScreenViewModel } from './BaseOnboardingScreenViewModel';
import { OnboardingViewModel } from './OnboardingViewModel';

export interface OnboardingStudentNoSchoolAccountViewModel extends BaseOnboardingScreenViewModel {
  readonly email: string;
  retry(): Promise<void>;
  goBack(): void;
}

export class AppOnboardingStudentNoSchoolAccountViewModel
  extends AppBaseOnboardingScreenViewModel
  implements OnboardingStudentNoSchoolAccountViewModel
{
  readonly canSubmit = true;

  constructor(
    onboardingState: OnboardingViewModel,
    private readonly _authentication = ServiceContainer.services.authentication,
    private readonly _user = ServiceContainer.services.user,
    private readonly _userStore = ServiceContainer.services.userStore
  ) {
    super(onboardingState);
    makeObservable(this);
  }

  @computed
  get email(): string {
    return this._user.currentUser?.emailAddress ?? '';
  }

  async retry() {
    const { personaAutoDetectedAndPlannerCreated } = await this._authentication.autoDetectPersonaAndCreatePlanner();

    if (personaAutoDetectedAndPlannerCreated) {
      await this._onboardingState.complete();
    }
  }

  goBack(): void {
    this._onboardingState.goBack();
  }

  submit(): Promise<void> {
    // No submit button
    return Promise.resolve();
  }
}
