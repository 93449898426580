export interface CustomEvent {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
  stopPropagation: () => void;
  isPropagationStopped: boolean;
}

export class AppCustomEvent implements CustomEvent {
  private _isPropagationStopped = false;

  constructor(
    readonly type: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    readonly payload?: any
  ) {}

  get isPropagationStopped(): boolean {
    return this._isPropagationStopped;
  }

  stopPropagation() {
    this._isPropagationStopped = true;
  }
}
