import {
  Day,
  TimeOfDay,
  dayToDate,
  isItemDueDuringPeriodOccurrence,
  plannerHasAccessKindsForUser,
  timeOfDayIsBetweenOthers,
  timestampToTimeOfDay
} from '@/models';
import { ServiceContainer } from '@/providers';
import { AccessKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/access_kind_pb';
import { CalendarEvent } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/calendar_event_pb';
import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { CourseSectionOccurrence } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_occurrence_pb';
import { Note } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/note_pb';
import { PlannedWork } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planned_work_pb';
import { PlannerDayAnnotation } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_day_annotation_pb';
import { PlannerDay } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_day_pb';
import { PlannerItem } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_item_pb';
import { PublishedWorkDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/published_work_details_pb';
import { Work } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_pb';
import { Timestamp } from '@bufbuild/protobuf';
import { chain } from 'lodash';
import { computed, makeObservable } from 'mobx';
import {
  UserDashboardCalendarEventInfo,
  makeRankedPositionsForCalendarItems,
  plannerCalendarEventToInfo,
  plannerCourseSectionOccurrenceToInfo,
  plannerNoteToInfo,
  plannerPlannedWorkToInfo,
  plannerWorkToInfo,
  publishedWorkToInfo
} from '../shared';
import { AppUserDashboardCalendarWeekDayViewModel } from './UserDashboardCalendarWeekDayViewModel';
import {
  UserDashboardCalendarWeekAllDayItemInfo,
  UserDashboardCalendarWeekPeriodInfo,
  UserDashboardCalendarWeekPeriodInfoItem,
  UserDashboardCalendarWeekPlannedWorkInfo,
  UserDashboardCalendarWeekPriority,
  UserDashboardCalendarWeekTimedCalendarEventInfo,
  UserDashboardCalendarWeekTimedItemInfo,
  UserDashboardCalendarWeekTimedNoteInfo,
  UserDashboardCalendarWeekTimedPublishedWorkInfo,
  UserDashboardCalendarWeekTimedWorkInfo
} from './UserDashboardCalendarWeekItems';

export class AppPlannerCalendarWeekDayViewModel extends AppUserDashboardCalendarWeekDayViewModel {
  constructor(
    day: Day,
    private readonly _plannerId: string,
    private readonly _getPlannerDay: () => PlannerDay | undefined,
    pointsPerHour: () => number,
    displayedCourseSectionIds: () => string[] | undefined,
    workStore = ServiceContainer.services.workStore,
    settings = ServiceContainer.services.settings,
    private readonly _pasteboard = ServiceContainer.services.pasteboard,
    private readonly _plannerStore = ServiceContainer.services.plannerStore,
    private readonly _userStore = ServiceContainer.services.userStore,
    private readonly _featureFlag = ServiceContainer.services.featureFlag
  ) {
    super(day, pointsPerHour, displayedCourseSectionIds, workStore, settings);
    makeObservable(this);
  }

  @computed
  protected get courseSections(): CourseSectionDetails[] {
    return this._plannerStore.getCourseSectionsInPlanner(this._plannerId).values;
  }

  @computed
  private get plannerDay(): PlannerDay | undefined {
    return this._getPlannerDay();
  }

  @computed
  private get isReadOnly() {
    const planner = this._userStore.getPlannerForId(this._plannerId)!;
    return !plannerHasAccessKindsForUser(this._userStore.user.userId, planner, AccessKind.FULL_ACCESS);
  }

  @computed
  private get canPublishWork(): boolean {
    return this._plannerStore.getCanPublishWorkInPlanner(this._plannerId);
  }

  @computed
  get cycleDayName(): string {
    if (this.plannerDay == null) {
      return '';
    }

    return !this.plannerDay.isDayOfWeekCycleDayName ? this.plannerDay.cycleDayName : '';
  }

  @computed
  get allDayItems(): UserDashboardCalendarWeekAllDayItemInfo[] {
    if (this.plannerDay == null) {
      return [];
    }

    return this.getAllDayItemsFromDayContent(this.plannerDay);
  }

  @computed
  get priorities(): UserDashboardCalendarWeekPriority[] {
    const allItems = this.plannerDay?.items ?? [];

    if (allItems.length === 0 || this.plannerDay == null) {
      return [];
    }

    return this.getTimedItems(allItems, dayToDate(this.plannerDay.day!));
  }

  private getAllDayItemsFromDayContent(dayContent: PlannerDay): UserDashboardCalendarWeekAllDayItemInfo[] {
    const items: UserDashboardCalendarWeekAllDayItemInfo[] = [];
    items.push(...this.getAnnotationsInfos(dayContent.annotations));
    items.push(...this.getAllDayItems(dayContent.items));
    return items;
  }

  private getAnnotationsInfos(annotations: PlannerDayAnnotation[]): UserDashboardCalendarWeekAllDayItemInfo[] {
    if (!this.filters.annotations) {
      return [];
    }

    return annotations.map((a) => ({
      case: 'annotation',
      value: { id: `${a.symbol}-${a.title}`, ...a }
    }));
  }

  private getAllDayItems(items: PlannerItem[]): UserDashboardCalendarWeekAllDayItemInfo[] {
    return chain(items)
      .map<UserDashboardCalendarWeekAllDayItemInfo | undefined>((item) => {
        if (!item.isContextualAllDay) {
          return undefined;
        }

        switch (item.item.case) {
          case 'calendarEvent':
            return this.filters.calendarEvents
              ? { case: 'calendarEvent', value: this.mapCalendarEventToInfo(item.item.value) }
              : undefined;

          case 'work': {
            if (item.plannedWork != null) {
              return undefined;
            }

            const work = item.item.value;
            if (!this.filters.works) {
              return undefined;
            }
            const courseIsDisplayed = this.shouldDisplayItemWithCourseSectionId(work.courseSectionId);
            return courseIsDisplayed
              ? {
                  case: 'work',
                  value: plannerWorkToInfo(
                    work,
                    this.courseSectionsById,
                    this.workIcons,
                    this._now,
                    this.isReadOnly,
                    this._pasteboard,
                    this._workStore,
                    this._plannerStore,
                    this._featureFlag
                  )
                }
              : undefined;
          }

          case 'note': {
            const note = item.item.value;
            if (!this.filters.notes) {
              return undefined;
            }
            const courseIsDisplayed = this.shouldDisplayItemWithCourseSectionId(note.courseSectionId);
            return courseIsDisplayed
              ? {
                  case: 'note',
                  value: plannerNoteToInfo(
                    note,
                    this.courseSectionsById,
                    this._now,
                    this._pasteboard,
                    this._featureFlag
                  )
                }
              : undefined;
          }

          case 'publishedWork': {
            const publishedWorkDetails = item.item.value;
            if (!this.filters.works) {
              return undefined;
            }
            const courseIsDisplayed = this.shouldDisplayItemWithCourseSectionId(publishedWorkDetails.courseSectionId);
            return courseIsDisplayed
              ? {
                  case: 'publishedWork',
                  value: publishedWorkToInfo(
                    publishedWorkDetails,
                    this.courseSectionsById,
                    this.workIcons,
                    this._now,
                    this.isReadOnly,
                    this._pasteboard,
                    this._featureFlag
                  )
                }
              : undefined;
          }

          default:
            return undefined;
        }
      })
      .compact()
      .value();
  }

  private getTimedItems(items: PlannerItem[], date: Date): UserDashboardCalendarWeekPriority[] {
    const itemInfos = this.getTimedItemsInfo(items, date);
    return makeRankedPositionsForCalendarItems(itemInfos, (item) => item.value.position);
  }

  private getTimedItemsInfo(items: PlannerItem[], date: Date): UserDashboardCalendarWeekTimedItemInfo[] {
    const displayedItems = this.getDisplayedTimedItems(items);

    const periodTimes = chain(displayedItems)
      .map((item) => {
        if (item.item.case === 'courseSectionOccurrence') {
          const period = item.item.value;
          return [period.startTime!, period.endTime!];
        }

        return undefined;
      })
      .compact()
      .value();

    return chain(displayedItems)
      .map<UserDashboardCalendarWeekTimedItemInfo | undefined>((item) => {
        switch (item.item.case) {
          case 'calendarEvent':
            return {
              case: 'calendarEvent',
              value: this.mapTimedCalendarEventToInfo(item.item.value, item)
            };

          case 'work': {
            const work = item.item.value;
            if (item.plannedWork != null) {
              const info = this.mapPlannedWorkToInfo(item.plannedWork, work, item);
              return { case: 'plannedWork', value: info };
            } else {
              return !this.isTimedItemInAPeriod(work.dueTime!, periodTimes)
                ? { case: 'work', value: this.mapTimedWorkToInfo(work, item) }
                : undefined;
            }
          }

          case 'note': {
            const note = item.item.value;
            return !this.isTimedItemInAPeriod(note.time!, periodTimes)
              ? { case: 'note', value: this.mapTimedNoteToInfo(note, item) }
              : undefined;
          }

          case 'publishedWork': {
            const publishedWorkDetails = item.item.value;
            return !this.isTimedItemInAPeriod(publishedWorkDetails.publishedWork!.dueTime!, periodTimes)
              ? { case: 'publishedWork', value: this.mapTimedPublishedWorkToInfo(publishedWorkDetails, item) }
              : undefined;
          }

          case 'courseSectionOccurrence': {
            const works = this.getItemsForCourseSectionOccurrence(item.item.value, displayedItems);
            const info = this.mapCourseSectionOccurrenceToInfo(item.item.value, date, item, works);
            return { case: 'period', value: info };
          }
        }
      })
      .compact()
      .value();
  }

  private getDisplayedTimedItems(items: PlannerItem[]): PlannerItem[] {
    return items.filter((item) => {
      if (item.isContextualAllDay) {
        return false;
      }

      switch (item.item.case) {
        case 'calendarEvent':
          return this.filters.calendarEvents;

        case 'work': {
          const work = item.item.value;

          if (item.plannedWork != null) {
            return this.filters.plannedWorks && this.shouldDisplayItemWithCourseSectionId(work.courseSectionId);
          } else {
            return this.filters.works && this.shouldDisplayItemWithCourseSectionId(work.courseSectionId);
          }
        }

        case 'note': {
          return this.filters.notes && this.shouldDisplayItemWithCourseSectionId(item.item.value.courseSectionId);
        }

        case 'publishedWork': {
          return this.filters.works && this.shouldDisplayItemWithCourseSectionId(item.item.value.courseSectionId);
        }

        case 'courseSectionOccurrence':
          return this.filters.periods && this.shouldDisplayItemWithCourseSectionId(item.item.value.courseSectionId);

        case undefined:
          return false;
      }
    });
  }

  private isTimedItemInAPeriod(time: Timestamp, periodTimes: TimeOfDay[][]): boolean {
    return periodTimes.some((times) =>
      timeOfDayIsBetweenOthers(timestampToTimeOfDay(time), times[0], times[1], {
        endInclusive: false
      })
    );
  }

  private mapCalendarEventToInfo(event: CalendarEvent): UserDashboardCalendarEventInfo {
    return plannerCalendarEventToInfo(event, this._now);
  }

  private mapTimedCalendarEventToInfo(
    event: CalendarEvent,
    item: PlannerItem
  ): UserDashboardCalendarWeekTimedCalendarEventInfo {
    const startTime = item.contextualStartTime!;
    const endTime = item.contextualEndTime!;

    return {
      ...this.mapCalendarEventToInfo(event),
      startTime: timestampToTimeOfDay(startTime),
      endTime: timestampToTimeOfDay(endTime),
      position: this.getPositionForItem(startTime, endTime)
    };
  }

  private mapTimedWorkToInfo(work: Work, item: PlannerItem): UserDashboardCalendarWeekTimedWorkInfo {
    const baseWorkInfo = plannerWorkToInfo(
      work,
      this.courseSectionsById,
      this.workIcons,
      this._now,
      this.isReadOnly,
      this._pasteboard,
      this._workStore,
      this._plannerStore,
      this._featureFlag
    );

    const startTime = item.contextualStartTime!;
    return {
      ...baseWorkInfo,
      startTime: timestampToTimeOfDay(startTime),
      position: this.getPositionForItem(
        startTime,
        undefined,
        (baseWorkInfo.description?.plainText ?? '').length > 0 ? 37 : 20
      )
    };
  }

  private mapTimedNoteToInfo(note: Note, item: PlannerItem): UserDashboardCalendarWeekTimedNoteInfo {
    const baseNoteInfo = plannerNoteToInfo(
      note,
      this.courseSectionsById,
      this._now,
      this._pasteboard,
      this._featureFlag
    );
    const startTime = item.contextualStartTime!;
    return {
      ...baseNoteInfo,
      startTime: timestampToTimeOfDay(startTime),
      position: this.getPositionForItem(startTime, undefined, 37)
    };
  }

  private mapTimedPublishedWorkToInfo(
    work: PublishedWorkDetails,
    item: PlannerItem
  ): UserDashboardCalendarWeekTimedPublishedWorkInfo {
    const baseWorkInfo = publishedWorkToInfo(
      work,
      this.courseSectionsById,
      this.workIcons,
      this._now,
      this.isReadOnly,
      this._pasteboard,
      this._featureFlag
    );
    const startTime = item.contextualStartTime!;

    return {
      ...baseWorkInfo,
      startTime: timestampToTimeOfDay(startTime),
      position: this.getPositionForItem(
        startTime,
        undefined,
        (baseWorkInfo.description?.plainText ?? '').length > 0 ? 37 : 20
      )
    };
  }

  private mapPlannedWorkToInfo(
    plannedWork: PlannedWork,
    work: Work,
    item: PlannerItem
  ): UserDashboardCalendarWeekPlannedWorkInfo {
    const startTime = item.contextualStartTime!;
    const endTime = item.contextualEndTime!;

    return {
      ...plannerPlannedWorkToInfo(
        plannedWork,
        work,
        this.courseSectionsById,
        this.workIcons,
        this._now,
        this.isReadOnly,
        this._pasteboard,
        this._workStore,
        this._featureFlag
      ),
      position: this.getPositionForItem(startTime, endTime)
    };
  }

  private mapCourseSectionOccurrenceToInfo(
    occurrence: CourseSectionOccurrence,
    date: Date,
    item: PlannerItem,
    items: UserDashboardCalendarWeekPeriodInfoItem[]
  ): UserDashboardCalendarWeekPeriodInfo {
    const startTime = item.contextualStartTime!;
    const endTime = item.contextualEndTime!;

    return {
      ...plannerCourseSectionOccurrenceToInfo(
        occurrence,
        this.courseSectionsById,
        date,
        this._now,
        this.isReadOnly,
        this.canPublishWork,
        this._pasteboard,
        this._featureFlag
      ),
      items,
      position: this.getPositionForItem(startTime, endTime)
    };
  }

  private shouldDisplayItemWithCourseSectionId(id: string): boolean {
    if (id.length === 0) {
      return true;
    }

    const courseSection = this.courseSectionsById.get(id);
    return (
      courseSection == null ||
      this.displayedCourseSectionIds == null ||
      this.displayedCourseSectionIds.includes(courseSection.courseSection!.id)
    );
  }

  private getItemsForCourseSectionOccurrence(
    occurrence: CourseSectionOccurrence,
    items: PlannerItem[]
  ): UserDashboardCalendarWeekPeriodInfoItem[] {
    return chain(items)
      .map<UserDashboardCalendarWeekPeriodInfoItem | undefined>((item) => {
        switch (item.item.case) {
          case 'work': {
            const work = item.item.value;

            return item.plannedWork == null &&
              isItemDueDuringPeriodOccurrence(work.dueTime!, work.courseSectionId, occurrence, items)
              ? {
                  case: 'work',
                  value: plannerWorkToInfo(
                    work,
                    this.courseSectionsById,
                    this.workIcons,
                    this._now,
                    this.isReadOnly,
                    this._pasteboard,
                    this._workStore,
                    this._plannerStore,
                    this._featureFlag
                  )
                }
              : undefined;
          }

          case 'publishedWork': {
            const publishedWorkDetails = item.item.value;
            return isItemDueDuringPeriodOccurrence(
              publishedWorkDetails.publishedWork!.dueTime!,
              publishedWorkDetails.courseSectionId,
              occurrence,
              items
            )
              ? {
                  case: 'publishedWork',
                  value: publishedWorkToInfo(
                    publishedWorkDetails,
                    this.courseSectionsById,
                    this.workIcons,
                    this._now,
                    this.isReadOnly,
                    this._pasteboard,
                    this._featureFlag
                  )
                }
              : undefined;
          }

          case 'note': {
            const note = item.item.value;
            return isItemDueDuringPeriodOccurrence(note.time!, note.courseSectionId, occurrence, items)
              ? {
                  case: 'note',
                  value: plannerNoteToInfo(
                    note,
                    this.courseSectionsById,
                    this._now,
                    this._pasteboard,
                    this._featureFlag
                  )
                }
              : undefined;
          }

          default: {
            return undefined;
          }
        }
      })
      .compact()
      .value();
  }
}
