import i18n from 'translate';

export const OnboardingTeacherOptionsSchoolKindExplanationStrings = {
  personalSchoolPanelMessage: () =>
    i18n.t('onboarding.teacherOptions.schoolKindExplanation.personalSchoolPanelMessage'),
  personalSchoolPanelTitle: () => i18n.t('onboarding.teacherOptions.schoolKindExplanation.personalSchoolPanelTitle'),
  sharedSchoolPanelMessage: () => i18n.t('onboarding.teacherOptions.schoolKindExplanation.sharedSchoolPanelMessage'),
  sharedSchoolPanelTitle: () => i18n.t('onboarding.teacherOptions.schoolKindExplanation.sharedSchoolPanelTitle'),
  title: () => i18n.t('onboarding.teacherOptions.schoolKindExplanation.title')
};

export const OnboardingTeacherOptionsStrings = {
  createSchoolSubtitle: () => i18n.t('onboarding.teacherOptions.createSchoolSubtitle'),
  createSchoolTitle: () => i18n.t('onboarding.teacherOptions.createSchoolTitle'),
  schoolKindExplanation: OnboardingTeacherOptionsSchoolKindExplanationStrings,
  signOut: () => i18n.t('onboarding.teacherOptions.signOut'),
  useCodeSubtitle: () => i18n.t('onboarding.teacherOptions.useCodeSubtitle'),
  useCodeTitle: () => i18n.t('onboarding.teacherOptions.useCodeTitle')
};
