import { useServices } from '@/hooks';
import { PlannerListItemInfo } from '@/viewmodels';
import { CheckCircleRounded, CircleOutlined } from '@mui/icons-material';
import { Box, IconButton, ListItemText, Stack, Typography, TypographyProps, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { ContextMenuHandler } from '../../../shared';
import { PlannerListItemAvatar } from './PlannerListItemAvatar';
import { PlannerListItemDateDetails } from './PlannerListItemDateDetails';
import { PlannerListItemRoot } from './PlannerListItemRoot';

export interface PlannerListItemProps {
  sx?: SxProps;
  className?: string;
  item: PlannerListItemInfo;
  displaySteps: boolean;
}

export const PlannerListItem = observer(({ item, className, sx = [], displaySteps }: PlannerListItemProps) => {
  const theme = useTheme();
  const { localization } = useServices();
  const strings = localization.localizedStrings.workList;
  const isHighlighted = item.state === 'current';

  const typographyProps: TypographyProps<'span', { component?: 'span' | undefined }> = {
    style: {
      textDecoration: item.state === 'completed' ? 'line-through' : undefined,
      color: item.state === 'completed' ? theme.palette.text.disabled : undefined
    },
    noWrap: true
  };

  return (
    <ContextMenuHandler actions={item.contextMenuActions}>
      {(contextMenuHandler) => (
        <PlannerListItemRoot
          className={className}
          sx={sx}
          isHighlighted={isHighlighted}
          url={item.url}
          onContextMenu={contextMenuHandler}
        >
          <Stack
            spacing={0.5}
            sx={{
              width: '100%'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <PlannerListItemAvatar color={item.color} icon={item.icon} />

              <ListItemText
                sx={{ ml: 2, mr: 1, flex: 1 }}
                primary={item.title}
                secondary={
                  item.description != null && item.description.length > 0 ? item.description : strings.noDescription
                }
                slotProps={{
                  primary: typographyProps,
                  secondary: { ...typographyProps, component: 'span' }
                }}
              />

              <PlannerListItemDateDetails item={item} />
            </Box>

            {displaySteps && item.stepsInfo != null && item.stepsInfo.steps.length > 0 && (
              <Stack>
                {item.stepsInfo.steps.map((s) => (
                  <Stack
                    key={s.id}
                    direction="row"
                    spacing={1}
                    sx={{
                      alignItems: 'center'
                    }}
                  >
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        s.isCompleted = !s.isCompleted;
                      }}
                    >
                      {s.isCompleted ? <CheckCircleRounded fontSize="small" /> : <CircleOutlined fontSize="small" />}
                    </IconButton>

                    <Typography
                      noWrap
                      variant="body2"
                      sx={{
                        flex: 1,
                        color: theme.palette.text[s.isCompleted ? 'secondary' : 'primary'],
                        textDecoration: s.isCompleted ? 'line-through' : undefined
                      }}
                    >
                      {s.title}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            )}
          </Stack>
        </PlannerListItemRoot>
      )}
    </ContextMenuHandler>
  );
});
