import { ReactNode } from 'react';
import { EventEmitterContext } from '../../contexts';
import { EventEmitter } from '../../events';

export interface EventEmitterProviderProps {
  children: ReactNode;
}

export const EventEmitterProvider = ({ children }: EventEmitterProviderProps) => {
  const emitter = new EventEmitter();
  return <EventEmitterContext value={emitter}>{children}</EventEmitterContext>;
};
