import { useActivePlannerId, useServices, useSync, useViewModel } from '@/hooks';
import { Badge, Stack, Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useParams } from 'react-router';
import { isSxArray } from '../../../../utils';
import { StudentPlannerList } from './StudentPlannerList';
import { StudentPlannerListSummary } from './StudentPlannerListSummary';

export interface StudentPlannerProps {
  sx?: SxProps;
  className?: string;
}

export const StudentPlanner = observer(({ sx = [], className }: StudentPlannerProps) => {
  const { localization, settings } = useServices();
  const strings = localization.localizedStrings.schoolCourseSection;
  const theme = useTheme();

  const [selectedColumn, setSelectedColumn] = useState(0);
  const params = useParams();
  const schoolId = params.schoolId ?? '';
  const courseSectionId = params.courseId ?? '';
  const studentId = params.studentId ?? '';
  const currentId = `${courseSectionId}-${studentId}`;
  const plannerId = useActivePlannerId();

  const courseViewModel = useViewModel(
    (viewModels) => viewModels.createCourseSectionDetailsViewModel(courseSectionId, plannerId, schoolId),
    [courseSectionId]
  );
  const student = courseViewModel.studentsPlannerSummary.allStudents.find((s) => s.id === studentId)!;
  const studentPlannerId = student.plannerId ?? '';
  const viewModel = useViewModel(
    (viewModels) => viewModels.createStudentPlannerViewModel(studentPlannerId),
    [studentPlannerId]
  );

  const isLargeScreen = useMediaQuery(() => theme.breakpoints.up('xl'));
  const isSmallScreen = useMediaQuery(() => theme.breakpoints.down(settings.isSchoolSidebarCollapsed ? 'md' : 'lg'));
  const isSmallerScreen = useMediaQuery(() => theme.breakpoints.only('xs'));

  const showInbox = selectedColumn === 0 || isLargeScreen;
  const showToday = selectedColumn === 1 || isLargeScreen;
  const showUpcoming = selectedColumn === 2 || isLargeScreen;
  const showDone = selectedColumn === 3 || isLargeScreen;

  useSync(
    'student-planner',
    async () => {
      await viewModel.reloadData();
    },
    [currentId]
  );

  return (
    <Stack
      className={className}
      direction="column"
      spacing={2}
      sx={[
        {
          pb: { xs: 1, sm: 3 },
          height: { xs: '100%', md: undefined }
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      {isSmallScreen && (
        <Tabs
          value={selectedColumn}
          onChange={(_, v: number) => setSelectedColumn(v)}
          centered={!isSmallerScreen}
          variant={isSmallerScreen ? 'scrollable' : undefined}
        >
          <Tab
            label={
              <Badge badgeContent={student.inboxItemsCount} color="secondary">
                {strings.studentDetailsPlannerInboxTitle}
              </Badge>
            }
            value={0}
          />
          <Tab
            label={
              <Badge badgeContent={student.todayItemsCount} color="secondary">
                {strings.studentDetailsPlannerTodayTitle}
              </Badge>
            }
            value={1}
          />
          <Tab
            label={
              <Badge badgeContent={student.upcomingItemsCount} color="secondary">
                {strings.studentDetailsPlannerUpcomingTitle}
              </Badge>
            }
            value={2}
          />
          <Tab
            label={
              <Badge badgeContent={student.doneItemsCount} color="secondary">
                {strings.studentDetailsPlannerDoneTitle}
              </Badge>
            }
            value={3}
          />
        </Tabs>
      )}
      <Stack
        direction="row"
        spacing={2}
        sx={{
          alignItems: 'stretch',
          height: { xs: undefined, md: 500 },
          px: { xs: 1, sm: 3 },

          [theme.breakpoints.down('md')]: {
            flex: 1
          }
        }}
      >
        {showInbox ? (
          <StudentPlannerList
            kind="inbox"
            viewModel={viewModel.inboxViewModel}
            connectionState={student.connectionState}
            showNotConnectedState
          />
        ) : !isSmallScreen ? (
          <StudentPlannerListSummary
            title={strings.studentDetailsPlannerInboxTitle}
            onSelect={() => setSelectedColumn(0)}
            itemCount={student.inboxItemsCount}
          />
        ) : undefined}

        {showToday ? (
          <StudentPlannerList
            kind="today"
            viewModel={viewModel.todayViewModel}
            connectionState={student.connectionState}
            showNotConnectedState={!isLargeScreen}
          />
        ) : !isSmallScreen ? (
          <StudentPlannerListSummary
            title={strings.studentDetailsPlannerTodayTitle}
            onSelect={() => setSelectedColumn(1)}
            itemCount={student.todayItemsCount}
          />
        ) : undefined}

        {showUpcoming ? (
          <StudentPlannerList
            kind="upcoming"
            viewModel={viewModel.upcomingViewModel}
            connectionState={student.connectionState}
            showNotConnectedState={!isLargeScreen}
          />
        ) : !isSmallScreen ? (
          <StudentPlannerListSummary
            title={strings.studentDetailsPlannerUpcomingTitle}
            onSelect={() => setSelectedColumn(2)}
            itemCount={student.upcomingItemsCount}
          />
        ) : undefined}

        {showDone ? (
          <StudentPlannerList
            kind="done"
            viewModel={viewModel.doneViewModel}
            connectionState={student.connectionState}
            showNotConnectedState={!isLargeScreen}
          />
        ) : !isSmallScreen ? (
          <StudentPlannerListSummary
            title={strings.studentDetailsPlannerDoneTitle}
            onSelect={() => setSelectedColumn(3)}
            itemCount={student.doneItemsCount}
          />
        ) : undefined}
      </Stack>
    </Stack>
  );
});
