import { useActivePlannerId, useNavigateAsync, useViewModel } from '@/hooks';
import { ItemDistributionPatternKind } from '@/models';
import { PlannerCopyItemKind } from '@/viewmodels';
import { AccessTimeRounded, PinRounded, TodayRounded } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { Observer, observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { Dialog, LegendButton, LegendSection, Subheader } from '../../../utils';
import { PlannerCopyOriginalItemCard } from '../PlannerCopyOriginalItemCard';
import { PlannerPastePlannerItemOption } from '../paste-item/PlannerPastePlannerItemOption';
import { PlannerDistributeItemCourseCell } from './PlannerDistributeItemCourseCell';

export interface PlannerDistributeItemDialogProps {
  sx?: SxProps;
  className?: string;
  isOpen: boolean;
  itemKind: PlannerCopyItemKind;
}

export const PlannerDistributeItemDialog = observer(
  ({ sx = [], className, isOpen, itemKind }: PlannerDistributeItemDialogProps) => {
    const plannerId = useActivePlannerId();
    const navigate = useNavigateAsync();
    const strings = LocalizedStrings.planner.distributeItem;

    function dismiss() {
      return navigate(-1);
    }

    const viewModel = useViewModel(
      (viewModels) => viewModels.createPlannerDistributeItemViewModel(plannerId, itemKind, dismiss),
      [plannerId, itemKind]
    );

    return (
      <Dialog
        sx={sx}
        className={className}
        viewModel={viewModel}
        isOpen={isOpen}
        title={strings.title()}
        width="sm"
        renderData={() => (
          <Stack
            spacing={1}
            sx={{
              width: '100%'
            }}
          >
            <PlannerCopyOriginalItemCard item={viewModel.item} />

            <Stack>
              <Subheader>{strings.coursesSelectionSectionTitle()}</Subheader>

              <Observer>
                {() => (
                  <Stack spacing={0.5}>
                    {viewModel.courseSections.map((cs) => (
                      <PlannerDistributeItemCourseCell
                        key={cs.id}
                        course={cs}
                        isSelected={viewModel.selectedCourseSectionIds.includes(cs.id)}
                        onClick={() => viewModel.toggleSelectionOfCourse(cs.id)}
                      />
                    ))}
                  </Stack>
                )}
              </Observer>
            </Stack>

            <Stack>
              <Stack direction="row" spacing={1}>
                <Subheader sx={{ flex: 1 }}>{strings.patternSelectionSectionTitle()}</Subheader>
                <LegendButton isIconOnly sections={makeLegendSections(viewModel.availableTimeOptions)} />
              </Stack>

              {viewModel.availableTimeOptions.map((option) => (
                <PlannerPastePlannerItemOption
                  key={option}
                  isSelected={viewModel.timeOption === option}
                  title={titleForTimeOption(option)}
                  icon={iconForTimeOption(option)}
                  onSelect={() => (viewModel.timeOption = option)}
                />
              ))}
            </Stack>
          </Stack>
        )}
      />
    );
  }
);

function iconForTimeOption(option: ItemDistributionPatternKind) {
  switch (option) {
    case 'same-time':
      return <AccessTimeRounded fontSize="small" />;

    case 'same-day':
      return <TodayRounded fontSize="small" />;

    case 'same-occurrence':
      return <PinRounded fontSize="small" />;
  }
}

function titleForTimeOption(option: ItemDistributionPatternKind): string {
  const strings = LocalizedStrings.planner.distributeItem;

  switch (option) {
    case 'same-time':
      return strings.sameTimeOptionTitle();

    case 'same-day':
      return strings.sameDayOptionTitle();

    case 'same-occurrence':
      return strings.sameOccurrenceOptionTitle();

    default:
      return '';
  }
}

function makeLegendSections(availablePatterns: ItemDistributionPatternKind[]): LegendSection[] {
  const strings = LocalizedStrings.planner.distributeItem;
  return [
    {
      items: [
        {
          title: strings.sameTimeOptionTitle(),
          subtitle: strings.sameTimeDetails(),
          icon: (size) => <AccessTimeRounded sx={{ width: size, height: size }} />,
          hidden: !availablePatterns.includes('same-time')
        },
        {
          title: strings.sameDayOptionTitle(),
          subtitle: strings.sameDayDetails(),
          icon: (size) => <TodayRounded sx={{ width: size, height: size }} />,
          hidden: !availablePatterns.includes('same-day')
        },
        {
          title: strings.sameOccurrenceOptionTitle(),
          subtitle: strings.sameOccurrenceDetails(),
          icon: (size) => <PinRounded sx={{ width: size, height: size }} />,
          hidden: !availablePatterns.includes('same-occurrence')
        }
      ]
    }
  ];
}
