import { useNavigateAsync, useServices, useViewModel } from '@/hooks';
import { observer } from 'mobx-react-lite';
import { Dialog } from '../../utils';
import { CreatePlanner } from './CreatePlanner';

export interface CreatePlannerDialogProps {
  isOpen: boolean;
}

export const CreatePlannerDialog = observer(({ isOpen }: CreatePlannerDialogProps) => {
  const { localization, route } = useServices();
  const strings = localization.localizedStrings.planners;
  const navigate = useNavigateAsync();

  function onCloseHandler(plannerId: string | undefined) {
    if (plannerId != null) {
      const newPlannerRoute = route.resolvePlannerLocation(plannerId);
      void navigate(newPlannerRoute, { replace: true });
    } else {
      void navigate(-1);
    }
  }

  const viewModel = useViewModel((viewModels) => viewModels.createCreatePlannerDialogViewModel(onCloseHandler));

  return (
    <Dialog
      isOpen={isOpen}
      viewModel={viewModel}
      title={strings.createPlannerDialogTitle}
      width="sm"
      renderData={() => <CreatePlanner viewModel={viewModel} />}
    />
  );
});
