import { DraggedItem } from '@/contexts';
import { Day, dayFromString, DayOfWeek, dayToDate } from '@/models';
import {
  ScheduleCycleKind,
  ScheduleCycleSpecialDaysViewModel,
  ScheduleCycleSpecialDaysViewModelSelection,
  titleForCycleDay
} from '@/viewmodels';
import { AddRounded, PreviewRounded } from '@mui/icons-material';
import { Box, IconButton, Popover, Stack, Tooltip } from '@mui/material';
import { SxProps } from '@mui/system';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useLocation } from 'react-router';
import LocalizedStrings from 'strings';
import { useBackButtonListener, useNavigateAsync } from '../../../../../hooks';
import { isSxArray } from '../../../../../utils';
import { DndContainer, MediaQuery, snapTopLeftToCursor } from '../../../../utils';
import { SpecialDayEditDialog } from './edit';
import { ScheduleCycleSpecialDaysCalendar } from './ScheduleCycleSpecialDaysCalendar';
import { ScheduleCycleSpecialDaysCycleDaysTable } from './ScheduleCycleSpecialDaysCycleDaysTable';
import { ScheduleCycleSpecialDaysDayOfWeekTable } from './ScheduleCycleSpecialDaysDayOfWeekTable';
import { ScheduleCycleSpecialDaysList } from './ScheduleCycleSpecialDaysList';
import { ScheduleCycleSpecialDaysSectionTitle } from './ScheduleCycleSpecialDaysSectionTitle';

interface LocationState {
  scheduleCycleSpecialDaysShowCreateDialog?: boolean;
}

export interface ScheduleCycleSpecialDaysProps {
  sx?: SxProps;
  className?: string;
  viewModel: ScheduleCycleSpecialDaysViewModel;
  isReadOnly?: boolean;
}

export const ScheduleCycleSpecialDays = observer(
  ({ sx = [], className, viewModel, isReadOnly = false }: ScheduleCycleSpecialDaysProps) => {
    const [calendarPreviewRef, setCalendarPreviewRef] = useState<HTMLButtonElement | undefined>(undefined);
    const location = useLocation();
    const state = (location.state ?? {}) as LocationState;
    const navigate = useNavigateAsync();
    const strings = LocalizedStrings.scheduleCycle.edit.specialDays;

    const resolvedIsReadOnly = viewModel.isReadOnly || isReadOnly;

    const selectedDay =
      viewModel.selection != null && viewModel.selection.case === 'day' ? viewModel.selection.value : undefined;
    const selectedCycleDay = viewModel.selection?.case === 'cycleDay' ? viewModel.selection.value : undefined;
    const selectedDayOfWeek = viewModel.selection?.case === 'dayOfWeek' ? viewModel.selection.value : undefined;

    function onDaySelection(day: Day | undefined) {
      viewModel.selection = day != null ? { case: 'day', value: day } : undefined;
    }

    function onCycleDaySelection(cycleDay: number) {
      viewModel.selection =
        viewModel.selection?.case === 'cycleDay' && viewModel.selection.value === cycleDay
          ? undefined
          : { case: 'cycleDay', value: cycleDay };
    }

    function onDayOfWeekSelection(dow: DayOfWeek) {
      viewModel.selection =
        viewModel.selection?.case === 'dayOfWeek' && viewModel.selection.value === dow
          ? undefined
          : { case: 'dayOfWeek', value: dow };
    }

    function createNewSpecialDay() {
      const newState: LocationState = { scheduleCycleSpecialDaysShowCreateDialog: true };
      void navigate(location, { state: newState });
    }

    function onDrop(droppableId: string, item: DraggedItem) {
      const droppableIdComponents = droppableId.split('/');
      const kind = droppableIdComponents[0];
      const value = droppableIdComponents[1];

      let when: ScheduleCycleSpecialDaysViewModelSelection;

      switch (kind) {
        case 'dow':
          when = { case: 'dayOfWeek', value: Number(value) as DayOfWeek };
          break;
        case 'cycleDay':
          when = { case: 'cycleDay', value: Number(value) };
          break;
        case 'day':
          when = { case: 'day', value: dayFromString(value) };
          break;
        default:
          throw new Error('Unknown droppable kind.');
      }

      viewModel.addSpecialDayOccurrence(item.id, when);
    }

    useBackButtonListener((e) => {
      if (calendarPreviewRef != null) {
        e.stopPropagation();
        setCalendarPreviewRef(undefined);
      }
    });

    return (
      <DndContainer onDrop={onDrop} modifiers={[snapTopLeftToCursor]}>
        <Stack
          className={className}
          direction="row"
          spacing={2}
          sx={[
            {
              height: '100%',
              width: '100%',
              pt: 2
            },
            ...(isSxArray(sx) ? sx : [sx])
          ]}
        >
          <MediaQuery mediaQuery={(th) => th.breakpoints.up('md')}>
            <Stack
              spacing={2}
              sx={{
                overflow: 'auto'
              }}
            >
              <ScheduleCycleSpecialDaysCalendar
                selectedDay={selectedDay}
                onDaySelection={onDaySelection}
                currentMonth={viewModel.currentMonth}
                currentDate={viewModel.currentCalendarDate}
                canGoToNextMonth={viewModel.canGoToNextMonth}
                canGoToPreviousMonth={viewModel.canGoToPreviousMonth}
                goToNextMonth={() => viewModel.goToNextMonth()}
                goToPreviousMonth={() => viewModel.goToPreviousMonth()}
                minDay={viewModel.startDay!}
                maxDay={viewModel.endDay!}
              />

              <Stack
                spacing={1}
                sx={{
                  alignItems: 'flex-start'
                }}
              >
                <ScheduleCycleSpecialDaysSectionTitle title={cycleDaySectionTitle(viewModel.scheduleCycleKind)} />

                <ScheduleCycleSpecialDaysCycleDaysTable
                  cycleDayCount={viewModel.cycleDayCount}
                  selectedCycleDay={selectedCycleDay}
                  dayAnnotationsForCycleDay={(cycleDay) =>
                    viewModel.getDayAnnotationsForCycleDayOrDayOfWeek('cycleDay', cycleDay)
                  }
                  onCycleDaySelection={onCycleDaySelection}
                  cycleDayNames={viewModel.cycleDayNames}
                  scheduleCycleKind={viewModel.scheduleCycleKind}
                />
              </Stack>

              <Stack
                spacing={1}
                sx={{
                  alignItems: 'flex-start'
                }}
              >
                <ScheduleCycleSpecialDaysSectionTitle title={strings.daysOfWeekSectionTitle()} />

                <ScheduleCycleSpecialDaysDayOfWeekTable
                  selectedDayOfWeek={selectedDayOfWeek}
                  onDayOfWeekSelection={onDayOfWeekSelection}
                  dayAnnotationsForDayOfWeek={(dow) =>
                    viewModel.getDayAnnotationsForCycleDayOrDayOfWeek('dayOfWeek', dow)
                  }
                />
              </Stack>
            </Stack>
          </MediaQuery>

          <Stack
            sx={{
              flex: 1,
              maxWidth: { xs: undefined, md: 350 }
            }}
          >
            <Stack
              direction="row"
              spacing={1}
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <ScheduleCycleSpecialDaysSectionTitle
                title={
                  viewModel.selection != null
                    ? titleForSelection(viewModel.selection, viewModel.scheduleCycleKind, viewModel.cycleDayNames)
                    : strings.list.title()
                }
                isHighlighted={viewModel.selection != null}
                clearHighlight={() => (viewModel.selection = undefined)}
              />

              <Box
                sx={{
                  flex: 1
                }}
              />

              <MediaQuery mediaQuery={(th) => th.breakpoints.down('md')}>
                <Box>
                  <Tooltip title={strings.showCalendarButtonTooltip()}>
                    <IconButton onClick={(e) => setCalendarPreviewRef(e.currentTarget)}>
                      <PreviewRounded />
                    </IconButton>
                  </Tooltip>

                  <Popover
                    open={calendarPreviewRef != null}
                    anchorEl={calendarPreviewRef}
                    onClose={() => setCalendarPreviewRef(undefined)}
                  >
                    <ScheduleCycleSpecialDaysCalendar
                      sx={{ p: 2 }}
                      selectedDay={selectedDay}
                      currentMonth={viewModel.currentMonth}
                      currentDate={viewModel.currentCalendarDate}
                      canGoToNextMonth={viewModel.canGoToNextMonth}
                      canGoToPreviousMonth={viewModel.canGoToPreviousMonth}
                      goToNextMonth={() => viewModel.goToNextMonth()}
                      goToPreviousMonth={() => viewModel.goToPreviousMonth()}
                      minDay={viewModel.startDay!}
                      maxDay={viewModel.endDay!}
                    />
                  </Popover>
                </Box>
              </MediaQuery>

              {!resolvedIsReadOnly && (
                <Box>
                  <IconButton onClick={createNewSpecialDay}>
                    <AddRounded />
                  </IconButton>

                  {state.scheduleCycleSpecialDaysShowCreateDialog && (
                    <SpecialDayEditDialog
                      specialDayId={undefined}
                      scheduleCycleId={viewModel.scheduleCycleId}
                      isOpen={true}
                      close={() => void navigate(-1)}
                      initialOccurrence={viewModel.selection}
                    />
                  )}
                </Box>
              )}
            </Stack>

            <ScheduleCycleSpecialDaysList
              sx={{ width: '100%' }}
              mainSpecialDays={viewModel.selection != null ? viewModel.selectionSpecialDays : viewModel.allSpecialDays}
              otherSpecialDays={viewModel.selectionOtherSpecialDays}
              isEditing={viewModel.selection != null}
              onEditAction={(id) => viewModel.toggleSelectionOfSpecialDay(id)}
              scheduleCycleKind={viewModel.scheduleCycleKind}
              cycleDayNames={viewModel.cycleDayNames}
              isReadOnly={resolvedIsReadOnly}
              onDelete={(id) => viewModel.deleteSpecialDay(id)}
            />
          </Stack>
        </Stack>
      </DndContainer>
    );
  }
);

function titleForSelection(
  selection: ScheduleCycleSpecialDaysViewModelSelection,
  scheduleCycleKind: ScheduleCycleKind,
  cycleDayNames: string[]
): string {
  switch (selection.case) {
    case 'cycleDay':
      return titleForCycleDay(selection.value, scheduleCycleKind, 'long', true, cycleDayNames);
    case 'dayOfWeek':
      return LocalizedStrings.dateTime.dayOfWeekTitle[selection.value]();
    case 'day':
      return format(dayToDate(selection.value), 'PPPP');
  }
}

function cycleDaySectionTitle(scheduleCycleKind: ScheduleCycleKind) {
  const strings = LocalizedStrings.scheduleCycle.edit.specialDays;
  switch (scheduleCycleKind) {
    case 'cycle-day':
      return strings.rotatingDaysSectionTitleCycleDay();
    case 'week':
      return strings.rotatingDaysSectionTitleWeek();
    case 'multiple-week':
      return strings.rotatingDaysSectionTitleMultipleWeeks();
  }
}
