import { Note } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/note_pb';
import { PartialMessage } from '@bufbuild/protobuf';
import {
  ArchiveRounded,
  ContentCopyRounded,
  DifferenceRounded,
  LowPriorityRounded,
  RepeatRounded
} from '@mui/icons-material';
import { Box, Grid2, Stack } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import LocalizedStrings from 'strings';
import { useNavigateAsync, useServices, useViewModel } from '../../hooks';
import { plannerCourseSectionDetailsToInfo } from '../../models';
import { CourseSectionEditDialog, CourseSectionPicker } from '../shared';
import {
  ConfirmationDialog,
  DateTimePicker,
  Dialog,
  DialogAdditionalAction,
  RichTextEditor,
  Subheader
} from '../utils';

interface LocationState {
  noteEditShowCreateCourseSection?: boolean;
}

export interface NoteEditDialogProps {
  /**
   * Id of the note we wish to edit. Optional. If undefined, it creates a new note.
   */
  noteId: string | undefined;
  /**
   * Initial values for a new work. Optional.
   */
  newNoteDefaultValues?: PartialMessage<Note>;
  isOpen: boolean;
}

export const NoteEditDialog = observer(({ noteId, newNoteDefaultValues, isOpen }: NoteEditDialogProps) => {
  const { featureFlag, route } = useServices();

  const params = useParams();
  const plannerId = params.plannerId ?? '';
  const strings = LocalizedStrings.note.edit;

  const location = useLocation();
  const state = (location.state ?? {}) as LocationState;
  const navigate = useNavigateAsync();
  const [showCancelWorkConfirmAlert, setShowCancelNoteConfirmAlert] = useState(false);

  function onSuccess() {
    return navigate(-1);
  }

  function close() {
    return navigate(-1);
  }

  useEffect(() => {
    route.onDialogPresent();
    return () => route.onDialogDismiss();
  }, []);

  // Using a ref to prevent the viewModel from being recreated when hot-reloading.
  const viewModelRef = useRef(
    useViewModel(
      (viewModels) => viewModels.createNoteEditViewModel(noteId, newNoteDefaultValues, plannerId, onSuccess, close),
      [plannerId]
    )
  );

  const viewModel = viewModelRef.current;

  function showCreateCourseSection() {
    const newState: LocationState = { ...state, noteEditShowCreateCourseSection: true };
    void navigate(location, { state: newState });
  }

  function onCreateCourseClose(courseSectionId: string | undefined) {
    if (courseSectionId != null) {
      viewModel.setCourseSection(courseSectionId);
    }
  }

  async function dismissCancelNoteConfirmAlert(hasConfirmed: boolean) {
    setShowCancelNoteConfirmAlert(false);

    if (hasConfirmed) {
      const wasCancelled = await viewModel.cancelNote();
      if (wasCancelled) {
        void navigate(route.resolvePlannerLocation(plannerId), { replace: true });
      }
    }
  }

  function duplicate() {
    viewModel.duplicate(navigate, location);
    return Promise.resolve();
  }

  function distribute() {
    viewModel.distribute(navigate, location);
    return Promise.resolve();
  }

  function repeat() {
    viewModel.repeat(navigate, location);
    return Promise.resolve();
  }

  const isItemDuplicationEnabled = featureFlag.isEnabled('item-duplication');

  const actions: DialogAdditionalAction[] =
    viewModel.hasData && !viewModel.isNewNote
      ? [
          {
            title: strings.copyNote(),
            icon: <ContentCopyRounded />,
            isHidden: !isItemDuplicationEnabled,
            action: () => {
              viewModel.copyToPasteboard();
              return Promise.resolve();
            }
          },
          {
            title: strings.duplicateNote(),
            icon: <DifferenceRounded />,
            isHidden: !isItemDuplicationEnabled || viewModel.hasChanges,
            action: duplicate
          },
          {
            title: strings.distributeNote(),
            icon: <LowPriorityRounded />,
            isHidden:
              !isItemDuplicationEnabled ||
              viewModel.hasChanges ||
              viewModel.time == null ||
              viewModel.courseSection == null,
            action: distribute
          },
          {
            title: strings.repeatNote(),
            icon: <RepeatRounded />,
            isHidden: !isItemDuplicationEnabled || viewModel.hasChanges,
            action: repeat
          },
          {
            title: strings.archiveNote(),
            icon: <ArchiveRounded />,
            isDestructive: true,
            action: () => {
              setShowCancelNoteConfirmAlert(true);
              return Promise.resolve();
            }
          }
        ]
      : [];

  return (
    <>
      <Dialog
        isOpen={isOpen}
        canToggleFullscreen
        width="lg"
        viewModel={viewModel}
        title={noteId != null ? strings.editTitle() : strings.addTitle()}
        fullScreenWidth="md"
        submit={() => void viewModel.save()}
        additionalActions={actions}
        renderData={() => (
          <Grid2
            container
            spacing={3}
            sx={{
              pb: viewModel.error != null ? 3 : 0,
              width: '100%'
            }}
          >
            <Grid2 size={{ xs: 12, md: 8 }}>
              <Stack direction="column" spacing={2}>
                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                  <RichTextEditor
                    id="note-edit"
                    autoFocus
                    alwaysDisplayLabel
                    label={strings.textLabel()}
                    content={viewModel.text}
                    onChange={(content) => (viewModel.text = content)}
                    disabled={viewModel.isApplying}
                    sx={{ height: 600 }}
                    sidebarMode="compact"
                  />
                </Box>
              </Stack>
            </Grid2>

            <Grid2 size={{ xs: 12, md: 4 }}>
              <Stack direction="column" spacing={2}>
                <Box>
                  <Stack direction="column" spacing={2}>
                    <CourseSectionPicker
                      label={strings.courseTitle()}
                      disabled={viewModel.isApplying}
                      selectedCourseId={viewModel.courseSection?.courseSection?.id}
                      allCourseSections={viewModel.allCourseSections.map(plannerCourseSectionDetailsToInfo)}
                      onChange={(id) => viewModel.setCourseSection(id)}
                      onCreateCourseClick={() => showCreateCourseSection()}
                      elevation={2}
                    />
                  </Stack>
                </Box>

                <Box>
                  <Subheader>{strings.timeSectionTitle()}</Subheader>
                  <DateTimePicker
                    direction="column"
                    value={viewModel.time}
                    disabled={viewModel.isApplying}
                    hasTime={!viewModel.isAllDay}
                    datePickerLabel={strings.dateInputLabel()}
                    timePickerLabel={strings.timeInputLabel()}
                    datePickerKind="user-dashboard-calendar"
                    highlightedDates={(from, to) => viewModel.getDatesWithOccurrenceForCourseSection(from, to)}
                    getPeriodTimesForDate={(date) => viewModel.getCourseSectionOccurrencesStartTimeForDate(date)}
                    dateFormat={strings.dateInputDateFormat()}
                    onChange={(value, hasTime) => {
                      viewModel.time = value;
                      viewModel.isAllDay = !hasTime;
                    }}
                  />
                </Box>
              </Stack>
            </Grid2>
          </Grid2>
        )}
      />
      {state.noteEditShowCreateCourseSection === true && (
        <CourseSectionEditDialog isOpen={true} courseSectionId={undefined} onClose={onCreateCourseClose} />
      )}
      {showCancelWorkConfirmAlert && (
        <ConfirmationDialog
          isOpen={true}
          title={strings.archiveNoteConfirmationTitle()}
          message={strings.archiveNoteConfirmationMessage()}
          confirmButtonLabel={strings.archiveNoteConfirmationButton()}
          onSubmit={(hasConfirmed) => void dismissCancelNoteConfirmAlert(hasConfirmed)}
        />
      )}
    </>
  );
});
