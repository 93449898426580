import { useActivePlannerId, useNavigateAsync, useServices } from '@/hooks';
import { UserDashboardCalendarDayPlannedWorkInfo, UserDashboardCalendarItemState } from '@/viewmodels';
import { CheckCircleRounded, RadioButtonUncheckedRounded } from '@mui/icons-material';
import { Box, CardActionArea, Checkbox, ListItem, ListItemText, Stack, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router';
import LocalizedStrings from 'strings';
import { RichTextViewer } from '../../../utils';
import { ContextMenuHandler } from '../../ContextMenuHandler';
import { PlannedWorkIcon } from '../../PlannedWorkIcon';

export interface UserDashboardCalendarDayPlannedWorkViewProps {
  sx?: SxProps;
  className?: string;
  plannedWork: UserDashboardCalendarDayPlannedWorkInfo;
}

export const UserDashboardCalendarDayPlannedWorkView = observer(
  ({ sx = [], className, plannedWork }: UserDashboardCalendarDayPlannedWorkViewProps) => {
    const theme = useTheme();

    const { route, settings } = useServices();
    const plannerId = useActivePlannerId();
    const location = useLocation();
    const navigate = useNavigateAsync();

    function showWorkDetails() {
      const newLocation = route.resolvePlannerWorkLocation(plannerId, plannedWork.workId);
      void navigate(newLocation, { state: { backgroundLocation: location } });
    }

    return (
      <ContextMenuHandler actions={plannedWork.contextMenuActions()}>
        {(contextMenuHandler) => (
          <Box
            sx={{
              ...sx,
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'stretch',
              p: 0.5,
              borderRadius: 1,
              overflow: 'hidden'
            }}
            className={className}
            onContextMenu={contextMenuHandler}
          >
            <PlannedWorkIcon color={plannedWork.color} icon={plannedWork.icon} />

            <Box
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden'
              }}
            >
              <CardActionArea
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'baseline',
                  px: 1,
                  py: 0.5,
                  borderRadius: 1,
                  width: '100%',
                  overflow: 'hidden'
                }}
                onClick={showWorkDetails}
              >
                <Stack sx={{ flex: 1 }}>
                  <Typography
                    color={getColorForTitle(plannedWork.state)}
                    noWrap={!settings.calendarDayOptions.expandWorkDetails}
                    sx={{ fontWeight: '500' }}
                  >
                    {plannedWork.title}
                  </Typography>

                  {settings.calendarDayOptions.expandWorkDetails && plannedWork.description != null ? (
                    <RichTextViewer
                      id="day-work-view"
                      label={LocalizedStrings.calendar.day.workInstructionsRichTextTitle()}
                      content={plannedWork.description.richText}
                      textSize="small"
                      canSelectText={false}
                      sx={{ maxHeight: 450 }}
                    />
                  ) : (
                    <Typography variant="body1" noWrap>
                      {plannedWork.description?.plainText}
                    </Typography>
                  )}
                </Stack>
              </CardActionArea>

              {plannedWork.steps.length > 0 && (
                <Stack
                  sx={{
                    overflow: 'hidden'
                  }}
                >
                  {plannedWork.steps.map((step) => (
                    <ListItem dense key={step.id}>
                      <Checkbox
                        checked={step.info.isCompleted}
                        onClick={() => (step.info.isCompleted = !step.info.isCompleted)}
                        sx={{
                          py: 0,
                          color: theme.palette.text.secondary,
                          ['&.Mui-checked']: {
                            color: theme.palette.text.secondary
                          }
                        }}
                        disableRipple
                        disabled={step.isReadOnly}
                        icon={<RadioButtonUncheckedRounded fontSize="small" />}
                        checkedIcon={<CheckCircleRounded fontSize="small" />}
                      />

                      <ListItemText
                        sx={{ flex: 1 }}
                        primary={step.info.title}
                        slotProps={{
                          primary: {
                            noWrap: !settings.calendarDayOptions.expandWorkDetails,
                            color: step.info.isCompleted ? 'textSecondary' : undefined,
                            sx: { textDecoration: step.info.isCompleted ? 'line-through' : undefined }
                          }
                        }}
                      />

                      {step.info.duration > 0 && (
                        <Typography
                          variant="caption"
                          noWrap
                          sx={{
                            color: theme.palette.text.secondary,
                            ml: 1
                          }}
                        >
                          {LocalizedStrings.dateTime.shortDurationFormat(step.info.duration)}
                        </Typography>
                      )}
                    </ListItem>
                  ))}
                </Stack>
              )}
            </Box>
          </Box>
        )}
      </ContextMenuHandler>
    );
  }
);

function getColorForTitle(state: UserDashboardCalendarItemState) {
  switch (state) {
    case 'completed':
      return 'textSecondary';
    default:
      return 'textPrimary';
  }
}
