import { LoadingPlannerScreen, NotFoundScreen, UserPropertiesDialog } from '@/components';
import { useServices } from '@/hooks';
import { observer } from 'mobx-react-lite';
import { Outlet } from 'react-router';

export interface PlannerRouteProps {
  plannerId: string;
}

export const PlannerRoute = observer(({ plannerId }: PlannerRouteProps) => {
  const { userStore } = useServices();
  const plannerLoadable = userStore.getPlannerLoadable(plannerId);
  const shouldRequireName = userStore.user.fullName.length === 0;

  if (plannerLoadable.hasData) {
    return (
      <>
        <Outlet />
        {shouldRequireName && <UserPropertiesDialog isOpen={true} />}
      </>
    );
  } else if (userStore.user.roles.includes('super-admin')) {
    return <LoadingPlannerScreen plannerLoadable={plannerLoadable} />;
  } else {
    return <NotFoundScreen />;
  }
});
