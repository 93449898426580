import { Box, Grid2 } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';
import { isSxArray } from '../../../../utils';
import { MediaQuery } from '../../../utils';
import { SchoolCourseSectionDetails } from './SchoolCourseSectionDetails';

export interface SchoolCourseSectionDetailsPageProps {
  sx?: SxProps;
  className?: string;
}

export const SchoolCourseSectionDetailsPage = observer(
  ({ sx = [], className }: SchoolCourseSectionDetailsPageProps) => {
    const params = useParams();
    const schoolId = params.schoolId ?? '';
    const courseSectionId = params.courseId ?? '';

    return (
      <Box
        className={className}
        sx={[
          {
            width: '100%',
            height: '100%'
          },
          ...(isSxArray(sx) ? sx : [sx])
        ]}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            overflowY: 'auto',
            overflowX: 'hidden',
            display: 'flex'
          }}
        >
          <MediaQuery mediaQuery={(th) => th.breakpoints.up('md')}>
            <Grid2 container spacing={2} rowSpacing={2} sx={{ width: '100%' }}>
              <Grid2 size={{ xs: 12 }}>
                <SchoolCourseSectionDetails schoolId={schoolId} courseSectionId={courseSectionId} />
              </Grid2>
            </Grid2>
          </MediaQuery>

          <MediaQuery mediaQuery={(th) => th.breakpoints.down('md')}>
            <SchoolCourseSectionDetails schoolId={schoolId} courseSectionId={courseSectionId} sx={{ flex: 1 }} />
          </MediaQuery>
        </Box>
      </Box>
    );
  }
);
