import { ScheduleCycleKind } from '@/viewmodels';
import { CycleDayEffect } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/cycle_day_effect_pb';
import { FormControl, FormGroup, FormHelperText, InputLabel, MenuItem, Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { ControlledSelect } from '../../../../../utils';

export interface SpecialDayEditCycleDaySectionProps {
  sx?: SxProps;
  className?: string;
  scheduleCycleKind: ScheduleCycleKind;
  possibleCycleDays: { value: number; title: string }[];
  cycleDayEffect: CycleDayEffect;
  setCycleDayEffect: (effect: CycleDayEffect) => void;
  cycleDay: number;
  setCycleDay: (value: number) => void;
}

export const SpecialDayEditCycleDaySection = observer(
  ({
    sx = [],
    className,
    scheduleCycleKind,
    possibleCycleDays,
    cycleDayEffect,
    setCycleDayEffect,
    cycleDay,
    setCycleDay
  }: SpecialDayEditCycleDaySectionProps) => {
    const strings = LocalizedStrings.scheduleCycle.edit.specialDays.edit;
    const cycleDayPickerLabel =
      scheduleCycleKind === 'cycle-day'
        ? strings.cycleDayPickerLabelCycleDay()
        : strings.cycleDayPickerLabelDayOfWeek();

    return (
      <FormGroup sx={sx} className={className}>
        <Stack direction="row" spacing={1}>
          <FormControl sx={{ flex: 1 }} fullWidth size="small">
            <InputLabel id="special-day-custom-cycle-day-label-id">{cycleDayPickerLabel}</InputLabel>
            <ControlledSelect
              value={cycleDayEffect === CycleDayEffect.UNSPECIFIED ? -1 : cycleDay}
              labelId="special-day-custom-cycle-day-label-id"
              label={cycleDayPickerLabel}
              onChange={(e) => setCycleDay(+e.target.value)}
              MenuProps={{ elevation: 2 }}
            >
              <MenuItem value={-1}>{strings.cycleDayPickerNoEffectOption()}</MenuItem>
              <MenuItem value={0}>
                {scheduleCycleKind === 'cycle-day'
                  ? strings.cycleDayPickerNoCycleDayOptionCycleDay()
                  : strings.cycleDayPickerNoCycleDayOptionDayOfWeek()}
              </MenuItem>
              {possibleCycleDays.map((cycleDay) => (
                <MenuItem key={`special-day-cycle-day-${cycleDay.value}`} value={cycleDay.value}>
                  {cycleDay.title}
                </MenuItem>
              ))}
            </ControlledSelect>
          </FormControl>

          {scheduleCycleKind !== 'week' && cycleDayEffect !== CycleDayEffect.UNSPECIFIED && (
            <FormControl sx={{ flex: 1 }} fullWidth size="small">
              <InputLabel id="special-day-cycle-day-effect-label-id">
                {scheduleCycleKind === 'cycle-day'
                  ? strings.cycleDayEffectPickerCycleDayLabel()
                  : strings.cycleDayEffectPickerMultipleWeeksLabel()}
              </InputLabel>
              <ControlledSelect
                value={cycleDayEffect}
                label="Rotating days effect"
                labelId="special-day-cycle-day-effect-label-id"
                MenuProps={{ elevation: 2 }}
                onChange={(e) => setCycleDayEffect(e.target.value as CycleDayEffect)}
              >
                <MenuItem value={CycleDayEffect.SKIP}>
                  {LocalizedStrings.models.cycleDayEffect.title[CycleDayEffect.SKIP]()}
                </MenuItem>

                {scheduleCycleKind === 'cycle-day' && (
                  <MenuItem value={CycleDayEffect.PUSH}>
                    {LocalizedStrings.models.cycleDayEffect.title[CycleDayEffect.PUSH]()}
                  </MenuItem>
                )}

                {cycleDay > 0 && (
                  <MenuItem value={CycleDayEffect.RESET}>
                    {LocalizedStrings.models.cycleDayEffect.title[CycleDayEffect.RESET]()}
                  </MenuItem>
                )}
              </ControlledSelect>
            </FormControl>
          )}
        </Stack>

        {scheduleCycleKind !== 'week' && cycleDayEffect !== CycleDayEffect.UNSPECIFIED && (
          <FormHelperText>{helperTextForCycleDayEffect(scheduleCycleKind, cycleDayEffect)}</FormHelperText>
        )}
      </FormGroup>
    );
  }
);

function helperTextForCycleDayEffect(scheduleKind: ScheduleCycleKind, effect: CycleDayEffect): string {
  const strings = LocalizedStrings.scheduleCycle.edit.specialDays.edit;

  switch (scheduleKind) {
    case 'cycle-day':
      return helperTextForCycleDayEffectForCycleDayScheduleKind(effect);
    case 'multiple-week':
      return helperTextForCycleDayEffectForMultipleWeeksScheduleKind(effect);
    default:
      return strings.cycleDayEffectHelperTextUnspecified();
  }
}

function helperTextForCycleDayEffectForCycleDayScheduleKind(effect: CycleDayEffect): string {
  const strings = LocalizedStrings.scheduleCycle.edit.specialDays.edit;

  switch (effect) {
    case CycleDayEffect.UNSPECIFIED:
      return strings.cycleDayEffectHelperTextUnspecified();
    case CycleDayEffect.PUSH:
      return strings.cycleDayEffectHelperTextPush();
    case CycleDayEffect.SKIP:
      return strings.cycleDayEffectHelperTextSkipCycleDay();
    case CycleDayEffect.RESET:
      return strings.cycleDayEffectHelperTextResetCycleDay();
  }
}

function helperTextForCycleDayEffectForMultipleWeeksScheduleKind(effect: CycleDayEffect): string {
  const strings = LocalizedStrings.scheduleCycle.edit.specialDays.edit;

  switch (effect) {
    case CycleDayEffect.SKIP:
      return strings.cycleDayEffectHelperTextSkipMultipleWeeks();
    case CycleDayEffect.RESET:
      return strings.cycleDayEffectHelperTextResetMultipleWeeks();
    case CycleDayEffect.UNSPECIFIED:
    case CycleDayEffect.PUSH:
      return strings.cycleDayEffectHelperTextUnspecified();
  }
}
