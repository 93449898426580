import { LoadingButton } from '@mui/lab';
import { Stack, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { ReactNode, useState } from 'react';
import { isSxArray } from '../../../../utils';

export interface UserDashboardCalendarDayStateIndicatorProps {
  sx?: SxProps;
  className?: string;
  renderIcon: (size: number, color: string) => ReactNode;
  title: string;
  subtitle?: string;
  action?: { title: string; onClick: () => Promise<void> };
}

export function UserDashboardCalendarDayStateIndicator({
  sx = [],
  className,
  title,
  renderIcon,
  subtitle,
  action
}: UserDashboardCalendarDayStateIndicatorProps) {
  const theme = useTheme();
  const [showActionLoading, setShowActionLoading] = useState(false);

  async function onActionClick() {
    if (action == null) {
      return;
    }

    setShowActionLoading(true);
    await action.onClick();
    setShowActionLoading(false);
  }

  return (
    <Stack
      className={className}
      spacing={2}
      sx={[
        {
          alignItems: 'flex-start'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <Stack
        direction="row"
        spacing={3}
        sx={{
          alignItems: 'center'
        }}
      >
        {renderIcon(34, theme.palette.text.secondary)}

        <Stack
          sx={{
            flex: 1
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontWeight: '500',
              color: theme.palette.text.secondary
            }}
          >
            {title}
          </Typography>

          {subtitle && (
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.text.secondary
              }}
            >
              {subtitle}
            </Typography>
          )}
        </Stack>
      </Stack>
      {action != null && (
        <LoadingButton
          variant="contained-grey"
          color="primary"
          sx={{ minWidth: 200 }}
          onClick={() => void onActionClick()}
          loading={showActionLoading}
        >
          {action.title}
        </LoadingButton>
      )}
    </Stack>
  );
}
