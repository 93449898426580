import { Box, Button, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router';
import LocalizedStrings from 'strings';
import { useNavigateAsync } from '../../hooks';
import { LoginDialog } from '../onboarding';

interface LocationState {
  startTrialNoUserShowLogin?: boolean;
}

export interface StartTrialNoUserProps {
  sx?: SxProps;
  className?: string;
}

export const StartTrialNoUser = observer(({ sx, className }: StartTrialNoUserProps) => {
  const location = useLocation();
  const state = location.state as LocationState | undefined;
  const navigate = useNavigateAsync();
  const strings = LocalizedStrings.startTrial.noUser;

  function showLogin() {
    const newState: LocationState = { ...state, startTrialNoUserShowLogin: true };
    void navigate(location, { state: newState });
  }

  return (
    <Box sx={sx} className={className}>
      <Stack spacing={3}>
        <Stack alignItems="center">
          <Typography variant="h6">{strings.title()}</Typography>
          <Typography color="textSecondary">{strings.subtitle()}</Typography>
        </Stack>

        <Button onClick={showLogin} variant="contained">
          {strings.login()}
        </Button>
      </Stack>

      {state?.startTrialNoUserShowLogin === true && (
        <LoginDialog isOpen={true} kind="log-in" referrer={location.pathname} />
      )}
    </Box>
  );
});
