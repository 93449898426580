import { useServices } from '@/hooks';
import { StudentConnectionState } from '@/models';
import { ExLocalizedStrings } from '@/resources/strings/ExLocalizedStrings';
import {
  getDailyActivityBehaviorTooltip,
  getInboxBehaviorLevel,
  getInboxBehaviorTooltip,
  getStudentEmotionalStatusLevel,
  getStudentEmotionalStatusTooltip,
  StudentsPlannerSummaryTableRowViewModel
} from '@/viewmodels';
import ConnectedIcon from '@mui/icons-material/PersonRounded';
import ConnectedNoAccessIcon from '@mui/icons-material/VisibilityOffRounded';
import { Box, ListItem, ListItemButton, ListItemText, Tooltip } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { ReactElement } from 'react';
import { Link } from 'react-router';
import { isSxArray } from '../../../../utils';
import { StudentBehaviors } from '../../student';

export interface StudentListItemProps {
  viewModel: StudentsPlannerSummaryTableRowViewModel;
  sx?: SxProps;
  className?: string;

  /**
   * Indicate if the item should be a link to another page.
   */
  targetLocation?: string;
  showBehaviors?: boolean;
}

export const StudentListItem = observer(
  ({ sx = [], className, viewModel, targetLocation, showBehaviors = true }: StudentListItemProps) => {
    const { localization, dateService } = useServices();
    const connectionState = viewModel.connectionState;
    const resolvedShowLastConnectionIndicator = showBehaviors && connectionState === 'connected';
    const inboxBehaviorLevel = getInboxBehaviorLevel(viewModel.inboxItemsCount);
    const lastEmotionalStatusLevel = getStudentEmotionalStatusLevel(viewModel.emotionalStateRating);

    const content = () => (
      <>
        <Tooltip title={tooltipForConnectionState(connectionState, localization.localizedStrings)}>
          {iconForConnectionState(connectionState)}
        </Tooltip>

        <ListItemText sx={{ mr: resolvedShowLastConnectionIndicator ? 1 : undefined }} primary={viewModel.name} />

        {resolvedShowLastConnectionIndicator && (
          <StudentBehaviors
            isCompact
            behaviors={{
              dailyActivity: {
                kind: 'daily-activity',
                level: viewModel.lastConnectionIndicatorLevel,
                tooltip: getDailyActivityBehaviorTooltip(viewModel.lastConnection, localization, dateService)
              },
              inbox: {
                kind: 'inbox',
                level: inboxBehaviorLevel,
                tooltip: getInboxBehaviorTooltip(inboxBehaviorLevel, localization)
              },
              lastEmotionalStatus: {
                kind: 'last-emotional-status',
                level: lastEmotionalStatusLevel,
                tooltip: getStudentEmotionalStatusTooltip(viewModel.emotionalStateRating, localization)
              }
            }}
          />
        )}
      </>
    );

    return (
      <Box
        className={className}
        sx={[
          {
            display: 'flex'
          },
          ...(isSxArray(sx) ? sx : [sx])
        ]}
      >
        {targetLocation != null ? (
          <ListItemButton component={Link} to={targetLocation}>
            {content()}
          </ListItemButton>
        ) : (
          <ListItem>{content()}</ListItem>
        )}
      </Box>
    );
  }
);

function tooltipForConnectionState(
  connectionState: StudentConnectionState,
  localizedStrings: ExLocalizedStrings
): string {
  const strings = localizedStrings.schoolCourseSection;

  switch (connectionState) {
    case 'connected':
      return strings.studentConnectionStateConnectedAndHaveAccessTooltip;
    case 'connected-no-access':
      return strings.studentConnectionStateConnectedNoAccessTooltip;
    case 'not-connected':
      return strings.studentConnectionStateNotConnectedTooltip;
  }
}

function iconForConnectionState(connectionState: StudentConnectionState): ReactElement {
  const iconStyle: SxProps = { width: 28, height: 28, mr: 2 };

  switch (connectionState) {
    case 'connected':
      return <ConnectedIcon sx={iconStyle} color="primary" />;

    case 'connected-no-access':
      return <ConnectedNoAccessIcon sx={iconStyle} color="primary" />;

    case 'not-connected':
      return <ConnectedIcon sx={iconStyle} color="disabled" />;
  }
}
