import { OnboardingCreateSchoolViewModel } from '@/viewmodels';
import { ArrowBackRounded } from '@mui/icons-material';
import { Box, Button, Stack, SxProps, TextField, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { OnboardingStepActions } from './OnboardingStepActions';

export interface OnboardingCreateSchoolProps {
  sx?: SxProps;
  className?: string;
  viewModel: OnboardingCreateSchoolViewModel;
}

export const OnboardingCreateSchool = observer(({ sx, className, viewModel }: OnboardingCreateSchoolProps) => {
  const strings = LocalizedStrings.onboarding.createSchool;

  return (
    <Box sx={sx} className={className} width="100%" display="flex" flexDirection="column">
      <Stack spacing={3}>
        <Stack spacing={2}>
          <Typography variant="h6">{strings.title()}</Typography>

          <TextField
            label={strings.schoolNameTextFieldLabel()}
            value={viewModel.schoolName}
            onChange={(e) => (viewModel.schoolName = e.currentTarget.value)}
            disabled={viewModel.isSubmitting}
          />
        </Stack>

        <OnboardingStepActions
          viewModel={viewModel}
          nextButtonLabel={strings.submitButtonLabel()}
          additionalActions={() => (
            <Button
              startIcon={<ArrowBackRounded />}
              onClick={() => viewModel.goBack()}
              disabled={viewModel.isSubmitting}
            >
              {strings.backButtonLabel()}
            </Button>
          )}
        />
      </Stack>
    </Box>
  );
});
