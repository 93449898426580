import { useActivePlannerId, useServices, useViewModel } from '@/hooks';
import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { Navigate, Outlet, useParams } from 'react-router';
import { SchoolCourseSectionCompactCurrentStudent } from './SchoolCourseSectionCompactCurrentStudent';
import { SchoolCourseSectionDetailsStudentGrid } from './SchoolCourseSectionDetailsStudentGrid';

export interface SchoolCourseSectionDetailsColumnsProps {
  sx?: SxProps;
  className?: string;
}

export const SchoolCourseSectionDetailsColumns = observer(
  ({ sx = [], className }: SchoolCourseSectionDetailsColumnsProps) => {
    const { route } = useServices();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));

    const plannerId = useActivePlannerId();
    const params = useParams();
    const schoolId = params.schoolId ?? '';
    const courseSectionId = params.courseId ?? '';
    const studentId = params.studentId ?? '';

    const viewModel = useViewModel(
      (viewModels) => viewModels.createCourseSectionDetailsViewModel(courseSectionId, plannerId, schoolId),
      [courseSectionId]
    );

    if (
      viewModel.studentsPlannerSummary.allStudents.length > 0 &&
      studentId.length === 0 &&
      viewModel.studentsPlannerSummary.searchText.length === 0
    ) {
      const firstStudentId = viewModel.studentsPlannerSummary.allStudents[0].id;
      return (
        <Navigate
          to={route.resolveSchoolStudentPlannerLocation(plannerId, schoolId, courseSectionId, firstStudentId)}
          replace
        />
      );
    }

    const showStudentDetails =
      viewModel.studentsPlannerSummary.students.length > 0 &&
      (studentId.length === 0 || viewModel.studentsPlannerSummary.students.some((s) => s.id === studentId));

    return (
      <Stack
        className={className}
        spacing={{ xs: 1, sm: 2 }}
        sx={{
          pb: !showStudentDetails || studentId.length === 0 ? 2 : undefined,
          ...sx,
          overflow: 'visible'
        }}
      >
        {isSmallScreen ? (
          <SchoolCourseSectionCompactCurrentStudent sx={{ mx: 2 }} viewModel={viewModel.studentsPlannerSummary} />
        ) : (
          <SchoolCourseSectionDetailsStudentGrid viewModel={viewModel.studentsPlannerSummary} />
        )}
        {showStudentDetails && (
          <Box
            sx={{
              flex: 1
            }}
          >
            <Outlet />
          </Box>
        )}
      </Stack>
    );
  }
);
