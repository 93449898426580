import { useViewModel } from '@/hooks';
import { AllDayOfWeek, dateToPBDate, dayToDate, formatTermDates } from '@/models';
import { ScheduleCycleActivityScheduleAtKind, ScheduleCycleActivitySchedulesCoursesFilter } from '@/viewmodels';
import { Activity } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/activity_pb';
import {
  Box,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  SelectChangeEvent,
  Stack,
  TextField
} from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import LocalizedStrings from 'strings';
import { isSxArray } from '../../../../../utils';
import {
  CalendarDatePicker,
  ControlledSelect,
  FormPopoverActions,
  FormPopoverHeader,
  Subheader,
  TimePicker
} from '../../../../utils';
import { useScheduleCycleUserDashboard } from '../../UseScheduleCycleUserDashboardHook';

const ScheduleTagNoneValue = 'no-schedule-tag-select-value';

export interface ScheduleCycleActivityScheduleEditProps {
  sx?: SxProps;
  className?: string;
  activityScheduleId: string | undefined;
  activity: Activity;
  dismiss: () => void;
  filters: ScheduleCycleActivitySchedulesCoursesFilter | undefined;
}

export const ScheduleCycleActivityScheduleEdit = observer(
  ({ sx = [], className, activityScheduleId, activity, dismiss, filters }: ScheduleCycleActivityScheduleEditProps) => {
    const { plannerId, dashboard, scheduleCycleId } = useScheduleCycleUserDashboard();
    const strings = LocalizedStrings.scheduleCycle.edit.activitySchedules.edit;

    const viewModel = useViewModel(
      (viewModels) =>
        viewModels.createScheduleCycleActivityScheduleEditViewModel(
          activityScheduleId,
          activity,
          scheduleCycleId,
          dashboard,
          plannerId,
          filters
        ),
      [activityScheduleId, activity.activityId, activity.sourceName, scheduleCycleId, filters?.value]
    );

    async function save() {
      await viewModel.save();
      dismiss();
    }

    const whenSelectValue = useMemo(() => {
      if (viewModel.when.case === 'cycleDay') {
        return viewModel.when.value;
      } else if (viewModel.when.case === 'dayOfWeek') {
        return -viewModel.when.value;
      }
      return 0;
    }, [viewModel.when]);

    function onWhenSelectChange(event: SelectChangeEvent<number>) {
      const numberValue = +event.target.value;

      if (numberValue > 0) {
        viewModel.when = { value: +event.target.value, case: 'cycleDay' };
      } else if (numberValue < 0) {
        viewModel.when = { case: 'dayOfWeek', value: -numberValue };
      } else {
        viewModel.when = { case: 'day' };
      }
    }

    return (
      <Stack
        className={className}
        sx={[
          {
            overflow: 'hidden'
          },
          ...(isSxArray(sx) ? sx : [sx])
        ]}
      >
        <FormPopoverHeader title={activityScheduleId != null ? strings.editTitle() : strings.addTitle()} />
        <Box
          sx={{
            flex: 1,
            overflow: 'hidden'
          }}
        >
          <Stack
            spacing={1}
            sx={{
              height: '100%',
              p: 2,
              pt: 1,
              overflow: 'auto'
            }}
          >
            <Stack>
              <Subheader>{strings.dayPickerSectionTitle()}</Subheader>

              <Stack spacing={2}>
                <FormControl size="small">
                  <InputLabel id="schedule-cycle-activity-schedule-add-when-case-select-label">
                    {strings.dayPickerLabel()}
                  </InputLabel>
                  <ControlledSelect
                    labelId="schedule-cycle-activity-schedule-add-when-case-select-label"
                    label={strings.dayPickerLabel()}
                    value={whenSelectValue}
                    onChange={onWhenSelectChange}
                  >
                    <MenuItem disabled>
                      {viewModel.isSingleWeekSchedule
                        ? strings.dayPickerRotatingDaySectionWeeklyScheduleTitle()
                        : strings.dayPickerRotatingDaySectionTitle()}
                    </MenuItem>
                    {viewModel.possibleCycleDays.map((cycleDay) => (
                      <MenuItem key={cycleDay.value} value={cycleDay.value}>
                        {cycleDay.title}
                      </MenuItem>
                    ))}

                    <Divider />

                    <MenuItem disabled>
                      {viewModel.isSingleWeekSchedule
                        ? strings.dayPickerDayOfWeekSectionWeeklyScheduleTitle()
                        : strings.dayPickerDayOfWeekSectionTitle()}
                    </MenuItem>
                    {AllDayOfWeek.map((dow) => (
                      <MenuItem key={dow} value={dow * -1}>
                        {LocalizedStrings.dateTime.dayOfWeekTitle[dow]()}
                      </MenuItem>
                    ))}

                    <Divider />

                    <MenuItem value={0}>{strings.dayPickerSpecificDateOption()}</MenuItem>
                  </ControlledSelect>
                </FormControl>

                {viewModel.when.case === 'day' && (
                  <CalendarDatePicker
                    value={dayToDate(viewModel.specificDay)}
                    label={strings.datePickerLabel()}
                    format="PPP"
                    onChange={(v) => (viewModel.specificDay = dateToPBDate(v!))}
                    slotProps={{
                      desktopPaper: { elevation: 2 },
                      mobilePaper: { elevation: 2 }
                    }}
                    showDaysOutsideCurrentMonth
                  />
                )}
              </Stack>
            </Stack>

            <Stack>
              <Subheader>{strings.timeSectionTitle()}</Subheader>

              <Stack spacing={2}>
                <ControlledSelect
                  value={viewModel.at}
                  onChange={(event) => (viewModel.at = event.target.value as ScheduleCycleActivityScheduleAtKind)}
                  MenuProps={{ elevation: 2 }}
                  size="small"
                >
                  <MenuItem value="periodLabel">{strings.periodPickerPeriodOption()}</MenuItem>
                  <MenuItem value="specificTimes">{strings.periodPickerTimesOption()}</MenuItem>
                </ControlledSelect>

                {viewModel.at === 'periodLabel' && (
                  <>
                    <FormControl size="small">
                      <InputLabel id="schedule-cycle-activity-schedule-add-period-label-select-label">
                        {strings.periodLabelPickerLabel()}
                      </InputLabel>
                      <ControlledSelect
                        labelId="schedule-cycle-activity-schedule-add-period-label-select-label"
                        label={strings.periodLabelPickerLabel()}
                        value={viewModel.periodLabel}
                        onChange={(event) => (viewModel.periodLabel = event.target.value)}
                        MenuProps={{ elevation: 2 }}
                      >
                        {viewModel.possiblePeriodLabels.map((label) => (
                          <MenuItem key={label} value={label}>
                            {label}
                          </MenuItem>
                        ))}

                        {viewModel.possiblePeriodLabels.length === 0 && (
                          <MenuItem disabled>{strings.periodLabelPickerEmptyOption()}</MenuItem>
                        )}
                      </ControlledSelect>
                    </FormControl>

                    {viewModel.supportsScheduleTags && (
                      <FormControl size="small">
                        <InputLabel id="schedule-cycle-activity-schedule-add-period-label-select-schedule-tag">
                          {strings.scheduleTagPickerLabel()}
                        </InputLabel>
                        <ControlledSelect
                          labelId="schedule-cycle-activity-schedule-add-period-label-select-schedule-tag"
                          label={strings.scheduleTagPickerLabel()}
                          value={viewModel.scheduleTag || ScheduleTagNoneValue}
                          onChange={(event) =>
                            (viewModel.scheduleTag =
                              event.target.value === ScheduleTagNoneValue ? '' : event.target.value)
                          }
                          MenuProps={{ elevation: 2 }}
                        >
                          {viewModel.possibleScheduleTags.map((label) => (
                            <MenuItem key={label} value={label}>
                              {label}
                            </MenuItem>
                          ))}

                          <MenuItem value={ScheduleTagNoneValue}>{strings.scheduleTagNoneLabel()}</MenuItem>
                        </ControlledSelect>
                      </FormControl>
                    )}
                  </>
                )}

                {viewModel.at === 'specificTimes' && (
                  <>
                    <FormControl>
                      <Stack direction="row" spacing={1}>
                        <TimePicker
                          sx={{ flex: 1 }}
                          label={strings.startTimePickerLabel()}
                          onChange={(value) => (viewModel.customStartTime = value!)}
                          value={viewModel.customStartTime}
                          error={!viewModel.customTimesAreValid}
                          timeSteps={{ minutes: 1 }}
                        />

                        <TimePicker
                          sx={{ flex: 1 }}
                          label={strings.endTimePickerLabel()}
                          onChange={(value) => (viewModel.customEndTime = value!)}
                          value={viewModel.customEndTime}
                          error={!viewModel.customTimesAreValid}
                          timeSteps={{ minutes: 1 }}
                        />
                      </Stack>

                      <FormHelperText error>
                        {!viewModel.customTimesAreValid ? strings.invalidTimesWarning() : ''}
                      </FormHelperText>
                    </FormControl>

                    <TextField
                      value={viewModel.customPeriodLabel}
                      label={strings.displayedPeriodLabelInputLabel()}
                      size="small"
                      onChange={(e) => (viewModel.customPeriodLabel = e.currentTarget.value)}
                    />
                  </>
                )}
              </Stack>
            </Stack>

            <Stack>
              <Subheader>{strings.moreInformationSectionTitle()}</Subheader>
              <Stack spacing={2}>
                <FormControl size="small">
                  <InputLabel id="schedule-cycle-activity-schedule-add-term-select-label">
                    {strings.termPickerLabel()}
                  </InputLabel>
                  <ControlledSelect
                    labelId="schedule-cycle-activity-schedule-add-term-select-label"
                    label={strings.termPickerLabel()}
                    value={viewModel.termId || '~'}
                    onChange={(event) => (viewModel.termId = event.target.value !== '~' ? event.target.value : '')}
                  >
                    <MenuItem value="~">{strings.termPickerNoneOption()}</MenuItem>
                    {viewModel.possibleTerms.map((term) => (
                      <MenuItem key={term.id} value={term.id}>
                        <ListItemText primary={term.name} secondary={formatTermDates(term.startDay, term.endDay)} />
                      </MenuItem>
                    ))}
                  </ControlledSelect>
                </FormControl>
                <TextField
                  value={viewModel.roomName}
                  label={strings.roomInputLabel()}
                  onChange={(e) => (viewModel.roomName = e.currentTarget.value)}
                  size="small"
                />
              </Stack>
            </Stack>
          </Stack>
        </Box>
        <FormPopoverActions
          onCancel={() => {
            dismiss();
            return Promise.resolve();
          }}
          onSubmit={save}
          canSubmit={viewModel.canSave}
        />
      </Stack>
    );
  }
);
