import { useBackButtonListener, useServices } from '@/hooks';
import { isSxArray, Locale } from '@/utils';
import { css } from '@emotion/css';
import { DarkModeRounded, LightModeRounded, TranslateRounded } from '@mui/icons-material';
import {
  Box,
  BoxProps,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { MouseEvent, ReactNode, useState } from 'react';
import { Link } from 'react-router';
import { Logo, MediaQuery } from './utils';

export interface SplashProps extends BoxProps {
  className?: string;
  children: ReactNode;
  showOptions?: boolean;
  /**
   * The content width. Optional. Default is `300`.
   */
  contentWidth?: number | string;

  logoIsLink?: boolean;

  /**
   * The header element. Optional.
   */
  header?: () => ReactNode;
}

export const Splash = observer(
  ({
    sx = [],
    className,
    children,
    header,
    contentWidth = 300,
    logoIsLink = true,
    showOptions = true,
    ...props
  }: SplashProps) => {
    const { environment, theme, userStore, localization } = useServices();
    const muiTheme = useTheme();

    const linkLogoStyle = css({
      position: 'absolute',
      left: muiTheme.spacing(8),
      top: muiTheme.spacing(4),

      [muiTheme.breakpoints.down('sm')]: {
        top: 'unset',
        left: 'unset',
        bottom: `calc(100% + ${muiTheme.spacing(2)})`
      }
    });

    const logoStyle = css([
      {
        height: '100%',
        width: 200,

        [muiTheme.breakpoints.down('sm')]: {
          width: 150
        }
      }
    ]);

    const [localeAnchorEl, setLocaleAnchorEl] = useState<null | HTMLElement>(null);

    function handleLocaleButtonClick(event: MouseEvent<HTMLButtonElement>) {
      setLocaleAnchorEl(event.currentTarget);
    }

    function handleLocaleMenuClose(locale: Locale | undefined) {
      if (locale) {
        void userStore.updateCurrentUserLocale(locale);
      }

      setLocaleAnchorEl(null);
    }

    useBackButtonListener((e) => {
      if (localeAnchorEl != null) {
        e.stopPropagation();
        setLocaleAnchorEl(null);
      }
    });

    return (
      <Box
        {...props}
        className={className}
        sx={[
          {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            maxHeight: '100%',
            overflow: 'hidden'
          },
          ...(isSxArray(sx) ? sx : [sx])
        ]}
      >
        <MediaQuery mediaQuery={(th) => th.breakpoints.up('sm')}>
          <LinkLogo linkClassName={linkLogoStyle} logoClassName={logoStyle} isLink={logoIsLink} />
        </MediaQuery>
        {showOptions && (
          <MediaQuery mediaQuery={(th) => th.breakpoints.up('sm')}>
            <Stack
              direction="row"
              spacing={2}
              sx={{
                position: 'absolute',
                right: muiTheme.spacing(8),
                top: muiTheme.spacing(4),
                zIndex: 1_000
              }}
            >
              <IconButton onClick={handleLocaleButtonClick}>
                <TranslateRounded color={localeAnchorEl != null ? 'primary' : 'inherit'} />
              </IconButton>

              {theme.variant === 'light' && (
                <Tooltip title={localization.localizedStrings.utils.splashSwitchToDarkModeTooltip}>
                  <IconButton onClick={() => theme.setVariant('dark')}>
                    <DarkModeRounded />
                  </IconButton>
                </Tooltip>
              )}

              {theme.variant === 'dark' && (
                <Tooltip title={localization.localizedStrings.utils.splashSwitchToLightModeTooltip}>
                  <IconButton onClick={() => theme.setVariant('light')}>
                    <LightModeRounded />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          </MediaQuery>
        )}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1,
            position: 'relative',
            width: '100%',
            height: '100%'
          }}
        >
          <MediaQuery mediaQuery={(th) => th.breakpoints.down('sm')}>
            <LinkLogo linkClassName={linkLogoStyle} logoClassName={logoStyle} isLink={logoIsLink} />
          </MediaQuery>

          <Box
            sx={{
              height: '100%',
              width: '100%',
              position: 'absolute',
              overflowY: 'auto',
              overflowX: 'none'
            }}
          >
            <Box
              sx={{
                display: 'table',
                height: '100%',
                width: '100%'
              }}
            >
              <Box
                sx={{
                  display: 'table-cell',
                  verticalAlign: 'middle'
                }}
              >
                <Box
                  sx={{
                    p: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: 125,
                    width: '100%',
                    alignItems: 'center'
                  }}
                >
                  <Stack
                    direction="column"
                    sx={{
                      width: contentWidth
                    }}
                  >
                    {header?.()}
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      {children}
                    </Box>
                  </Stack>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            zIndex: 0,
            position: 'absolute',
            bottom: muiTheme.spacing(4),
            left: muiTheme.spacing(4)
          }}
        >
          <Typography
            variant="caption"
            sx={{
              color: (theme) => theme.palette.text.secondary
            }}
          >
            <code style={{ fontFamily: 'inherit' }}>
              {environment?.isLoaded
                ? `${environment.version}.${environment.buildNumber}-${environment.environment}`
                : ''}
            </code>
          </Typography>
          <Typography
            variant="caption"
            sx={{
              color: (theme) => theme.palette.text.secondary
            }}
          >
            © 2020-2025 Intuitic Inc.
          </Typography>
        </Box>
        {showOptions && (
          <Menu
            open={localeAnchorEl != null}
            anchorEl={localeAnchorEl}
            onClose={() => handleLocaleMenuClose(undefined)}
            slotProps={{ paper: { sx: { width: 200 } } }}
          >
            <MenuItem onClick={() => handleLocaleMenuClose('en')}>
              <ListItemIcon sx={{ color: muiTheme.palette.text.primary }}>🇺🇸</ListItemIcon>
              <ListItemText
                slotProps={{
                  primary: {
                    color: localization.currentLocale === 'en' ? muiTheme.palette.primary.main : 'inherit'
                  }
                }}
              >
                {/* Not localized */}
                English
              </ListItemText>
            </MenuItem>

            <MenuItem onClick={() => handleLocaleMenuClose('fr')}>
              <ListItemIcon sx={{ color: muiTheme.palette.text.primary }}>🇫🇷</ListItemIcon>
              <ListItemText
                slotProps={{
                  primary: {
                    color: localization.currentLocale === 'fr' ? muiTheme.palette.primary.main : 'inherit'
                  }
                }}
              >
                {/* Not localized */}
                Français
              </ListItemText>
            </MenuItem>
          </Menu>
        )}
      </Box>
    );
  }
);

function LinkLogo({
  linkClassName,
  logoClassName,
  isLink
}: {
  linkClassName: string;
  logoClassName: string;
  isLink: boolean;
}) {
  return (
    <div className={linkClassName}>
      {isLink && (
        <Link to="/">
          <Logo className={logoClassName} />
        </Link>
      )}

      {!isLink && <Logo className={logoClassName} />}
    </div>
  );
}
