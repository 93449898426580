import { ContentPasteRounded, PersonRounded, SendRounded, StickyNote2Rounded } from '@mui/icons-material';
import { Divider, ListItemIcon, ListItemText, Menu, MenuItem, MenuProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { useBackButtonListener, useServices } from '../../hooks';
import { AllPlannerItemsPasteboardContentKind } from '../../services';

export interface CreateWorkOptionsMenuProps extends MenuProps {
  canPublishWork: boolean;
  onCreatePersonalWorkOptionSelect: () => void;
  onCreateNoteOptionSelect: () => void;
  onPublishWorkOptionSelect: () => void;
  onPaste: () => void;
}

export const CreateWorkOptionsMenu = observer(
  ({
    canPublishWork,
    onCreatePersonalWorkOptionSelect,
    onCreateNoteOptionSelect,
    onPublishWorkOptionSelect,
    onPaste,
    ...props
  }: CreateWorkOptionsMenuProps) => {
    const { pasteboard } = useServices();
    const strings = LocalizedStrings.work.createOptionsMenu;

    useBackButtonListener((e) => {
      if (props.open) {
        e.stopPropagation();
        props.onClose?.({}, 'escapeKeyDown');
      }
    });

    return (
      <Menu {...props} slotProps={{ paper: { ...props.slotProps?.paper, sx: { minWidth: 250 } } }}>
        <MenuItem onClick={() => onCreatePersonalWorkOptionSelect()}>
          <ListItemIcon>
            <PersonRounded />
          </ListItemIcon>
          <ListItemText>{strings.personalTaskOption()}</ListItemText>
        </MenuItem>

        <MenuItem onClick={() => onCreateNoteOptionSelect()}>
          <ListItemIcon>
            <StickyNote2Rounded />
          </ListItemIcon>
          <ListItemText>{strings.noteOption()}</ListItemText>
        </MenuItem>

        {canPublishWork && (
          <MenuItem onClick={() => onPublishWorkOptionSelect()}>
            <ListItemIcon>
              <SendRounded />
            </ListItemIcon>
            <ListItemText>{strings.publishedTaskOption()}</ListItemText>
          </MenuItem>
        )}

        {pasteboard.currentContentForKinds(AllPlannerItemsPasteboardContentKind) != null && [
          <Divider key="divider" />,

          <MenuItem key="paste-item-option" onClick={() => onPaste()}>
            <ListItemIcon>
              <ContentPasteRounded />
            </ListItemIcon>
            <ListItemText>{strings.pasteOption()}</ListItemText>
          </MenuItem>
        ]}
      </Menu>
    );
  }
);
