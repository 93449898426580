import i18n from 'translate';
import { OnboardingTeacherOptionsStrings } from './OnboardingTeacherOptionsStrings';

export const LinkPlannerToSchoolAutoStrings = {
  errorMessage: () => i18n.t('onboarding.link.auto.errorMessage'),
  retryButtonTitle: () => i18n.t('onboarding.link.auto.retryButtonTitle')
};

export const LinkPlannerToSchoolFormStrings = {
  errorMessage: () => i18n.t('onboarding.link.form.errorMessage'),
  plannerSelectLabel: () => i18n.t('onboarding.link.form.plannerSelectLabel'),
  schoolSelectLabel: () => i18n.t('onboarding.link.form.schoolSelectLabel'),
  signOutButtonTitle: () => i18n.t('onboarding.link.form.signOutButtonTitle'),
  submitButtonTitle: () => i18n.t('onboarding.link.form.submitButtonTitle'),
  subtitle: () => i18n.t('onboarding.link.form.subtitle'),
  title: () => i18n.t('onboarding.link.form.title')
};

export const LinkPlannerToSchoolStrings = {
  auto: LinkPlannerToSchoolAutoStrings,
  form: LinkPlannerToSchoolFormStrings
};

export const OnboardingCreateSchoolStrings = {
  backButtonLabel: () => i18n.t('onboarding.createSchool.backButtonLabel'),
  schoolNameTextFieldLabel: () => i18n.t('onboarding.createSchool.schoolNameTextFieldLabel'),
  submitButtonLabel: () => i18n.t('onboarding.createSchool.submitButtonLabel'),
  title: () => i18n.t('onboarding.createSchool.title')
};

export const OnboardingClassroomStrings = {
  connectErrorMessage: () => i18n.t('onboarding.classroom.connectErrorMessage'),
  messagePlanner: () => i18n.t('onboarding.classroom.messagePlanner'),
  messageSchool: () => i18n.t('onboarding.classroom.messageSchool'),
  retryFetchSyncStatusButtonLabel: () => i18n.t('onboarding.classroom.retryFetchSyncStatusButtonLabel'),
  skipButtonLabel: () => i18n.t('onboarding.classroom.skipButtonLabel'),
  skipMessage: () => i18n.t('onboarding.classroom.skipMessage'),
  subtitle: () => i18n.t('onboarding.classroom.subtitle'),
  syncStatusFetchErrorMessage: () => i18n.t('onboarding.classroom.syncStatusFetchErrorMessage'),
  title: () => i18n.t('onboarding.classroom.title')
};

export const OnboardingStudentNoSchoolFoundStrings = {
  backButtonTitle: () => i18n.t('onboarding.studentNoSchoolFound.backButtonTitle'),
  retryButtonTitle: () => i18n.t('onboarding.studentNoSchoolFound.retryButtonTitle'),
  subtitle: (email: string) => i18n.t('onboarding.studentNoSchoolFound.subtitle', { email }),
  title: () => i18n.t('onboarding.studentNoSchoolFound.title')
};

export const OnboardingParentOptionsStrings = {
  createPlannerError: () => i18n.t('onboarding.parentOptions.createPlannerError'),
  createPlannerSubtitle: () => i18n.t('onboarding.parentOptions.createPlannerSubtitle'),
  createPlannerTitle: () => i18n.t('onboarding.parentOptions.createPlannerTitle'),
  creatingPlanner: () => i18n.t('onboarding.parentOptions.creatingPlanner'),
  signOut: () => i18n.t('onboarding.parentOptions.signOut'),
  useCodeSubtitle: () => i18n.t('onboarding.parentOptions.useCodeSubtitle'),
  useCodeTitle: () => i18n.t('onboarding.parentOptions.useCodeTitle')
};

export const OnboardingStudentOptionsStrings = {
  createPlannerError: () => i18n.t('onboarding.studentOptions.createPlannerError'),
  creatingPlanner: () => i18n.t('onboarding.studentOptions.creatingPlanner'),
  personalSubtitle: () => i18n.t('onboarding.studentOptions.personalSubtitle'),
  personalTitle: () => i18n.t('onboarding.studentOptions.personalTitle'),
  schoolSubtitle: () => i18n.t('onboarding.studentOptions.schoolSubtitle'),
  schoolTitle: () => i18n.t('onboarding.studentOptions.schoolTitle'),
  signOut: () => i18n.t('onboarding.studentOptions.signOut'),
  withParentsSubtitle: () => i18n.t('onboarding.studentOptions.withParentsSubtitle'),
  withParentsTitle: () => i18n.t('onboarding.studentOptions.withParentsTitle')
};

export const OnboardingSubscriptionsStrings = {
  backButtonTitle: () => i18n.t('onboarding.subscriptions.backButtonTitle'),
  cadPriceOptionTitle: () => i18n.t('onboarding.subscriptions.cadPriceOptionTitle'),
  legalText: () => i18n.t('onboarding.subscriptions.legalText'),
  title: () => i18n.t('onboarding.subscriptions.title'),
  usdPriceOptionTitle: () => i18n.t('onboarding.subscriptions.usdPriceOptionTitle')
};

export const OnboardingTeacherUseCode = {
  backButtonTitle: () => i18n.t('onboarding.teacherUseCode.backButtonTitle'),
  submitButtonTitle: () => i18n.t('onboarding.teacherUseCode.submitButtonTitle'),
  textFieldLabel: () => i18n.t('onboarding.teacherUseCode.textFieldLabel'),
  title: () => i18n.t('onboarding.teacherUseCode.title')
};

export const OnboardingStrings = {
  classroom: OnboardingClassroomStrings,
  createSchool: OnboardingCreateSchoolStrings,
  link: LinkPlannerToSchoolStrings,
  parentOptions: OnboardingParentOptionsStrings,
  studentNoSchoolFound: OnboardingStudentNoSchoolFoundStrings,
  studentOptions: OnboardingStudentOptionsStrings,
  subscriptions: OnboardingSubscriptionsStrings,
  teacherOptions: OnboardingTeacherOptionsStrings,
  teacherUseCode: OnboardingTeacherUseCode
};
