import { useActivePlannerId, useNavigateAsync, useServices, useViewModel } from '@/hooks';
import { AddRounded, VisibilityOffRounded, VisibilityRounded } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useLocation } from 'react-router';
import LocalizedStrings from 'strings';
import { UserDashboardInfo, plannerSchools } from '../../../models';
import {
  ScheduleCycleCreateDialog,
  ScheduleCycleCreationUserDashboardPicker,
  ScheduleCycleList,
  SettingsCard,
  SettingsCardAction
} from '../../shared';

interface LocationState {
  plannerSchedulesListShowCreate?: UserDashboardInfo;
}

export interface PlannerScheduleCycleListProps {
  sx?: SxProps;
  className?: string;
}

export const PlannerScheduleCycleList = observer(({ sx = [], className }: PlannerScheduleCycleListProps) => {
  const { userStore } = useServices();
  const plannerId = useActivePlannerId();
  const strings = LocalizedStrings.scheduleCycle.list;
  const [showScheduleUserDashboardPicker, setShowScheduleUserDashboardPicker] = useState(false);

  const location = useLocation();
  const state = (location.state ?? {}) as LocationState;
  const navigate = useNavigateAsync();

  const viewModel = useViewModel(
    (viewModels) => viewModels.createSchedulesListViewModel(plannerId, 'planner', false),
    [plannerId]
  );

  function createNewSchedule() {
    const planner = userStore.getPlannerForId(plannerId);
    const schools =
      planner != null ? plannerSchools(planner, userStore.schools).filter((s) => !s.school!.isArchived) : [];

    if (schools.length > 0) {
      setShowScheduleUserDashboardPicker(true);
    } else {
      createScheduleCycleForUserDashboard({ kind: 'planner', id: plannerId });
    }

    return Promise.resolve();
  }

  function toggleShowPastSchedules() {
    viewModel.showPastSchedules = !viewModel.showPastSchedules;
    return Promise.resolve();
  }

  const createScheduleAction: SettingsCardAction = {
    action: createNewSchedule,
    icon: <AddRounded />
  };

  const togglePastSchedulesAction: SettingsCardAction = {
    action: toggleShowPastSchedules,
    icon: viewModel.showPastSchedules ? <VisibilityOffRounded /> : <VisibilityRounded />,
    tooltip: viewModel.showPastSchedules ? strings.hidePastSchedulesTooltip() : strings.showPastSchedulesTooltip()
  };

  function onCreateScheduleCycleUserDashboardSelection(dashboard: UserDashboardInfo | undefined) {
    setShowScheduleUserDashboardPicker(false);

    if (dashboard != null) {
      createScheduleCycleForUserDashboard(dashboard);
    }
  }

  function createScheduleCycleForUserDashboard(dashboard: UserDashboardInfo) {
    const newState: LocationState = { ...state, plannerSchedulesListShowCreate: dashboard };
    void navigate(location, { state: newState });
  }

  return (
    <Stack sx={{ ...sx, width: '100%' }} className={className}>
      <SettingsCard
        title={strings.titlePlanner()}
        supportsFullHeight
        actions={
          viewModel.canCreatePersonalScheduleCycle
            ? [togglePastSchedulesAction, createScheduleAction]
            : [togglePastSchedulesAction]
        }
      >
        <ScheduleCycleList viewModel={viewModel} showSource />

        {state.plannerSchedulesListShowCreate != null && (
          <ScheduleCycleCreateDialog isOpen={true} userDashboard={state.plannerSchedulesListShowCreate} />
        )}

        {showScheduleUserDashboardPicker && (
          <ScheduleCycleCreationUserDashboardPicker
            isOpen={true}
            onClose={onCreateScheduleCycleUserDashboardSelection}
          />
        )}
      </SettingsCard>
    </Stack>
  );
});
