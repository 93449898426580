import {
  AttachmentInfo,
  plannerAttachmentKindToAttachmentInfoKind,
  plannerHasAccessKindsForUser,
  urlForExternalSourceBadge,
  urlForWorkIconFromWork,
  workCurrentAndUpcomingPlannedWorks,
  WorkIconInfo,
  workIsLate
} from '@/models';
import { ServiceContainer } from '@/providers';
import { localizedExternalSourceName } from '@/resources/strings/utils/ExternalSourceStringsUtils';
import { UserDashboardPlannerItemsLocationState } from '@/services';
import { mergeLoadableStates, PlannerDetailedCourseSectionsLoadable, WorkIconsLoadable, WorkLoadable } from '@/stores';
import { AccessKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/access_kind_pb';
import { CourseSection } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_pb';
import { CustomActionEffect } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/custom_action_effect_pb';
import { CustomWorkAction } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/custom_work_action_pb';
import { ExternalSource } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/external_source_pb';
import { RichText } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/rich_text_pb';
import { Work } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_pb';
import { WorkStatus } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_status_pb';
import { captureException } from '@sentry/react';
import { isSameDay } from 'date-fns';
import { action, computed, makeObservable, observable, override, runInAction } from 'mobx';
import { Location, NavigateFunction } from 'react-router';
import { BackgroundLocationState } from '../../BackgroundLocationState';
import {
  AppBaseUpdatableDialogViewModel,
  BaseDialogActionButtonConfiguration,
  CloseDialogActionButtonConfiguration,
  DialogActionButtonConfiguration,
  EditDialogActionButtonConfiguration,
  UpdatableDialogViewModel
} from '../utils';
import { AppWorkDetailsDueDateViewModel, WorkDetailsDueDateViewModel } from './WorkDetailsDueDateViewModel';
import { AppWorkDetailsGradeViewModel, WorkDetailsGradeViewModel } from './WorkDetailsGradeViewModel';
import { AppWorkPlannedWorksViewModel, WorkPlannedWorksViewModel } from './WorkPlannedWorksViewModel';
import { AppWorkStepsViewModel, WorkStepsViewModel } from './WorkStepsViewModel';

export interface WorkDetailsViewModel extends UpdatableDialogViewModel {
  readonly isApplying: boolean;

  readonly isReadOnly: boolean;
  readonly editIsEnabled: boolean;
  readonly courseTitle: string;
  readonly section: string | undefined;
  readonly courseColor: string | undefined;
  readonly workTitle: string;
  readonly workDescription: RichText | undefined;
  readonly workIcon?: WorkIconInfo;
  readonly customActions: CustomWorkAction[];
  readonly externalSource?: ExternalSource;
  readonly attachments: AttachmentInfo[];
  readonly isLate: boolean;
  readonly isPlanned: boolean;
  readonly isDueToday: boolean;
  readonly dueDateViewModel: WorkDetailsDueDateViewModel;
  readonly stepsViewModel: WorkStepsViewModel;
  readonly plannedWorksViewModel: WorkPlannedWorksViewModel;
  readonly gradeViewModel: WorkDetailsGradeViewModel;
  readonly work: Work;
  readonly isCompleted: boolean;
  readonly isCancelled: boolean;
  readonly isStatusChangedExternally: boolean;

  showNoExternalUrlAlert: boolean;

  copyToPasteboard(): void;
  duplicate(navigate: NavigateFunction, location: Location): void;
  distribute(navigate: NavigateFunction, location: Location): void;
  repeat(navigate: NavigateFunction, location: Location): void;
  close(): Promise<void>;
  cancelWork(): Promise<void>;
  restoreWork(): Promise<void>;
  performCustomAction(effect: CustomActionEffect): Promise<void>;
}

export class AppWorkDetailsViewModel extends AppBaseUpdatableDialogViewModel implements WorkDetailsViewModel {
  @observable private _isApplying = false;
  @observable private _showNoExternalUrlAlert = false;

  private readonly _closeButtonConfig: CloseDialogActionButtonConfiguration;
  private readonly _editButtonConfig: EditDialogActionButtonConfiguration;

  private _openExternalWorkButtonConfig = new BaseDialogActionButtonConfiguration(
    'secondary',
    'both',
    'right',
    'open-in',
    'start',
    () => '', // Title will be set in supplementaryActions
    'contained-grey',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    async () => {}
  );

  constructor(
    private readonly _workId: string,
    private readonly _plannerId: string,
    private readonly _close: () => Promise<void>,
    localization = ServiceContainer.services.localization,
    private readonly _workStore = ServiceContainer.services.workStore,
    private readonly _plannerStore = ServiceContainer.services.plannerStore,
    private readonly _userStore = ServiceContainer.services.userStore,
    private readonly _dateService = ServiceContainer.services.dateService,
    private readonly _pasteboard = ServiceContainer.services.pasteboard
  ) {
    super(localization, _close);
    makeObservable(this);

    this._closeButtonConfig = new CloseDialogActionButtonConfiguration('main', this._localization, () =>
      this.dismiss()
    );

    this._editButtonConfig = new EditDialogActionButtonConfiguration(
      'main',
      this._localization,
      { url: undefined, state: { workDetailsShowWorkEdit: true } },
      false
    );
  }

  @computed
  private get courseSections(): PlannerDetailedCourseSectionsLoadable {
    return this._plannerStore.getCourseSectionsInPlanner(this._plannerId);
  }

  @computed
  private get workIcons(): WorkIconsLoadable {
    return this._workStore.workIcons;
  }

  @computed
  private get workLoadable(): WorkLoadable {
    return this._workStore.getWorkLoadable(this._workId);
  }

  @computed
  private get courseSection(): CourseSection | undefined {
    if (this.work?.courseSectionId == null) {
      return undefined;
    }

    return this.courseSections.data.get(this.work.courseSectionId)?.courseSection;
  }

  @computed
  get editIsEnabled() {
    return !this.isApplying;
  }

  @computed
  get isReadOnly(): boolean {
    const planner = this._userStore.getPlannerForId(this._plannerId);

    if (planner == null) {
      return true;
    }

    return !plannerHasAccessKindsForUser(this._userStore.user.userId, planner, AccessKind.FULL_ACCESS);
  }

  @override
  get actions(): DialogActionButtonConfiguration[] {
    this._closeButtonConfig.isEnabled = !this.isApplying;
    return [this._closeButtonConfig];
  }

  @override
  get supplementaryActions(): DialogActionButtonConfiguration[] {
    if (this.isReadOnly) {
      return [];
    }

    if (this.work.externalSource == null) {
      if (this.isCancelled) {
        return [];
      }

      this._editButtonConfig.isEnabled = this.editIsEnabled;
      return [this._editButtonConfig];
    } else if (this.work.externalSource.url.length == 0) {
      return [];
    } else {
      this._openExternalWorkButtonConfig.isEnabled = !this.isApplying;
      this._openExternalWorkButtonConfig.title = () =>
        this._localization.localizedStrings.work.details.openWorkLinkButtonLabel(
          localizedExternalSourceName(this.work.externalSource!.sourceName)
        );
      this._openExternalWorkButtonConfig.url = this.work.externalSource.url;
      return [this._openExternalWorkButtonConfig];
    }
  }

  @computed
  get state() {
    return mergeLoadableStates([this.workLoadable.state, this.courseSections.state, this.workIcons.state]);
  }

  @computed
  get hasData() {
    return this.workLoadable.hasData && this.courseSections.hasData && this.workIcons.hasData;
  }

  @computed
  get isApplying(): boolean {
    return this._isApplying || this.dueDateViewModel.isApplying;
  }

  @computed
  get isSubmitting(): boolean {
    return this.isApplying;
  }

  readonly hasChanges = false;

  @override
  get error(): string | undefined {
    if (this._error != null) {
      return this._error;
    } else if (this.hasData) {
      return this.dueDateViewModel.error;
    }
  }

  @computed
  get work() {
    return this.workLoadable.data;
  }

  @computed
  get courseTitle(): string {
    return this.courseSection?.title ?? this._localization.localizedStrings.work.details.noCourseTitle;
  }

  @computed
  get section(): string | undefined {
    return this.courseSection?.section;
  }

  @computed
  get courseColor(): string | undefined {
    return this.courseSection?.color;
  }

  @computed
  get workTitle(): string {
    return (
      (this.work.title || this.workIcon?.title) ?? this._localization.localizedStrings.work.details.defaultWorkTitle
    );
  }

  @computed
  get workDescription(): RichText | undefined {
    return this.work.description;
  }

  @computed
  get workIcon(): WorkIconInfo | undefined {
    const icon = this.workIcons.data.iconsById.get(this.work.iconId);
    if (icon == null) {
      return undefined;
    }

    return {
      id: icon.iconId,
      title: icon.iconName,
      lightUrl: urlForWorkIconFromWork(icon, this.work, 'light'),
      darkUrl: urlForWorkIconFromWork(icon, this.work, 'dark'),
      externalBadgeUrl: urlForExternalSourceBadge(this.work.externalSource?.sourceName, this.workIcons.data)
    };
  }

  @computed
  get customActions(): CustomWorkAction[] {
    return this.work.customActions;
  }

  @computed
  get externalSource(): ExternalSource | undefined {
    return this.work.externalSource;
  }

  @computed
  get attachments(): AttachmentInfo[] {
    return this.work.attachments.map((attachment) => ({
      ...attachment,
      kind: plannerAttachmentKindToAttachmentInfoKind(attachment.kind)
    }));
  }

  @computed
  get isLate(): boolean {
    return workIsLate(this.work, this._dateService);
  }

  @computed
  get isPlanned(): boolean {
    return workCurrentAndUpcomingPlannedWorks(this.work, this._dateService).length > 0;
  }

  @computed
  get isDueToday(): boolean {
    return this.work.dueTime != null ? isSameDay(this.work.dueTime.toDate(), this._dateService.now) : false;
  }

  @computed
  get isStatusChangedExternally(): boolean {
    return this.work.isStatusChangedExternally;
  }

  @computed
  get dueDateViewModel(): WorkDetailsDueDateViewModel {
    return new AppWorkDetailsDueDateViewModel(
      () => this.work,
      this._close,
      this._workStore,
      this._plannerStore,
      this._userStore,
      this._localization
    );
  }

  @computed
  get stepsViewModel(): WorkStepsViewModel {
    return new AppWorkStepsViewModel(() => this.work, this._workStore, this._plannerStore, this._userStore);
  }

  @computed
  get plannedWorksViewModel(): WorkPlannedWorksViewModel {
    return new AppWorkPlannedWorksViewModel(
      () => this.work,
      () => this.stepsViewModel.steps,
      (id) => this.cancelPlannedWork(id)
    );
  }

  @computed
  get gradeViewModel(): WorkDetailsGradeViewModel {
    return new AppWorkDetailsGradeViewModel(() => this.work);
  }

  @computed
  get isCompleted(): boolean {
    return this.work.status === WorkStatus.COMPLETED;
  }

  @computed
  get isCancelled(): boolean {
    return this.work.status === WorkStatus.CANCELLED;
  }

  @computed
  get showNoExternalUrlAlert(): boolean {
    return this._showNoExternalUrlAlert;
  }

  set showNoExternalUrlAlert(value: boolean) {
    this._showNoExternalUrlAlert = value;
  }

  copyToPasteboard() {
    this._pasteboard.setContent({ case: 'work', value: this.work });
    void this.close();
  }

  close() {
    return this._close();
  }

  async reloadData() {
    await this.loadData();
  }

  async cancelWork() {
    try {
      runInAction(() => {
        this._isApplying = true;
        this._error = undefined;
      });

      await this._workStore.setWorkStatus(this.work.id, WorkStatus.CANCELLED, this.work.syncToken);
      await this._plannerStore.fetchPlannerContents(this._plannerId);

      runInAction(() => (this._isApplying = false));
    } catch (e) {
      captureException(e);
      const error = e as Error;

      runInAction(() => {
        this._error = error.message;
        this._isApplying = false;
      });
    }
  }

  async restoreWork() {
    if (this.isStatusChangedExternally) {
      if (this.work.externalSource?.url != null) {
        window.open(this.work.externalSource.url, '_blank', 'noreferrer');
      } else {
        this.showNoExternalUrlAlert = true;
      }
      return;
    }

    try {
      runInAction(() => {
        this._isApplying = true;
        this._error = undefined;
      });

      await this._workStore.setWorkStatus(this.work.id, WorkStatus.ACTIVE, this.work.syncToken);
      await this._plannerStore.fetchPlannerContents(this._plannerId);

      runInAction(() => (this._isApplying = false));
      await this.close();
    } catch (e) {
      captureException(e);
      const error = e as Error;
      runInAction(() => {
        this._error = error.message;
        this._isApplying = false;
      });
    }
  }

  @action
  clearError() {
    super.clearError();
    if (this.hasData) {
      this.dueDateViewModel.error = undefined;
    }
  }

  async performCustomAction(effect: CustomActionEffect) {
    switch (effect) {
      case CustomActionEffect.CANCEL_WORK:
        await this.cancelWork();
        break;

      default:
        console.error('Unsupported custom effect');
    }
  }

  duplicate(navigate: NavigateFunction, location: Location) {
    this.showPlannerItemDialog(navigate, location, {
      workEdit: { workId: undefined, newWorkDefaultValues: { ...this.work, isDueAllDay: true, dueTime: undefined } }
    });
  }

  distribute(navigate: NavigateFunction, location: Location) {
    this.showPlannerItemDialog(navigate, location, {
      distributeItem: { kind: { case: 'work', id: this._workId } }
    });
  }

  repeat(navigate: NavigateFunction, location: Location) {
    this.showPlannerItemDialog(navigate, location, {
      repeatItem: { kind: { case: 'work', id: this._workId } }
    });
  }

  private async cancelPlannedWork(id: string) {
    await this._workStore.cancelPlannedWork(id, this._workId, this.work.syncToken);
    void this._plannerStore.fetchPlannerContents(this._plannerId);
  }

  private async loadData() {
    this.clearError();

    try {
      await Promise.all([this.courseSections.fetch(false), this.workIcons.fetch(false), this.workLoadable.fetch(true)]);
    } catch (e) {
      captureException(e);
      const error = e as Error;
      runInAction(() => {
        this._error = error.message;
      });
    }
  }

  private showPlannerItemDialog(
    navigate: NavigateFunction,
    location: Location,
    state: UserDashboardPlannerItemsLocationState
  ) {
    const newLocation = ((location.state || {}) as BackgroundLocationState)?.backgroundLocation ?? location;
    void navigate(newLocation, { state, replace: true });
  }
}
