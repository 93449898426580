import { Card, CardProps, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';

export interface SchoolScreenCardProps extends CardProps {
  forcedBackgroundColor?: string;
  forcedBorderColor?: string;
}

export const ContentCard = observer(
  ({ forcedBackgroundColor, forcedBorderColor, ref, ...props }: SchoolScreenCardProps) => {
    const theme = useTheme();

    return (
      <Card
        {...props}
        elevation={0}
        ref={ref}
        sx={{
          ...props.sx,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: forcedBorderColor ?? theme.palette.divider,
          backgroundColor: forcedBackgroundColor ?? 'transparent',
          backgroundImage: 'none'
        }}
      />
    );
  }
);
