import { useActivePlannerId, useNavigateAsync, useViewModel } from '@/hooks';
import { Stack } from '@mui/material';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { Dialog } from '../../utils';
import { PlannedWorkEditSchedule } from './PlannedWorkEditSchedule';

export interface PlannedWorkEditDialogProps {
  /**
   * Id of the work associated to the planned work to edit.
   */
  workId: string;

  /**
   * Id of the planned work to edit. Optional. If undefined, it creates a new planned work.
   */
  plannedWorkId: string | undefined;

  isOpen: boolean;
}

export const PlannedWorkEditDialog = observer(({ workId, plannedWorkId, isOpen }: PlannedWorkEditDialogProps) => {
  const navigate = useNavigateAsync();
  const plannerId = useActivePlannerId();

  function dismiss() {
    return navigate(-1);
  }

  const strings = LocalizedStrings.plannedWork.edit;
  const viewModel = useViewModel((viewModels) =>
    viewModels.createPlannedWorkEditViewModel(plannerId, workId, plannedWorkId, dismiss, dismiss)
  );

  return (
    <Dialog
      isOpen={isOpen}
      width="md"
      viewModel={viewModel}
      title={strings.title()}
      fullScreenWidth="md"
      submit={() => void viewModel.save()}
      fullHeight
      isContentDisplayFlex
      disableOverflow
      renderData={() => (
        <Stack
          spacing={1}
          sx={{
            width: '100%',
            height: '100%',
            overflow: 'hidden'
          }}
        >
          <PlannedWorkEditSchedule sx={{ flex: 1 }} viewModel={viewModel} />
        </Stack>
      )}
    />
  );
});
