import { useServices } from '@/hooks';
import {
  addMinutesToTimeOfDay,
  dateToPBDate,
  dateToTimeOfDay,
  dayToDate,
  differenceInMinutesBetweenTimeOfDays,
  timeOfDayToDate
} from '@/models';
import { EditablePlannedWorkEditInfo, PlannedWorkEditInfo } from '@/viewmodels';
import { WorkStep } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_step_pb';
import { CheckCircleRounded, CheckRounded, CircleOutlined } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Chip,
  Dialog,
  FormHelperText,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { SxProps } from '@mui/system';
import {
  PickersLayoutContentWrapper,
  PickersLayoutProps,
  PickersLayoutRoot,
  PickersShortcutsProps,
  TimePicker,
  usePickerLayout
} from '@mui/x-date-pickers';
import { TimeViewWithMeridiem } from '@mui/x-date-pickers/internals/models';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import LocalizedStrings from 'strings';
import { CalendarDatePicker, FormPopoverActions, FormPopoverHeader, Subheader } from '../../utils';

const suggestedDurations = [15, 30, 45, 60, 90, 120];

export interface PlannedWorkEditInfoDialogProps {
  sx?: SxProps;
  className?: string;
  isOpen: boolean;
  editable: EditablePlannedWorkEditInfo;
  steps: WorkStep[];
  getIsConflicting: (info: PlannedWorkEditInfo) => boolean;
  onSave: () => void;
  onCancel: () => void;
  onDelete: () => void;
}

export const PlannedWorkEditInfoDialog = observer(
  ({
    sx = [],
    editable,
    onCancel,
    onSave,
    isOpen,
    className,
    onDelete,
    steps,
    getIsConflicting
  }: PlannedWorkEditInfoDialogProps) => {
    const { localization } = useServices();
    const strings = LocalizedStrings.plannedWork.edit;
    const theme = useTheme();
    const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));

    const save = () => {
      onSave();
      return Promise.resolve();
    };

    const cancel = () => {
      onCancel();
      return Promise.resolve();
    };

    const deleteInfo = () => {
      onDelete();
      return Promise.resolve();
    };

    const endTimeSuggestions = useMemo(() => {
      return suggestedDurations.map((d) => {
        const timeOfDay = addMinutesToTimeOfDay(editable.startTime, d);
        const date = timeOfDayToDate(timeOfDay);

        return {
          label: `${format(date, 'p')} (${localization.localizedStrings.dateTime.shortDurationFormat(d)})`,
          getValue: () => date
        };
      });
    }, [editable.startTime]);

    const duration: string = useMemo(() => {
      const diff = Math.abs(differenceInMinutesBetweenTimeOfDays(editable.endTime, editable.startTime));
      return localization.localizedStrings.dateTime.shortDurationFormat(diff);
    }, [editable.startTime, editable.endTime]);

    const canSave = useMemo(() => {
      return !getIsConflicting(editable);
    }, [editable.startTime, editable.endTime, editable.day]);

    return (
      <Dialog open={isOpen} onClose={onCancel} sx={sx} className={className} maxWidth="xs" fullWidth>
        <Stack
          sx={{
            overflow: 'hidden',
            height: '100%',
            width: '100%'
          }}
        >
          <FormPopoverHeader title={editable.isNew ? strings.addSessionTitle() : strings.editSessionTitle()} />
          <Stack
            spacing={1}
            sx={{
              p: 2,
              pt: 1,
              flex: 1,
              overflow: 'auto'
            }}
          >
            {editable.isInThePast && <Alert severity="warning">{strings.pastSessionWarning()}</Alert>}

            <Stack>
              <Subheader>{strings.datePickerLabel()}</Subheader>
              <CalendarDatePicker
                value={dayToDate(editable.day)}
                onChange={(v) => {
                  if (v != null) {
                    editable.day = dateToPBDate(v);
                  }
                }}
                showDaysOutsideCurrentMonth
                kind="user-dashboard-calendar"
              />
            </Stack>

            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              sx={{
                columnGap: 1,
                rowGap: 1
              }}
            >
              <Stack
                sx={{
                  flex: 1
                }}
              >
                <Subheader>{strings.startTimePickerLabel()}</Subheader>
                <TimePicker
                  value={timeOfDayToDate(editable.startTime)}
                  format="p"
                  maxTime={timeOfDayToDate(addMinutesToTimeOfDay(editable.endTime, -5))}
                  onChange={(v) => v && (editable.startTime = dateToTimeOfDay(v))}
                  slotProps={{ textField: { error: !canSave } }}
                />
              </Stack>

              <Stack
                sx={{
                  flex: 1
                }}
              >
                <Subheader>{strings.endTimePickerLabel()}</Subheader>
                <TimePicker
                  value={timeOfDayToDate(editable.endTime)}
                  format="p"
                  minTime={timeOfDayToDate(addMinutesToTimeOfDay(editable.startTime, 5))}
                  onChange={(v) => v && (editable.endTime = dateToTimeOfDay(v))}
                  slots={{ layout: CustomEndTimePickerLayout, shortcuts: CustomEndTimeShortcuts }}
                  slotProps={{
                    textField: { error: !canSave },
                    shortcuts: { items: endTimeSuggestions, dense: true, disablePadding: true }
                  }}
                />
              </Stack>
            </Stack>

            <FormHelperText sx={{ px: 1 }}>Duration: {duration}</FormHelperText>

            {!canSave && (
              <Typography
                variant="subtitle2"
                sx={{
                  color: theme.palette.error.main,
                  fontWeight: '500',
                  px: 1
                }}
              >
                {strings.conflictErrorMessage()}
              </Typography>
            )}

            {steps.length > 0 && (
              <Stack>
                <Subheader>{strings.assignStepsSectionTitle()}</Subheader>
                <List dense disablePadding>
                  {steps.map((step) => (
                    <ListItemButton
                      key={step.id}
                      sx={{ borderRadius: 1, pl: 1 }}
                      onClick={() => editable.toggleStepId(step.id)}
                    >
                      <ListItemIcon sx={{ mr: 1.5 }}>
                        {step.isCompleted ? (
                          <CheckCircleRounded fontSize="small" color="disabled" />
                        ) : (
                          <CircleOutlined fontSize="small" color="disabled" />
                        )}
                      </ListItemIcon>

                      <ListItemText
                        primary={step.title}
                        secondary={step.duration > 0 ? `${step.duration} min` : undefined}
                        sx={{ my: step.duration > 0 ? '2px' : undefined }}
                        slotProps={{ primary: { noWrap: true }, secondary: { variant: 'caption' } }}
                      />

                      {editable.stepIds.includes(step.id) && (
                        <CheckRounded sx={{ ml: 1 }} color="primary" fontSize="small" />
                      )}
                    </ListItemButton>
                  ))}
                </List>
              </Stack>
            )}

            {!editable.isNew && isExtraSmallScreen && (
              <Button color="error" onClick={() => void deleteInfo()}>
                {strings.removeSessionButtonLabel()}
              </Button>
            )}
          </Stack>

          <FormPopoverActions
            onSubmit={() => save()}
            canSubmit={canSave}
            onCancel={() => cancel()}
            additionalActions={
              !isExtraSmallScreen
                ? [
                    {
                      id: 'remove',
                      hidden: editable.isNew,
                      kind: 'button',
                      title: strings.removeSessionButtonLabel(),
                      onClick: () => deleteInfo(),
                      isDestructive: true
                    }
                  ]
                : undefined
            }
          />
        </Stack>
      </Dialog>
    );
  }
);

// Custom layout end time picker to place shortcuts on the right instead of the left
function CustomEndTimePickerLayout(props: PickersLayoutProps<Date | null, Date, TimeViewWithMeridiem>) {
  const { content, actionBar, shortcuts } = usePickerLayout(props);

  return (
    <PickersLayoutRoot ownerState={props}>
      <Stack direction={{ xs: 'column', sm: 'row' }}>
        <PickersLayoutContentWrapper>
          {content}
          {actionBar}
        </PickersLayoutContentWrapper>
        <Stack
          sx={{
            pb: { xs: 2, sm: 0 },
            pl: { xs: 2, sm: 0 }
          }}
        >
          <Subheader>{LocalizedStrings.plannedWork.edit.endTimesPresetsTitle()}</Subheader>
          {shortcuts}
        </Stack>
      </Stack>
    </PickersLayoutRoot>
  );
}

function CustomEndTimeShortcuts(props: PickersShortcutsProps<Date | null>) {
  const { items, onChange, isValid, changeImportance = 'accept' } = props;

  if (items == null || items.length === 0) {
    return null;
  }

  const resolvedItems = items.map((item) => {
    const newValue = item.getValue({ isValid });

    return {
      label: item.label,
      onClick: () => {
        onChange(newValue, changeImportance, item);
      },
      disabled: !isValid(newValue)
    };
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'row', sm: 'column' },
        rowGap: 1,
        columnGap: 1,
        flexWrap: 'wrap',
        gridRow: 1,
        gridColumn: 1,
        maxWidth: 300,
        px: 2
      }}
    >
      {resolvedItems.map((item) => (
        <Chip key={item.label} {...item} size="small" />
      ))}
    </Box>
  );
}
