import { BackgroundLocationState } from '@/BackgroundLocationState';
import { RouteService } from '@/services';
import { NavigateFunctionAsync } from '../../../utils';

export function onDialogClose(
  route: RouteService,
  navigate: NavigateFunctionAsync,
  state: BackgroundLocationState,
  plannerId: string
): Promise<void> {
  if (state.backgroundLocation != null) {
    return navigate(-1);
  } else {
    const newLocation = route.resolvePlannerLocation(plannerId);
    return navigate(newLocation);
  }
}
