import { useBackButtonListener } from '@/hooks';
import { CourseSectionColors, OrderedScheduleSymbolKinds } from '@/models';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  ListSubheader,
  MenuItem,
  Popover,
  Stack,
  useTheme
} from '@mui/material';
import { SxProps } from '@mui/system';
import EmojiPicker, { Theme as EmojiPickerTheme } from 'emoji-picker-react';
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import LocalizedStrings from 'strings';
import { ColorPicker, ControlledSelect, ControlledSelectRef } from '../../../../../utils';
import { EmojiSymbol, ScheduleSymbol } from '../../../../calendar';

export interface SpecialDayEditSymbolSectionProps {
  sx?: SxProps;
  className?: string;
  symbol: string;
  onSymbolSelection: (symbol: string) => void;
  color: string;
  onColorSelection: (color: string) => string;
  isSymbolVisible: boolean;
  setIsSymbolVisible: (value: boolean) => void;
}

export const SpecialDayEditSymbolSection = observer(
  ({
    sx = [],
    className,
    symbol,
    onSymbolSelection,
    color,
    onColorSelection,
    isSymbolVisible,
    setIsSymbolVisible
  }: SpecialDayEditSymbolSectionProps) => {
    const theme = useTheme();
    const [emojiPickerButtonRef, setEmojiPickerButtonRef] = useState<HTMLInputElement | null>();
    const symbolPickerRef = useRef<ControlledSelectRef | null>(null);
    const strings = LocalizedStrings.scheduleCycle.edit.specialDays.edit;

    useBackButtonListener((e) => {
      if (emojiPickerButtonRef != null) {
        e.stopPropagation();
        setEmojiPickerButtonRef(null);
      }
    });

    return (
      <FormGroup sx={sx} className={className}>
        <Box sx={{ height: 40, overflow: 'hidden' }}>
          <ListSubheader>{strings.symbolSectionTitle()}</ListSubheader>
        </Box>
        <Stack
          direction={'row'}
          spacing={2}
          sx={{
            alignItems: 'center',
            my: 1
          }}
        >
          <Box
            sx={{
              flex: 1
            }}
          >
            <ControlledSelect
              ref={symbolPickerRef}
              value={symbol}
              size="small"
              onChange={(e) => {
                if (e.target.value === 'emoji') {
                  return;
                }

                onSymbolSelection(e.target.value);
                symbolPickerRef.current?.close();
              }}
              displayEmpty
              fullWidth
            >
              {OrderedScheduleSymbolKinds.map((symbolKind) => (
                <MenuItem key={symbolKind} value={symbolKind}>
                  <ScheduleSymbol symbolKind={symbolKind} size={22} color={color} />
                </MenuItem>
              ))}

              {symbol.length > 0 && !symbol.startsWith(':') && (
                <MenuItem value={symbol}>
                  <EmojiSymbol emoji={symbol} size={22} />
                </MenuItem>
              )}

              <MenuItem value="">{strings.symbolNoneOption()}</MenuItem>
              <MenuItem value="emoji" onClick={() => setEmojiPickerButtonRef(symbolPickerRef.current)}>
                {strings.symbolSelectEmojiOption()}
              </MenuItem>
            </ControlledSelect>
          </Box>

          {symbol.startsWith(':') && (
            <Box
              sx={{
                flex: 1
              }}
            >
              <ColorPicker
                size="small"
                fullWidth
                label={strings.colorPickerTitle()}
                color={color}
                palette={CourseSectionColors}
                onChange={(value) => onColorSelection(value)}
                elevation={2}
              />
            </Box>
          )}
        </Stack>
        <Popover
          open={emojiPickerButtonRef != null}
          anchorEl={emojiPickerButtonRef}
          onClose={() => setEmojiPickerButtonRef(undefined)}
        >
          <EmojiPicker
            autoFocusSearch={false}
            lazyLoadEmojis
            onEmojiClick={(emoji) => {
              onSymbolSelection(emoji.emoji);
              setEmojiPickerButtonRef(undefined);
              symbolPickerRef.current?.close();
            }}
            theme={theme.palette.mode === 'dark' ? EmojiPickerTheme.DARK : EmojiPickerTheme.LIGHT}
            previewConfig={{ showPreview: false }}
          />
        </Popover>
        {symbol.length > 0 && (
          <FormControlLabel
            control={<Checkbox />}
            label={strings.symbolVisibilityOptionTitle()}
            checked={isSymbolVisible}
            onChange={(_, checked) => setIsSymbolVisible(checked)}
          />
        )}
      </FormGroup>
    );
  }
);
