import { TeacherOnboardingOptionsViewModel } from '@/viewmodels';
import { Box, Button, Stack, SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { NavigationButton } from '../utils';

export interface TeacherOnboardingOptionsProps {
  sx?: SxProps;
  className?: string;
  viewModel: TeacherOnboardingOptionsViewModel;
}

export const TeacherOnboardingOptions = observer(({ sx, className, viewModel }: TeacherOnboardingOptionsProps) => {
  const strings = LocalizedStrings.onboarding.teacherOptions;

  return (
    <Box sx={sx} className={className} width="100%" display="flex" flexDirection="column">
      <Stack spacing={2}>
        <NavigationButton
          title={strings.createSchoolTitle()}
          subtitle={strings.createSchoolSubtitle()}
          onClick={() => viewModel.createSchool()}
        />

        <NavigationButton
          title={strings.useCodeTitle()}
          subtitle={strings.useCodeSubtitle()}
          onClick={() => viewModel.joinSchool()}
        />

        <Button onClick={() => void viewModel.signOut()}>{strings.signOut()}</Button>
      </Stack>
    </Box>
  );
});
