import { Box, BoxProps, InputLabel } from '@mui/material';
import NotchedOutline from '@mui/material/OutlinedInput/NotchedOutline';
import clsx from 'clsx';
import { ElementType } from 'react';
import './RichTextEditorNotchedInput.styles.css';

export interface RichTextEditorNotchedInputProps {
  id: string;
  label: string;
}

export function RichTextEditorNotchedInput<C extends ElementType>({
  id,
  label,
  children,
  className,
  ...props
}: RichTextEditorNotchedInputProps & BoxProps<C, { component?: C }>) {
  return (
    <Box {...props} className={clsx(className, 'rich-text-editor-notched-input')}>
      <InputLabel htmlFor={id} variant="outlined" shrink className="rich-text-editor-notched-input-label">
        {label}
      </InputLabel>
      <div className="rich-text-editor-notched-input-content-wrapper">
        <div id={id} className="rich-text-editor-notched-input-content">
          {children}
          <NotchedOutline notched label={label} className="rich-text-editor-notched-input-outline" />
        </div>
      </div>
    </Box>
  );
}
