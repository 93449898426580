import { useNavigateAsync, useServices } from '@/hooks';
import {
  UserDashboardCalendarDayTimedNoteInfo,
  UserDashboardCalendarItemState,
  UserDashboardCalendarNoteInfo,
  UserDashboardCalendarWeekTimedNoteInfo
} from '@/viewmodels';
import { Box, Card, CardActionArea, Stack, Tooltip, Typography, styled } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useLocation } from 'react-router';
import { BackgroundLocationState } from '../../../../../BackgroundLocationState';
import { ColorIndicator, NoteIcon } from '../../../../lists';
import { MultilineTooltipTitle } from '../../../../utils';
import { ContextMenuHandler } from '../../../ContextMenuHandler';
import { formatItemStartTimeLong, formatItemTime } from '../../shared';

function isTimedNote(
  note: UserDashboardCalendarNoteInfo | UserDashboardCalendarWeekTimedNoteInfo
): note is UserDashboardCalendarWeekTimedNoteInfo {
  return (note as UserDashboardCalendarDayTimedNoteInfo)?.startTime != null;
}

export interface UserDashboardCalendarWeekNoteViewProps {
  sx?: SxProps;
  className?: string;
  note: UserDashboardCalendarNoteInfo | UserDashboardCalendarWeekTimedNoteInfo;
  showBorder: boolean;
}

export const UserDashboardCalendarWeekNoteView = observer(
  ({ sx = [], className, note, showBorder }: UserDashboardCalendarWeekNoteViewProps) => {
    const { navigation } = useServices();
    const defaultHeight = 37;
    const [showTooltip, setShowTooltip] = useState(false);

    const location = useLocation();
    const state = (location.state ?? {}) as BackgroundLocationState;
    const navigate = useNavigateAsync();

    function showWorkDetails() {
      navigation.navigateToNoteEdit(navigate, location, note.id);
    }

    return (
      <ContextMenuHandler actions={note.contextMenuActions()}>
        {(contextMenuHandler) => (
          <Tooltip
            title={
              <MultilineTooltipTitle
                lines={
                  isTimedNote(note)
                    ? [note.text?.plainText ?? '', formatItemStartTimeLong(note.startTime)]
                    : [note.text?.plainText ?? '']
                }
              />
            }
            open={showTooltip && state.backgroundLocation == null}
            disableHoverListener
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            disableInteractive
          >
            <StyledNoteView
              sx={{ ...sx, height: isTimedNote(note) ? note.position.height : defaultHeight }}
              className={className}
              // Prevents background click event when user clicks on the left or right padding of the period.
              onClick={(e) => e.stopPropagation()}
              showBorder={showBorder}
              onContextMenu={contextMenuHandler}
            >
              <Card className="card" elevation={0} sx={{ borderRadius: isTimedNote(note) ? 1 : 0.5 }}>
                <CardActionArea
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowTooltip(false);
                    showWorkDetails();
                  }}
                  className="cardAction"
                >
                  <Box className="mainContainer">
                    <Box className="colorIndicatorContainer">
                      <ColorIndicator color={note.color} isRounded sx={{ height: '100%' }} />
                    </Box>

                    <Stack className="contentContainer" spacing={0.5}>
                      <Stack
                        direction="row"
                        spacing={0.5}
                        sx={{
                          overflow: 'hidden',
                          flex: 1
                        }}
                      >
                        <NoteIcon size={20} sx={{ pt: '2px' }} />

                        <Stack
                          spacing={-0.5}
                          sx={{
                            flex: 1,
                            overflow: 'hidden'
                          }}
                        >
                          <Typography
                            variant="caption"
                            color={getColorForTitle(note.state)}
                            sx={{
                              fontSize: 10
                            }}
                          >
                            {note.text?.plainText}
                          </Typography>
                        </Stack>

                        {isTimedNote(note) && (
                          <Typography
                            noWrap
                            variant="caption"
                            color={getColorForTitle(note.state)}
                            sx={{
                              fontSize: 10
                            }}
                          >
                            {formatItemTime(note.startTime)}
                          </Typography>
                        )}
                      </Stack>
                    </Stack>
                  </Box>
                </CardActionArea>
              </Card>
            </StyledNoteView>
          </Tooltip>
        )}
      </ContextMenuHandler>
    );
  }
);

interface StyledWorkViewProps {
  showBorder: boolean;
}

const StyledNoteView = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'showBorder'
})<StyledWorkViewProps>(({ theme, showBorder }) => {
  const colorIndicatorPadding = showBorder ? 4 : 2;

  return {
    '.badge': {
      height: '100%',
      width: '100%'
    },

    '.card': {
      height: '100%',
      width: '100%',
      backgroundImage: 'none',
      backgroundColor: !showBorder ? 'transparent' : undefined,
      border: showBorder ? `1px solid ${theme.palette.divider}` : undefined
    },

    '.cardAction': {
      paddingRight: '6px',
      overflow: 'hidden',
      height: '100%'
    },

    '.mainContainer': {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      padding: 2
    },

    '.colorIndicatorContainer': {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
      height: '100%',
      paddingTop: colorIndicatorPadding,
      paddingBottom: colorIndicatorPadding
    },

    '.contentContainer': {
      flex: 1,
      display: 'flex',
      overflow: 'hidden',
      paddingTop: '2px',
      paddingBottom: '2px',
      minWidth: 120
    }
  };
});

function getColorForTitle(state: UserDashboardCalendarItemState) {
  switch (state) {
    case 'completed':
      return 'textSecondary';
    default:
      return 'textPrimary';
  }
}
