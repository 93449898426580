import { useActivePlannerId, useNavigateAsync, useServices, useViewModel } from '@/hooks';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import LocalizedStrings from 'strings';
import { Dialog } from '../utils';
import { SubscriptionsProductsView } from './SubscriptionsProductsView';

export interface SubscriptionsDialogProps {
  isOpen: boolean;
}

export const SubscriptionsDialog = observer(({ isOpen }: SubscriptionsDialogProps) => {
  const { analytics, route } = useServices();
  const strings = LocalizedStrings.subscriptions.manage;
  const plannerId = useActivePlannerId();

  const navigate = useNavigateAsync();

  function close() {
    return navigate(-1);
  }

  async function onSubscribe(sharedSchoolIds: string[]) {
    if (sharedSchoolIds.length === 1) {
      await navigate(route.resolveAdminSchoolInformationLocation(sharedSchoolIds[0]), { replace: true });
    }
  }

  const viewModel = useViewModel(
    (viewModels) => viewModels.createSubscriptionsDialogViewModel(plannerId, close, onSubscribe),
    [plannerId]
  );

  useEffect(() => {
    analytics.logScreenView_SubscriptionPlans();
  }, []);

  return (
    <Dialog
      viewModel={viewModel}
      isOpen={isOpen}
      title={strings.title()}
      width="md"
      renderData={() => <SubscriptionsProductsView sx={{ width: '100%' }} viewModel={viewModel} />}
    />
  );
});
