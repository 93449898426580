import { useActivePlannerId, useNavigateAsync, usePlannerUserHasAccess, useServices, useViewModel } from '@/hooks';
import { AccessKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/access_kind_pb';
import { AddRounded, SyncRounded, VisibilityOffRounded, VisibilityRounded } from '@mui/icons-material';
import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router';
import LocalizedStrings from 'strings';
import {
  CourseSectionEditDialog,
  CourseSectionList,
  SettingsCard,
  SettingsCardAction,
  SettingsContent
} from '../../shared';
import { UpdatablePresenter } from '../../utils';

interface LocationState {
  courseSectionListEditCourseId?: string;
  personalCourseSectionListAdd?: boolean;
}

export interface PlannerCourseSectionsProps {
  sx?: SxProps;
  className?: string;
}

export const PlannerCourseSections = observer(({ sx = [], className }: PlannerCourseSectionsProps) => {
  const { connectedApps } = useServices();
  const plannerId = useActivePlannerId();

  const personalCoursesViewModel = useViewModel(
    (viewModels) => viewModels.createPlannerPersonalCourseSectionListViewModel(plannerId),
    [plannerId]
  );

  const externalCoursesViewModel = useViewModel(
    (viewModels) => viewModels.createPlannerExternalCourseSectionListViewModel(plannerId),
    [plannerId]
  );

  const hasAccess = usePlannerUserHasAccess([AccessKind.FULL_ACCESS]);
  const strings = LocalizedStrings.settings.planner.classes;

  const location = useLocation();
  const state = (location.state ?? {}) as LocationState;
  const navigate = useNavigateAsync();

  function editCourseSection(id: string) {
    const newState: LocationState = { ...state, courseSectionListEditCourseId: id };
    void navigate(location, { state: newState });
  }

  function showAddCourse() {
    const newState: LocationState = { ...state, personalCourseSectionListAdd: true };
    return navigate(location, { state: newState });
  }

  const hasPersonalCourses = personalCoursesViewModel.courseSections.length > 0;
  const hasExternalCourses = externalCoursesViewModel.courseSections.length > 0;

  const personalActions: SettingsCardAction[] | undefined = hasAccess
    ? _.compact([
        hasPersonalCourses
          ? {
              icon: <VisibilityOffRounded />,
              action: () => personalCoursesViewModel.hideAll(),
              disabled: !personalCoursesViewModel.canHideAll,
              showLoading: true
            }
          : undefined,
        hasPersonalCourses
          ? {
              icon: <VisibilityRounded />,
              action: () => personalCoursesViewModel.showAll(),
              disabled: !personalCoursesViewModel.canShowAll,
              showLoading: true
            }
          : undefined,
        { icon: <AddRounded />, action: showAddCourse }
      ])
    : undefined;

  const externalActions: SettingsCardAction[] = _.compact([
    hasExternalCourses
      ? {
          icon: <VisibilityOffRounded />,
          action: () => externalCoursesViewModel.hideAll(),
          disabled: !externalCoursesViewModel.canHideAll,
          showLoading: true
        }
      : undefined,
    hasExternalCourses
      ? {
          icon: <VisibilityRounded />,
          action: () => externalCoursesViewModel.showAll(),
          disabled: !externalCoursesViewModel.canShowAll,
          showLoading: true
        }
      : undefined,
    {
      icon: <SyncRounded />,
      action: async () => connectedApps.sync(plannerId),
      showLoading: true
    }
  ]);

  return (
    <SettingsContent sx={sx} className={className}>
      <Box>
        <SettingsCard
          title={strings.personalTitle()}
          subtitle={strings.personalSubtitle()}
          canBeCollapsed
          actions={personalActions}
        >
          <UpdatablePresenter
            viewModel={personalCoursesViewModel}
            renderData={() => (
              <CourseSectionList
                sx={{ height: '100%', width: '100%' }}
                viewModel={personalCoursesViewModel}
                editCourse={editCourseSection}
                canManageConnectedApps={false}
              />
            )}
          />

          {state.courseSectionListEditCourseId != null && (
            <CourseSectionEditDialog isOpen={true} courseSectionId={state.courseSectionListEditCourseId} />
          )}
        </SettingsCard>

        <SettingsCard
          sx={{ mt: 2 }}
          title={strings.externalTitle()}
          subtitle={strings.externalSubtitle()}
          canBeCollapsed
          actions={externalActions}
        >
          <UpdatablePresenter
            viewModel={externalCoursesViewModel}
            renderData={() => (
              <CourseSectionList
                sx={{ height: '100%', width: '100%' }}
                viewModel={externalCoursesViewModel}
                editCourse={editCourseSection}
                canManageConnectedApps
              />
            )}
          />

          {state.courseSectionListEditCourseId != null && (
            <CourseSectionEditDialog isOpen={true} courseSectionId={state.courseSectionListEditCourseId} />
          )}

          {state.personalCourseSectionListAdd === true && (
            <CourseSectionEditDialog isOpen={true} courseSectionId={undefined} />
          )}
        </SettingsCard>
      </Box>

      {state.personalCourseSectionListAdd === true && (
        <CourseSectionEditDialog isOpen={true} courseSectionId={undefined} />
      )}
    </SettingsContent>
  );
});
