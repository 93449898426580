import { useServices } from '@/hooks';
import { AttachmentInfo } from '@/models';
import { css } from '@emotion/css';
import { Box, Button, Grid2, Grid2Props, styled, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import { MediaQuery, Subheader } from '../utils';
import { WorkAttachment } from '../work';
import { WorkAttachmentEmptyList } from './WorkAttachmentEmptyList';

const itemSpacing = 2;

export interface WorkAttachmentListProps {
  /**
   * The attachments to display.
   */
  attachments: AttachmentInfo[];
  className?: string;
  sx?: SxProps;
}

export const WorkAttachmentList = observer(({ attachments, className, sx }: WorkAttachmentListProps) => {
  const { localization } = useServices();
  const strings = localization.localizedStrings.work.details;
  const theme = useTheme();
  const [showMore, setShowMore] = useState(false);

  const heightStyle = useMemo(
    () =>
      css({
        [theme.breakpoints.up('sm')]: {
          // Displaying a small portion of another row so that user knows there is more.
          maxHeight: showMore
            ? undefined
            : `calc(${numberOfRows * itemHeight}px + ${theme.spacing(itemSpacing)} * ${numberOfRows} + ${
                itemHeight * 0.2
              }px)`
        }
      }),
    [showMore]
  );

  return (
    <Box className={className} sx={sx}>
      <Subheader>{strings.attachmentsSectionTitle}</Subheader>

      <ContainerGrid container spacing={itemSpacing} alignItems="stretch" className={heightStyle}>
        {attachments.length === 0 ? (
          <WorkAttachmentEmptyList />
        ) : (
          attachments.map((attachment, i) => (
            <Grid2 size={{ xs: 12, sm: 6 }} key={`attachment-item-${i}`}>
              <WorkAttachment attachment={attachment} />
            </Grid2>
          ))
        )}
      </ContainerGrid>
      <MediaQuery mediaQuery={(th) => th.breakpoints.up('sm')}>
        {attachments.length > 4 && (
          <Button size="small" onClick={() => setShowMore(!showMore)}>
            {showMore ? strings.attachmentsShowLess : strings.attachmentsShowMore}
          </Button>
        )}
      </MediaQuery>
    </Box>
  );
});

const numberOfRows = 2;
const itemHeight = 72;

const ContainerGrid = styled((props: Grid2Props) => <Grid2 {...props} />)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    overflow: 'hidden',
    // Without this, the cards bottom shadow will be hidden
    paddingBottom: theme.spacing(2)
  }
}));
