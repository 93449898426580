import { AdminSchoolAddAdminDialog } from '@/components/admin/general/AdminSchoolAddAdminDialog';
import { AddRounded } from '@mui/icons-material';
import { Box, Grid2, alpha, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useLocation, useParams } from 'react-router';
import LocalizedStrings from 'strings';
import { useNavigateAsync, useServices } from '../../../hooks';
import { isAdminOfSharedSchool } from '../../../models';
import { SchoolEdit } from '../../school';
import { AdminContentCard, AdminContentCardAction } from '../shared';
import { AdminManageSchoolActions } from './AdminManageSchoolActions';
import { AdminSchoolAdminUsersList } from './AdminSchoolAdminUsersList';
import { AdminSchoolParticipationCodesList } from './AdminSchoolParticipationCodesList';

interface LocationState {
  isAddAdminDialogOpen?: boolean;
}

export interface AdminSchoolGeneralInformationProps {
  sx?: SxProps;
  className?: string;
}

export const AdminSchoolGeneralInformation = observer(({ sx = [], className }: AdminSchoolGeneralInformationProps) => {
  const { userStore } = useServices();
  const params = useParams();
  const schoolId = params.schoolId ?? '';
  const strings = LocalizedStrings.admin.general;
  const theme = useTheme();
  const school = userStore.getSchoolForId(schoolId);
  const adminIds = school?.administrators.map((u) => u.userId) ?? [];

  const location = useLocation();
  const state = (location.state ?? {}) as LocationState;
  const navigate = useNavigateAsync();

  function showAddAdminDialog() {
    const newState: LocationState = { ...state, isAddAdminDialogOpen: true };
    void navigate(location, { state: newState });
  }

  const canManageSchool = school != null && isAdminOfSharedSchool(userStore.user.userId, school);
  const adminListActions: AdminContentCardAction[] = [
    {
      label: { case: 'text', value: strings.addAdminLabel(), icon: <AddRounded /> },
      onPress: () => showAddAdminDialog(),
      isHidden: !canManageSchool
    }
  ];

  return (
    <Grid2 sx={sx} className={className} container spacing={{ xs: 1, sm: 2 }}>
      <Grid2 size={{ xs: 12, md: 6 }}>
        <AdminContentCard title={strings.informationTitle()}>
          <Box
            sx={{
              p: 2,
              pt: 3
            }}
          >
            <SchoolEdit schoolId={schoolId} />
          </Box>
        </AdminContentCard>
      </Grid2>
      <Grid2 size={{ xs: 12, md: 6 }}>
        <AdminContentCard
          title={strings.adminsListTitle()}
          subtitle={strings.adminsListSubtitle()}
          actions={adminListActions}
        >
          <AdminSchoolAdminUsersList schoolId={schoolId} />
        </AdminContentCard>
      </Grid2>
      {canManageSchool && (
        <Grid2 size={{ xs: 12, md: 6 }}>
          <AdminContentCard title={strings.participationCodesTitle()} subtitle={strings.participationCodesSubtitle()}>
            <AdminSchoolParticipationCodesList sx={{ p: 2 }} schoolId={schoolId} />
          </AdminContentCard>
        </Grid2>
      )}
      {canManageSchool && (
        <Grid2 size={{ xs: 12, md: 6 }}>
          <AdminContentCard
            title={strings.manageActions.title()}
            sx={{
              border: `1px solid ${alpha(theme.palette.error.main, theme.palette.mode === 'dark' ? 0.5 : 1)}`,
              boxShadow: 'none'
            }}
          >
            <AdminManageSchoolActions schoolId={schoolId} />
          </AdminContentCard>
        </Grid2>
      )}
      {state.isAddAdminDialogOpen === true && (
        <AdminSchoolAddAdminDialog isOpen={true} schoolId={schoolId} currentAdminUserIds={adminIds} />
      )}
    </Grid2>
  );
});
