import { EventEmitter } from '@/events';
import { createContext, useContext } from 'react';

export const EventEmitterContext = createContext<EventEmitter | null>(null);

export const useEventEmitter = () => {
  const context = useContext(EventEmitterContext);
  if (!context) {
    throw new Error('useEventEmitter must be used within an EventEmitterProvider');
  }
  return context;
};
