import { ServiceContainer } from '@/providers';
import { ConnectedAppService, ConnectedAppsService } from '@/services';
import { CustomActionEffect } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/custom_action_effect_pb';
import { computed, makeObservable } from 'mobx';
import { PlannerDataStore } from '../../stores';
import { AppBaseOnboardingScreenViewModel, BaseOnboardingScreenViewModel } from './BaseOnboardingScreenViewModel';
import { OnboardingViewModel } from './OnboardingViewModel';

export interface OnboardingClassroomConnectionStepViewModel extends BaseOnboardingScreenViewModel {
  readonly connectedApp: ConnectedAppService<string>;
  readonly hasSchool: boolean;
  connect(): Promise<void>;
}

export class AppOnboardingClassroomConnectionStepViewModel
  extends AppBaseOnboardingScreenViewModel
  implements OnboardingClassroomConnectionStepViewModel
{
  constructor(
    onboardingState: OnboardingViewModel,
    private readonly _connectedApps: ConnectedAppsService = ServiceContainer.services.connectedApps,
    private readonly _plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore
  ) {
    super(onboardingState);
    makeObservable(this);

    void this.connectedApp.refreshSyncStatus();
  }

  readonly canSubmit = true;

  @computed
  get connectedApp(): ConnectedAppService<string> {
    return this._connectedApps.getConnectedAppService(
      'classroom',
      this._onboardingState.plannerId!,
      this._onboardingState.school?.id
    )!;
  }

  @computed
  get hasSchool(): boolean {
    return this._onboardingState.school != null;
  }

  async connect(): Promise<void> {
    await this.connectedApp.connect(false);

    // Not waiting for sync to complete.
    void this.connectedApp.sync();

    void this._plannerStore.ensureCustomWorksCompleted(
      this._onboardingState.plannerId!,
      CustomActionEffect.CONNECT_GOOGLE_CLASSROOM
    );

    await this.submit();
  }

  async submit(): Promise<void> {
    await this._onboardingState.complete();
  }
}
