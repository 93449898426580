import i18n from 'translate';

export const PasteboardBannerStrings = {
  clearButtonTitle: () => i18n.t('utils.pasteboardBanner.clearButtonTitle'),
  noteMessage: (title: string) => i18n.t('utils.pasteboardBanner.noteMessage', { title }),
  publishedWorkMessage: (title: string) => i18n.t('utils.pasteboardBanner.publishedWorkMessage', { title }),
  workMessage: (title: string) => i18n.t('utils.pasteboardBanner.workMessage', { title })
};

export const UtilsStrings = {
  formPopoverCancelActionTitle: () => i18n.t('utils.formPopoverCancelActionTitle'),
  formPopoverSaveActionDefaultTitle: () => i18n.t('utils.formPopoverSaveActionDefaultTitle'),
  optionsPopoverCloseButtonTitle: () => i18n.t('utils.optionsPopoverCloseButtonTitle'),
  optionsPopoverResetButtonTitle: () => i18n.t('utils.optionsPopoverResetButtonTitle'),
  pasteboardBanner: PasteboardBannerStrings,
  pasteButtonTooltip: () => i18n.t('utils.pasteButtonTooltip'),
  requiresTodaySchoolsSubscriptionButtonTooltip: () => i18n.t('utils.requiresTodaySchoolsSubscriptionButtonTooltip'),
  richTextViewerExpand: () => i18n.t('utils.richTextViewerExpand'),
  supportSubject: () => i18n.t('utils.supportSubject')
};
