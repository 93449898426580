import { useNavigateAsync, useServices } from '@/hooks';
import { timeOfDayToDate } from '@/models';
import { UserDashboardCalendarDayTimedNoteInfo, UserDashboardCalendarNoteInfo } from '@/viewmodels';
import { CardActionArea, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router';
import LocalizedStrings from 'strings';
import { ColorIndicator, NoteIcon } from '../../../lists';
import { RichTextViewer } from '../../../utils';
import { ContextMenuHandler } from '../../ContextMenuHandler';

function isTimedNote(
  note: UserDashboardCalendarNoteInfo | UserDashboardCalendarDayTimedNoteInfo
): note is UserDashboardCalendarDayTimedNoteInfo {
  return (note as UserDashboardCalendarDayTimedNoteInfo)?.startTime != null;
}

export interface UserDashboardCalendarDayNoteViewProps {
  sx?: SxProps;
  className?: string;
  note: UserDashboardCalendarNoteInfo | UserDashboardCalendarDayTimedNoteInfo;
  showTime: boolean;
}

export const UserDashboardCalendarDayNoteView = observer(
  ({ sx = [], className, note, showTime }: UserDashboardCalendarDayNoteViewProps) => {
    const { navigation, settings } = useServices();
    const location = useLocation();
    const navigate = useNavigateAsync();

    function showNoteDetails() {
      navigation.navigateToNoteEdit(navigate, location, note.id);
    }

    return (
      <ContextMenuHandler actions={note.contextMenuActions()}>
        {(contextMenuHandler) => (
          <CardActionArea
            sx={{
              ...sx,
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'stretch',
              p: 1,
              borderRadius: 1
            }}
            className={className}
            onClick={showNoteDetails}
            onContextMenu={contextMenuHandler}
            component="div"
          >
            <ColorIndicator color={note.color} isRounded sx={{ mr: 1 }} />

            <Stack
              spacing={1}
              direction="row"
              sx={{
                alignItems: 'flex-start',
                flex: 1
              }}
            >
              <NoteIcon size={32} sx={{ mr: 1 }} />

              {settings.calendarDayOptions.expandWorkDetails && note.text != null ? (
                <RichTextViewer
                  id="day-note-view"
                  sx={{ flex: 1, maxHeight: 450 }}
                  label={LocalizedStrings.calendar.day.noteInstructionsRichTextTitle()}
                  content={note.text.richText}
                  canSelectText={false}
                  textSize="small"
                />
              ) : (
                <Typography sx={{ flex: 1 }} variant="body1" noWrap>
                  {note.text?.plainText}
                </Typography>
              )}
            </Stack>

            {showTime && isTimedNote(note) && (
              <Typography
                variant="body2"
                sx={{
                  color: (theme) => theme.palette.text.secondary
                }}
              >
                {format(timeOfDayToDate(note.startTime), 'p')}
              </Typography>
            )}
          </CardActionArea>
        )}
      </ContextMenuHandler>
    );
  }
);
