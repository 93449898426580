import {
  UserDashboardCalendarItemState,
  UserDashboardCalendarPublishedWorkInfo,
  UserDashboardCalendarWorkInfo
} from '@/viewmodels';
import { Box, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { isSxArray } from '../../../../../utils';
import { ColorIndicator, WorkIcon } from '../../../../lists';
import { ContextMenuHandler } from '../../../ContextMenuHandler';

export interface UserDashboardCalendarWeekPeriodWorkProps {
  sx?: SxProps;
  className?: string;
  work: UserDashboardCalendarWorkInfo | UserDashboardCalendarPublishedWorkInfo;
}

export const UserDashboardCalendarWeekPeriodWork = observer(
  ({ sx = [], className, work }: UserDashboardCalendarWeekPeriodWorkProps) => {
    const hasDescription = (work.description?.plainText ?? '').length > 0;
    const colorIndicatorPadding = hasDescription ? 0.5 : '2px';

    return (
      <ContextMenuHandler actions={work.contextMenuActions()}>
        {(contextMenuHandler) => (
          <Stack
            className={className}
            direction="row"
            spacing={0.5}
            onContextMenu={contextMenuHandler}
            sx={[
              {
                alignItems: hasDescription ? 'stretch' : 'center',
                overflow: 'hidden'
              },
              ...(isSxArray(sx) ? sx : [sx])
            ]}
          >
            <Box sx={{ height: '100%', py: colorIndicatorPadding }}>
              <ColorIndicator isRounded color={work.color} sx={{ height: '100%' }} />
            </Box>

            <WorkIcon icon={work.icon} size={14} sx={{ py: hasDescription ? colorIndicatorPadding : undefined }} />

            <Stack
              spacing={-0.5}
              sx={{
                flex: 1,
                overflow: 'hidden'
              }}
            >
              <Typography
                noWrap
                color={getColorForTitle(work.state)}
                sx={{
                  fontSize: 12,
                  fontWeight: '500'
                }}
              >
                {work.title}
              </Typography>

              {hasDescription && (
                <Typography
                  noWrap
                  sx={{
                    fontSize: 10
                  }}
                >
                  {work.description?.plainText}
                </Typography>
              )}
            </Stack>
          </Stack>
        )}
      </ContextMenuHandler>
    );
  }
);

function getColorForTitle(state: UserDashboardCalendarItemState) {
  switch (state) {
    case 'completed':
      return 'textSecondary';
    case 'late':
      return 'error';
    default:
      return 'textPrimary';
  }
}
