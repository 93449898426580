import { PlannerRelationshipKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_relationship_kind_pb';
import { SchoolSharingMode } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/school_sharing_mode_pb';
import { captureException } from '@sentry/react';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { ServiceContainer } from '../../providers';
import { AppBaseOnboardingScreenViewModel, BaseOnboardingScreenViewModel } from './BaseOnboardingScreenViewModel';
import { OnboardingViewModel } from './OnboardingViewModel';

export interface OnboardingCreateSchoolViewModel extends BaseOnboardingScreenViewModel {
  readonly error?: string;
  schoolName: string;
  goBack(): void;
}

export class AppOnboardingCreateSchoolViewModel
  extends AppBaseOnboardingScreenViewModel
  implements OnboardingCreateSchoolViewModel
{
  @observable private _schoolName = '';
  @observable private _error: string | undefined;

  constructor(
    onboardingState: OnboardingViewModel,
    private readonly _userStore = ServiceContainer.services.userStore
  ) {
    super(onboardingState);
    makeObservable(this);
  }

  @computed
  get error(): string | undefined {
    return this._error;
  }

  @computed
  get schoolName() {
    return this._schoolName;
  }

  set schoolName(newValue: string) {
    this._schoolName = newValue;
  }

  @computed
  get canSubmit() {
    return this._schoolName.trim().length > 0;
  }

  @action
  async submit(): Promise<void> {
    this._isSubmitting = true;
    this._error = undefined;

    try {
      const plannerId = await this._userStore.createPlanner(PlannerRelationshipKind.INDIVIDUAL, undefined, false);
      this._onboardingState.plannerId = plannerId;

      const { schoolId } = await this._userStore.createSchool(
        this.schoolName,
        undefined,
        SchoolSharingMode.SHARED,
        false,
        plannerId
      );
      this._onboardingState.school = { id: schoolId, wasCreatedByUser: true };
      this._onboardingState.displayScreenOfKind('classroom-connection');
    } catch (e) {
      captureException(e);
      const error = e as Error;
      runInAction(() => (this._error = error.message));
    } finally {
      runInAction(() => (this._isSubmitting = false));
    }
  }

  goBack() {
    this._onboardingState.goBack();
  }
}
