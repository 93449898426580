import { useBackButtonListener, useServices } from '@/hooks';
import { WorkPlannedWorkStepsEditViewModel } from '@/viewmodels';
import { CheckRounded } from '@mui/icons-material';
import { List, ListItemButton, ListItemText, Popover, PopoverOrigin, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { captureException } from '@sentry/react';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { FormPopoverActions, FormPopoverHeader } from '../../utils';

export interface WorkDetailsPlannedWorkLinkStepsPopoverProps {
  sx?: SxProps;
  className?: string;
  viewModel: WorkPlannedWorkStepsEditViewModel;
  open: boolean;
  anchorEl?: HTMLElement;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  onClose: () => void;
}

export const WorkDetailsPlannedWorkLinkStepsPopover = observer(
  ({
    sx = [],
    className,
    viewModel,
    open,
    anchorEl,
    anchorOrigin,
    transformOrigin,
    onClose
  }: WorkDetailsPlannedWorkLinkStepsPopoverProps) => {
    const { localization } = useServices();
    const strings = localization.localizedStrings.work.details;
    const [saveError, setSaveError] = useState<Error | undefined>(undefined);

    async function save() {
      setSaveError(undefined);
      try {
        await viewModel.save();
        onClose();
      } catch (e) {
        setSaveError(e as Error);
        captureException(e);
      }
    }

    function cancel() {
      onClose();
      return Promise.resolve();
    }

    useBackButtonListener((e) => {
      if (open) {
        e.stopPropagation();
        void cancel();
      }
    });

    return (
      <Popover
        sx={sx}
        className={className}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        onClose={onClose}
        slotProps={{ paper: { sx: { width: 320, overflowY: 'hidden' } } }}
        elevation={2}
      >
        <Stack
          sx={{
            overflow: 'hidden',
            height: '100%'
          }}
        >
          <FormPopoverHeader title={strings.plannedWorkLinkStepsTitle} />

          <List dense sx={{ flex: 1, overflowY: 'auto' }}>
            {viewModel.steps.map((step) => (
              <ListItemButton key={step.id} onClick={() => viewModel?.toggleSelectionForStepId(step.id)}>
                <ListItemText
                  primary={step.title}
                  secondary={step.duration > 0 ? `${step.duration} min` : undefined}
                  sx={{ my: step.duration > 0 ? '2px' : undefined }}
                  slotProps={{
                    primary: {
                      noWrap: true,
                      color: step.isCompleted ? 'textSecondary' : 'textPrimary',
                      sx: { textDecoration: step.isCompleted ? 'line-through' : undefined }
                    },
                    secondary: { variant: 'caption' }
                  }}
                />

                {viewModel.selectedStepIds.includes(step.id) && (
                  <CheckRounded sx={{ ml: 1 }} color="primary" fontSize="small" />
                )}
              </ListItemButton>
            ))}
          </List>

          {saveError && (
            <Typography
              variant="caption"
              sx={{
                fontWeight: '500',
                color: (theme) => theme.palette.error.main,
                p: 1,
                textAlign: 'center'
              }}
            >
              {strings.plannedWorkLinkStepsErrorMessage}
            </Typography>
          )}
          <FormPopoverActions onSubmit={save} canSubmit customSubmitIcon={<CheckRounded />} onCancel={cancel} />
        </Stack>
      </Popover>
    );
  }
);
