import { Breadcrumbs, Link, Toolbar, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { Link as RouterLink, useLocation } from 'react-router';
import LocalizedStrings from 'strings';
import { useServices } from '../../../hooks';

export interface AdminBreadcrumbsProps {
  sx?: SxProps;
  className?: string;
  schoolId: string;
}

export const AdminBreadcrumbs = observer(({ sx = [], className, schoolId }: AdminBreadcrumbsProps) => {
  const { route } = useServices();
  const theme = useTheme();

  const routeStrings = LocalizedStrings.admin.routeNames;
  const location = useLocation();
  const pathNames = location.pathname.split('/').filter((x) => x);

  return (
    <Toolbar
      sx={{
        ...sx,
        height: 50,
        borderBottom: `1px solid ${theme.palette.divider}`,
        backgroundColor: theme.palette.background.default
      }}
      className={className}
      variant="dense"
    >
      <Breadcrumbs maxItems={5}>
        <Link
          variant="body2"
          component={RouterLink}
          to={route.resolveAdminSchoolInformationLocation(schoolId)}
          underline="hover"
          sx={{
            color: theme.palette.text.secondary
          }}
        >
          {LocalizedStrings.admin.breadcrumbsHome()}
        </Link>

        {pathNames
          .map((_, index) => `/${pathNames.slice(0, index + 1).join('/')}`)

          .map((pathname, index, items) => {
            const isLast = index === items.length - 1;
            const routeName = route.getLocationName(pathname, { ...routeStrings });

            if (routeName == null) {
              return null;
            }

            return isLast ? (
              <Typography
                variant="body2"
                key={pathname}
                sx={{
                  color: theme.palette.primary.main,
                  fontWeight: '500'
                }}
              >
                {routeName}
              </Typography>
            ) : (
              <Link
                variant="body2"
                component={RouterLink}
                to={{ ...location, pathname: pathname }}
                key={pathname}
                underline="hover"
                sx={{
                  color: theme.palette.text.secondary
                }}
              >
                {routeName}
              </Link>
            );
          })}
      </Breadcrumbs>
    </Toolbar>
  );
});
