import { ParentOnboardingOptionsViewModel } from '@/viewmodels';
import { Box, Button, CircularProgress, Stack, SxProps, Typography } from '@mui/material';
import { captureException } from '@sentry/react';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import LocalizedStrings from 'strings';
import { NavigationButton } from '../utils';

export interface ParentOnboardingOptionsProps {
  sx?: SxProps;
  className?: string;
  viewModel: ParentOnboardingOptionsViewModel;
}

export const ParentOnboardingOptions = observer(({ sx, className, viewModel }: ParentOnboardingOptionsProps) => {
  const [isCreatingPlanner, setIsCreatingPlanner] = useState<boolean>(false);
  const [hasCreatePlannerError, setHasCreatePlannerError] = useState(false);
  const strings = LocalizedStrings.onboarding.parentOptions;

  const onCreatePlannerOptionPress = async () => {
    try {
      setHasCreatePlannerError(false);
      setIsCreatingPlanner(true);
      await viewModel.createPlanner();
    } catch (e) {
      captureException(e);
      setHasCreatePlannerError(true);
    } finally {
      setIsCreatingPlanner(false);
    }
  };

  return (
    <Box sx={sx} className={className} width="100%" display="flex" flexDirection="column">
      <Stack spacing={2}>
        <NavigationButton
          title={strings.createPlannerTitle()}
          subtitle={strings.createPlannerSubtitle()}
          onClick={() => void onCreatePlannerOptionPress()}
          disabled={isCreatingPlanner}
        />

        {isCreatingPlanner && (
          <Stack direction="row" spacing={1} alignItems="center" px={2}>
            <CircularProgress size={16} />
            <Typography variant="caption" color="textSecondary">
              {strings.creatingPlanner()}
            </Typography>
          </Stack>
        )}

        {hasCreatePlannerError && (
          <Typography variant="caption" color="error">
            {strings.createPlannerError()}
          </Typography>
        )}

        <NavigationButton
          title={strings.useCodeTitle()}
          subtitle={strings.useCodeSubtitle()}
          onClick={() => viewModel.useCode()}
          disabled={isCreatingPlanner}
        />

        <Button onClick={() => void viewModel.signOut()} disabled={isCreatingPlanner}>
          {strings.signOut()}
        </Button>
      </Stack>
    </Box>
  );
});
