import { useSync, useViewModel } from '@/hooks';
import { Box, Divider, Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';
import { isSxArray } from '../../../../utils';
import { UpdatablePresenter } from '../../../utils';
import { AdminAccountClassesList } from '../../classes';
import { AdminAccountDetailsHeader } from '../../shared';

export interface AdminSchoolStudentDetailsPageProps {
  sx?: SxProps;
  className?: string;
}

export const AdminSchoolStudentDetailsPage = observer(({ sx = [], className }: AdminSchoolStudentDetailsPageProps) => {
  const params = useParams();
  const schoolId = params.schoolId ?? '';
  const studentId = params.studentId ?? '';

  const viewModel = useViewModel(
    (viewModels) => viewModels.createAdminStudentDetailsViewModel(studentId, schoolId),
    [studentId, schoolId]
  );

  useSync('admin-student-details', () => viewModel.reloadData(), [viewModel]);

  return (
    <Box
      className={className}
      sx={[
        {
          overflow: 'hidden'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <UpdatablePresenter
        sx={{ height: '100%', overflow: 'hidden' }}
        viewModel={viewModel}
        renderData={() => (
          <Stack sx={{ width: '100%', height: '100%', overflow: 'hidden' }}>
            <AdminAccountDetailsHeader account={viewModel.account} sx={{ flexShrink: 0 }} />
            <Divider />

            <Stack
              sx={{
                overflow: 'auto',
                p: 2
              }}
            >
              {viewModel.courseSections.teacher.length > 0 && (
                <AdminAccountClassesList
                  schoolId={schoolId}
                  accountId={studentId}
                  sx={{ flexShrink: 0 }}
                  accountKind="teacher"
                />
              )}

              {viewModel.courseSections.student.length > 0 && (
                <AdminAccountClassesList
                  schoolId={schoolId}
                  accountId={studentId}
                  sx={{ flexShrink: 0 }}
                  accountKind="student"
                />
              )}
            </Stack>
          </Stack>
        )}
      />
    </Box>
  );
});
