import { useRoutes } from 'react-router';
import { RouteTemplates } from '../RouteTemplates';
import { PublishedWorkDetailsDialog, SubscriptionsDialog, WorkDetailsDialog } from '../components';

export function DialogsRoutes() {
  return useRoutes([
    { path: RouteTemplates.plannerWork, element: <WorkDetailsDialog isOpen /> },
    { path: RouteTemplates.publishedWork, element: <PublishedWorkDetailsDialog isOpen /> },
    { path: RouteTemplates.plannerSubscribe, element: <SubscriptionsDialog isOpen /> }
  ]);
}
