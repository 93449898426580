import { Typography, TypographyVariant } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { isSxArray } from '../../../utils';
import { RichTextViewerTextSize } from './RichTextViewerUtils';

export interface RichTextViewerPlainTextProps {
  sx?: SxProps;
  className?: string;
  content: string | undefined;
  textSize?: RichTextViewerTextSize;
  canSelectText?: boolean;
}

export const RichTextViewerPlainText = observer(
  ({ sx = [], className, content, textSize, canSelectText }: RichTextViewerPlainTextProps) => {
    const textVariant = useMemo<TypographyVariant>(() => {
      switch (textSize) {
        case 'small':
          return 'body2';
        default:
          return 'body1';
      }
    }, [textSize]);

    return (
      <Typography
        sx={[
          {
            userSelect: canSelectText === false ? 'none' : undefined
          },
          ...(isSxArray(sx) ? sx : [sx])
        ]}
        className={className}
        variant={textVariant}
      >
        {content}
      </Typography>
    );
  }
);
