import { RouteTemplates } from '@/RouteTemplates';
import { useActivePlannerId, useIsMatchPath, useServices } from '@/hooks';
import { localizedExternalSourceName } from '@/resources/strings/utils/ExternalSourceStringsUtils';
import { SchoolCourseSectionDetailsViewModel } from '@/viewmodels';
import MoreVert from '@mui/icons-material/MoreVertRounded';
import OpenInExternalSourceIcon from '@mui/icons-material/OpenInNewRounded';
import ColumnViewIcon from '@mui/icons-material/TableChartRounded';
import ListViewIcon from '@mui/icons-material/ViewListRounded';
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import { Link } from 'react-router';
import { ColorIndicator } from '../../../lists';
import { ActionMenu, MediaQuery, SearchBar } from '../../../utils';

export interface SchoolCourseSectionDetailsHeaderProps {
  sx?: SxProps;
  className?: string;
  viewModel: SchoolCourseSectionDetailsViewModel;
  schoolId: string;
  courseSectionId: string;
}

export const SchoolCourseSectionDetailsHeader = observer(
  ({ sx = [], className, viewModel, courseSectionId, schoolId }: SchoolCourseSectionDetailsHeaderProps) => {
    const { localization, route, settings } = useServices();
    const plannerId = useActivePlannerId();
    const strings = localization.localizedStrings.schoolCourseSection;
    const theme = useTheme();
    const searchBarBreakpoint = settings.isSchoolSidebarCollapsed ? 'md' : 'lg';
    const isSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));

    const coursePath = RouteTemplates.schoolCourseSectionDetails;
    const isColumn = useIsMatchPath({ path: coursePath, end: false });
    const listPath = RouteTemplates.schoolCourseSectionDetailsStudentList;
    const isList = useIsMatchPath({ path: listPath, end: false });

    const [showOptionMenu, setShowOptionMenu] = useState(false);
    const actionMenuButtonRef = useRef<HTMLButtonElement>(null);

    const secondaryText = viewModel.isDemo ? strings.detailsDemoCourseSubtitle : viewModel.section;

    return (
      <Stack direction="column" spacing={2} sx={sx} className={className}>
        <Stack
          direction="row"
          spacing={1}
          sx={{
            alignItems: { xs: 'center', sm: 'flex-start' },
            p: 2,
            pb: 0
          }}
        >
          <Stack
            direction="row"
            spacing={1.5}
            sx={{
              alignItems: 'center',
              flex: 1
            }}
          >
            <ColorIndicator color={viewModel.color} sx={{ height: { xs: '2.5em', sm: '3em' } }} />

            <Stack>
              <Typography
                variant="h6"
                sx={{ [theme.breakpoints.only('xs')]: { fontSize: theme.typography.body1.fontSize } }}
              >
                {viewModel.title}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  color: theme.palette.text.secondary,
                  [theme.breakpoints.only('xs')]: { fontSize: theme.typography.caption.fontSize }
                }}
              >
                {secondaryText}
              </Typography>
            </Stack>
          </Stack>

          <Stack
            direction="row"
            spacing={1}
            sx={{
              alignItems: 'center'
            }}
          >
            {viewModel.studentsPlannerSummary.allStudents.length > 0 && (
              <MediaQuery mediaQuery={(th) => th.breakpoints.up(searchBarBreakpoint)}>
                <SearchBar
                  placeholder={strings.detailsStudentSearchBarPlaceholder}
                  onSearch={(text) => (viewModel.studentsPlannerSummary.searchText = text ?? '')}
                />
              </MediaQuery>
            )}

            <Stack
              direction="row"
              spacing={0}
              sx={{
                alignItems: 'center'
              }}
            >
              {!isSmallScreen && (
                <>
                  <IconButton
                    color={isColumn && !isList ? 'primary' : 'inherit'}
                    component={Link}
                    to={route.resolveSchoolCourseSectionDetailsLocation(plannerId, schoolId, courseSectionId)}
                  >
                    <ColumnViewIcon />
                  </IconButton>

                  <IconButton
                    color={isList ? 'primary' : 'inherit'}
                    component={Link}
                    to={route.resolveSchoolCourseSectionDetailsStudentListLocation(
                      plannerId,
                      schoolId,
                      courseSectionId
                    )}
                  >
                    <ListViewIcon />
                  </IconButton>
                </>
              )}

              {(viewModel.externalSource != null || isSmallScreen) && (
                <>
                  <IconButton ref={actionMenuButtonRef} color={'inherit'} onClick={() => setShowOptionMenu(true)}>
                    <MoreVert />
                  </IconButton>

                  <ActionMenu
                    anchorEl={actionMenuButtonRef.current}
                    open={showOptionMenu}
                    onClose={() => setShowOptionMenu(false)}
                  >
                    {isSmallScreen && [
                      <MenuItem
                        key={'school-course-section-column-menu-item'}
                        selected={isColumn && !isList}
                        component={Link}
                        to={route.resolveSchoolCourseSectionDetailsLocation(plannerId, schoolId, courseSectionId)}
                        onClick={() => setShowOptionMenu(false)}
                      >
                        <ListItemIcon>
                          <ColumnViewIcon />
                        </ListItemIcon>

                        <ListItemText>{strings.detailsMenuShowColumns}</ListItemText>
                      </MenuItem>,

                      <MenuItem
                        key={'school-course-section-list-menu-item'}
                        selected={isList}
                        component={Link}
                        to={route.resolveSchoolCourseSectionDetailsStudentListLocation(
                          plannerId,
                          schoolId,
                          courseSectionId
                        )}
                        onClick={() => setShowOptionMenu(false)}
                      >
                        <ListItemIcon>
                          <ListViewIcon />
                        </ListItemIcon>

                        <ListItemText>{strings.detailsMenuShowList}</ListItemText>
                      </MenuItem>
                    ]}

                    {viewModel.externalSource != null && (
                      <MenuItem component="a" href={viewModel.externalSource.url} target="_blank" rel="noreferrer">
                        <ListItemIcon>
                          <OpenInExternalSourceIcon />
                        </ListItemIcon>
                        <ListItemText>
                          {`${strings.detailsOpenCourseInExternalSource} ${localizedExternalSourceName(
                            viewModel.externalSource.sourceName
                          )}`}
                        </ListItemText>
                      </MenuItem>
                    )}
                  </ActionMenu>
                </>
              )}
            </Stack>
          </Stack>
        </Stack>
        {viewModel.studentsPlannerSummary.allStudents.length > 0 && (
          <MediaQuery mediaQuery={(th) => th.breakpoints.down(searchBarBreakpoint)}>
            {!isSmallScreen && (
              <Box sx={{ px: 2 }}>
                <SearchBar
                  placeholder={strings.detailsStudentSearchBarPlaceholder}
                  onSearch={(text) => (viewModel.studentsPlannerSummary.searchText = text ?? '')}
                />
              </Box>
            )}
          </MediaQuery>
        )}
      </Stack>
    );
  }
);
