import { AllFulfilledImportanceLevel } from '@/models';
import { ImportanceLevel } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/importance_level_pb';
import { css } from '@emotion/css';
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlagsRounded';
import FlagIcon from '@mui/icons-material/FlagRounded';
import { FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { yellow } from '@mui/material/colors';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';
import LocalizedStrings from 'strings';
import { ControlledSelect } from '../../utils';

export interface WorkImportanceLevelPickerProps {
  className?: string;

  /**
   * The selected importance level.
   */
  value: ImportanceLevel;

  /*
  The label. Optional.
   */
  label?: string;

  /**
   * Indicates whether to disable the picker. Optional. Default is `false`.
   */
  disabled?: boolean;

  /**
   * Callback when the selected importance level changes.
   * @param value The new selected importance level.
   */
  onChange: (value: ImportanceLevel) => void;

  /**
   * Elevation for the select menu.
   */
  elevation?: number;
}

export const WorkImportanceLevelPicker = observer(
  ({ className, value, label, disabled, onChange, elevation }: WorkImportanceLevelPickerProps) => {
    return (
      <FormControl className={className} sx={{ width: '100%' }} disabled={disabled} size="small">
        <InputLabel id="work-importance-level-picker-label">{label}</InputLabel>
        <ControlledSelect
          classes={{ select: selected }}
          value={value}
          label={label}
          labelId="work-importance-level-picker-label"
          MenuProps={{ elevation }}
          onChange={(event) => onChange(event.target.value as ImportanceLevel)}
        >
          {AllFulfilledImportanceLevel.map((level, i) => (
            <MenuItem key={`work-importance-menu-item-${i}`} value={level}>
              <ListItemIcon>{getIconForImportanceLevel(level)}</ListItemIcon>
              <ListItemText>{labelForImportanceLevel(level)}</ListItemText>
            </MenuItem>
          ))}
        </ControlledSelect>
      </FormControl>
    );
  }
);

function getIconForImportanceLevel(level: ImportanceLevel): ReactNode {
  switch (level) {
    case ImportanceLevel.REMINDER:
      return <EmojiFlagsIcon color="disabled" />;
    case ImportanceLevel.REGULAR:
      return <FlagIcon />;
    case ImportanceLevel.MAJOR:
      return <FlagIcon sx={{ color: yellow[500] }} />;
    case ImportanceLevel.CRITICAL:
      return <FlagIcon color="error" />;
    case ImportanceLevel.UNSPECIFIED:
      return null;
  }
}

function labelForImportanceLevel(level: ImportanceLevel): string {
  const strings = LocalizedStrings.work.edit;

  switch (level) {
    case ImportanceLevel.REMINDER:
      return strings.importanceReminderTitle();
    case ImportanceLevel.REGULAR:
      return strings.importanceRegularTitle();
    case ImportanceLevel.MAJOR:
      return strings.importanceMajorTitle();
    case ImportanceLevel.CRITICAL:
      return strings.importanceCriticalTitle();
    case ImportanceLevel.UNSPECIFIED:
      return '';
  }
}

// If we don't specify "!important" for the display property, the value is not respected.
const selected = css([{ display: 'flex !important', alignItems: 'center', flexDirection: 'row' }]);
