import { useServices } from '@/hooks';
import { useMediaQuery, useTheme } from '@mui/material';
import { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router';

export interface ScreenSizeListenerProps {
  children: ReactNode;
}

export function ScreenSizeListener({ children }: ScreenSizeListenerProps) {
  const { route, intercom } = useServices();
  const theme = useTheme();
  const location = useLocation();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (!isSmallScreen && route.getLocationShouldDisplayIntercomMessenger(location)) {
      intercom.show();
    } else {
      intercom.hide();
    }
  }, [location, isSmallScreen]);

  return <>{children}</>;
}
