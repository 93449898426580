import { Day, dayToDate } from '@/models';
import { Term } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/term_pb';
import { DeleteRounded, EditRounded } from '@mui/icons-material';
import { IconButton, ListItem, ListItemText, Popover } from '@mui/material';
import { SxProps } from '@mui/system';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useBackButtonListener } from '../../../../../hooks';
import { ScheduleCycleTermEdit } from './ScheduleCycleTermEdit';

export interface ScheduleCycleTermsListProps {
  sx?: SxProps;
  className?: string;
  term: Term;
  minDate: Date;
  maxDate: Date;
  onEdit: (id: string | undefined, name: string, startDay: Day, endDay: Day) => void;
  onDelete: (id: string) => void;
  isReadOnly?: boolean;
}

export const ScheduleCycleTermsListItem = observer(
  ({ sx = [], className, term, onEdit, onDelete, minDate, maxDate, isReadOnly }: ScheduleCycleTermsListProps) => {
    const [editButtonRef, setEditButtonRef] = useState<HTMLButtonElement | null>(null);

    useBackButtonListener((e) => {
      if (editButtonRef != null) {
        e.stopPropagation();
        setEditButtonRef(null);
      }
    });

    return (
      <>
        <ListItem
          sx={sx}
          className={className}
          secondaryAction={
            isReadOnly !== true ? (
              <>
                <IconButton onClick={(e) => setEditButtonRef(e.currentTarget)} size="small">
                  <EditRounded fontSize="small" />
                </IconButton>

                <IconButton onClick={() => onDelete(term.id)} color="error" size="small">
                  <DeleteRounded fontSize="small" />
                </IconButton>
              </>
            ) : undefined
          }
        >
          <ListItemText
            primary={term.name || undefined}
            secondary={`${format(dayToDate(term.startDay!), 'PP')} - ${format(dayToDate(term.endDay!), 'PP')}`}
          />
        </ListItem>

        {editButtonRef != null && (
          <Popover
            open={true}
            anchorEl={editButtonRef}
            slotProps={{ paper: { sx: { width: 400 } } }}
            onClose={() => setEditButtonRef(null)}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'right'
            }}
          >
            <ScheduleCycleTermEdit
              term={term}
              onSave={(id, name, startDay, endDay) => {
                onEdit(id, name, startDay, endDay);
                setEditButtonRef(null);
              }}
              minDate={minDate}
              maxDate={maxDate}
              dismiss={() => setEditButtonRef(null)}
            />
          </Popover>
        )}
      </>
    );
  }
);
