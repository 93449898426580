import { MoreVert } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { MouseEventHandler, Ref } from 'react';

export interface ActionMenuButtonProps {
  className?: string;
  sx?: SxProps;

  /**
   * Indicates whether to disable the button. Optional. Default is `false`.
   */
  disabled?: boolean;

  buttonColor?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  tooltip?: string;
  buttonSize?: 'small' | 'medium' | 'large';
  iconSize?: 'small' | 'medium' | 'large';
  onClick: MouseEventHandler<HTMLButtonElement>;
  ref?: Ref<HTMLButtonElement>;
}

export const ActionMenuButton = observer(
  ({
    ref,
    className,
    sx = [],
    disabled,
    buttonColor,
    tooltip,
    buttonSize,
    iconSize,
    onClick
  }: ActionMenuButtonProps) => {
    return (
      <Tooltip title={tooltip ?? ''}>
        <IconButton
          color={buttonColor}
          className={className}
          sx={sx}
          ref={ref}
          disabled={disabled}
          size={buttonSize}
          onClick={(e) => onClick(e)}
        >
          <MoreVert color={buttonColor} fontSize={iconSize} />
        </IconButton>
      </Tooltip>
    );
  }
);
