import { useActivePlannerId, useServices } from '@/hooks';
import { ClassroomRoundedIcon, GoogleCalendarIcon } from '@/resources/images/connectedApps';
import {
  StudentsPlannerSummaryTableRowViewModel,
  StudentsPlannerSummaryViewModel,
  getDailyActivityBehaviorTooltip,
  getInboxBehaviorLevel,
  getInboxBehaviorTooltip,
  getStudentEmotionalStatusLevel,
  getStudentEmotionalStatusTooltip
} from '@/viewmodels';
import EmptyListIcon from '@mui/icons-material/EmojiNatureRounded';
import { Avatar, Box, Stack, Tooltip, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { observer } from 'mobx-react-lite';
import { ReactNode, useMemo } from 'react';
import { useParams } from 'react-router';
import { TableRowActions, TableTextCell, TableWithHeader } from '../../../lists';
import { LinkRouter, MultilineTooltipTitle } from '../../../utils';
import { StudentBehaviors } from '../../student';
import { StudentListItem } from './StudentListItem';

export interface StudentsPlannerSummaryTableProps {
  /**
   * The students to display.
   */
  viewModel: StudentsPlannerSummaryViewModel;
  className?: string;
  sx?: SxProps;
}

type RenderCellData = GridRenderCellParams<
  StudentsPlannerSummaryTableRowViewModel,
  StudentsPlannerSummaryTableRowViewModel
>;

export const StudentsPlannerSummaryTable = observer(
  ({ className, viewModel, sx }: StudentsPlannerSummaryTableProps) => {
    const { localization, route, dateService } = useServices();
    const plannerId = useActivePlannerId();
    const strings = localization.localizedStrings.schoolCourseSection;
    const theme = useTheme();
    const params = useParams();
    const schoolId = params.schoolId ?? '';

    const baseStatColumn: Partial<GridColDef> = {
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      minWidth: 80,
      flex: 1
    };

    const columns: GridColDef[] = useMemo(
      () => [
        {
          headerName: '',
          field: 'student',
          minWidth: 200,
          renderCell: (data: RenderCellData) => {
            const student = data.row;
            const studentInsightsLocation = student.isPlannerAccessAuthorized
              ? route.resolveSchoolStudentInsightsLocation(plannerId, schoolId, student.courseSectionId, student.id)
              : student.connectionState === 'connected-no-access'
                ? route.resolveSchoolStudentPlannerLocation(plannerId, schoolId, student.courseSectionId, student.id)
                : undefined;

            const content = () => <StudentListItem viewModel={student} showBehaviors={false} />;

            if (studentInsightsLocation != null) {
              return (
                <LinkRouter to={studentInsightsLocation} underline="hover" color="inherit">
                  {content()}
                </LinkRouter>
              );
            }

            return content();
          },
          flex: 5,
          disableColumnMenu: true,
          sortable: false
        },
        {
          ...baseStatColumn,
          headerName: '',
          field: 'lastConnectionIndicatorLevel',
          align: 'center',
          renderCell: (data: RenderCellData) => {
            if (data.row.connectionState !== 'connected') {
              return <Box />;
            }

            const inboxBehaviorLevel = getInboxBehaviorLevel(data.row.inboxItemsCount);
            const lastEmotionalStatusLevel = getStudentEmotionalStatusLevel(data.row.emotionalStateRating);

            return (
              <Stack
                direction={{ sm: 'column', md: 'row' }}
                spacing={0.5}
                sx={{
                  alignItems: 'center'
                }}
              >
                <StudentBehaviors
                  isCompact
                  behaviors={{
                    dailyActivity: {
                      kind: 'daily-activity',
                      level: data.row.lastConnectionIndicatorLevel,
                      tooltip: getDailyActivityBehaviorTooltip(data.row.lastConnection, localization, dateService)
                    },
                    inbox: {
                      kind: 'inbox',
                      level: inboxBehaviorLevel,
                      tooltip: getInboxBehaviorTooltip(inboxBehaviorLevel, localization)
                    },
                    lastEmotionalStatus: {
                      kind: 'last-emotional-status',
                      level: lastEmotionalStatusLevel,
                      tooltip: getStudentEmotionalStatusTooltip(data.row.emotionalStateRating, localization)
                    }
                  }}
                />
              </Stack>
            );
          }
        },
        {
          ...baseStatColumn,
          headerName: strings.studentsListLateColumnTitle,
          field: 'lateCount',
          renderCell: (data: RenderCellData) => (
            <TableTextCell color={data.row.lateItemsCount > 0 ? 'error' : undefined}>
              {data.row.lateItemsCount}
            </TableTextCell>
          )
        },
        {
          ...baseStatColumn,
          headerName: strings.studentsListInboxColumnTitle,
          field: 'inboxItemsCount',
          renderCell: (data: RenderCellData) => <TableTextCell>{data.row.inboxItemsCount}</TableTextCell>
        },
        {
          ...baseStatColumn,
          headerName: strings.studentsListTodayColumnTitle,
          field: 'todayItemsCount',
          renderCell: (data: RenderCellData) => <TableTextCell>{data.row.todayItemsCount}</TableTextCell>
        },
        {
          ...baseStatColumn,
          headerName: strings.studentsListUpcomingColumnTitle,
          field: 'upcomingItemsCount',
          renderCell: (data: RenderCellData) => <TableTextCell>{data.row.upcomingItemsCount}</TableTextCell>
        },
        {
          ...baseStatColumn,
          headerName: strings.studentsListDoneColumnTitle,
          field: 'doneItemsCount',
          renderCell: (data: RenderCellData) => <TableTextCell>{data.row.doneItemsCount}</TableTextCell>
        },
        {
          field: 'connectedSources',
          headerName: strings.studentsListConnectedSources,
          sortable: false,
          disableColumnMenu: true,
          headerAlign: 'center',
          align: 'center',
          renderCell: (data: RenderCellData) => {
            const avatarStyle: SxProps = { width: 24, height: 24, ...theme.typography.body2 };

            return (
              <Stack direction="row" spacing={0.5}>
                {data.row.plannerExternalSourceNames
                  // If more than 3 items, we display the first two with another being the +X.
                  .slice(0, data.row.plannerExternalSourceNames.length > 3 ? 2 : 3)
                  .map((sourceName) => avatarForExternalSource(sourceName, avatarStyle))}

                {data.row.plannerExternalSourceNames.length > 3 && (
                  <Tooltip
                    title={<MultilineTooltipTitle lines={data.row.plannerExternalSourceNames.slice(2)} />}
                    slotProps={{
                      tooltip: {
                        sx: {
                          textTransform: 'capitalize'
                        }
                      }
                    }}
                  >
                    <Avatar sx={avatarStyle}>+{data.row.plannerExternalSourceNames.length - 2}</Avatar>
                  </Tooltip>
                )}
              </Stack>
            );
          }
        },
        {
          field: 'actions',
          headerName: '',
          type: 'actions',
          renderCell: (data: RenderCellData) => <RowActions data={data} />
        }
      ],
      [theme]
    );

    return (
      <TableWithHeader
        sx={sx}
        className={className}
        tableKey="student_planner_summary"
        title={strings.studentsListTitle}
        rows={viewModel.students}
        columns={columns}
        emptyOverlayParams={{
          title: strings.detailsNoStudentsTitle,
          subtitle: strings.detailsNoStudentsSubtitle,
          renderIcon: (className) => <EmptyListIcon className={className} />
        }}
      />
    );
  }
);

function avatarForExternalSource(externalSourceName: string, sx: SxProps): ReactNode {
  const name = nameForExternalSource(externalSourceName);

  return (
    <Tooltip
      key={externalSourceName}
      title={name}
      slotProps={{
        tooltip: {
          sx: {
            ':first-letter': {
              textTransform: 'capitalize'
            }
          }
        }
      }}
    >
      <Avatar sx={sx} alt={name}>
        {iconForExternalSource(externalSourceName) ?? name.charAt(0).toUpperCase()}
      </Avatar>
    </Tooltip>
  );
}

function iconForExternalSource(externalSourceName: string): ReactNode | undefined {
  switch (externalSourceName) {
    case 'classroom':
      return <ClassroomRoundedIcon title="Google Classroom" />;
    case 'google-calendar':
      return <GoogleCalendarIcon title="Google Calendar" />;
    default:
      return undefined;
  }
}

function nameForExternalSource(externalSourceName: string): string {
  switch (externalSourceName) {
    case 'classroom':
      return 'Google Classroom';
    case 'google-calendar':
      return 'Google Calendar';
    default:
      return externalSourceName;
  }
}

function RowActions({ data }: { data: RenderCellData }) {
  const { localization } = useServices();
  const strings = localization.localizedStrings.schoolCourseSection;

  const student = data.row;
  const plannerUrl = student.plannerUrl;

  return (
    <TableRowActions
      actions={[
        plannerUrl != null
          ? { text: strings.studentPlannerOpenStudentPlannerButtonTitle, selection: { case: 'link', to: plannerUrl } }
          : { text: strings.studentPlannerOpenStudentPlannerButtonTitle, disabled: true }
      ]}
    />
  );
}
