import i18n from 'translate';

export const CalendarDayOptionsStrings = {
  alwaysShowPastEventsOption: () => i18n.t('calendar.day.options.alwaysShowPastEventsOption'),
  expandWorkDetailsOption: () => i18n.t('calendar.day.options.expandWorkDetailsOption'),
  showFreePeriods: () => i18n.t('calendar.day.options.showFreePeriods'),
  showOptionsSectionTitle: () => i18n.t('calendar.day.options.showOptionsSectionTitle'),
  showPeriodLabels: () => i18n.t('calendar.day.options.showPeriodLabels'),
  title: () => i18n.t('calendar.day.options.title')
};

export const CalendarDayStrings = {
  allDayItemsSectionTitle: () => i18n.t('calendar.day.allDayItemsSectionTitle'),
  createWorkInDayButtonTitle: () => i18n.t('calendar.day.createWorkInDayButtonTitle'),
  currentItems: () => i18n.t('calendar.day.currentItems'),
  dataFetchErrorMessage: () => i18n.t('calendar.day.dataFetchErrorMessage'),
  dataFetchErrorRetryButtonTitle: () => i18n.t('calendar.day.dataFetchErrorRetryButtonTitle'),
  dataFetchLoadingMessage: () => i18n.t('calendar.day.dataFetchLoadingMessage'),
  emptyIndicatorSubtitle: () => i18n.t('calendar.day.emptyIndicatorSubtitle'),
  emptyIndicatorTitle: () => i18n.t('calendar.day.emptyIndicatorTitle'),
  hidePastItemsButtonTitle: () => i18n.t('calendar.day.hidePastItemsButtonTitle'),
  inboxWorkAlert: (count: number) => i18n.t('calendar.day.inboxWorkAlert', { count }),
  noteInstructionsRichTextTitle: () => i18n.t('calendar.day.noteInstructionsRichTextTitle'),
  options: CalendarDayOptionsStrings,
  pasteInDayButtonTitle: () => i18n.t('calendar.day.pasteInDayButtonTitle'),
  pastItemsSectionTitle: () => i18n.t('calendar.day.pastItemsSectionTitle'),
  periodCreateItemButtonTitle: () => i18n.t('calendar.day.periodCreateItemButtonTitle'),
  periodOccurrenceCount: (occurrence: number) =>
    i18n.t('calendar.day.periodOccurrenceCount', { count: occurrence, ordinal: true }),
  periodOccurrenceNoClass: () => i18n.t('calendar.day.periodOccurrenceNoClass'),
  periodPasteItemButtonTitle: () => i18n.t('calendar.day.periodPasteItemButtonTitle'),
  showPastItemsButtonTitle: () => i18n.t('calendar.day.showPastItemsButtonTitle'),
  upNextEmptyIndicatorSubtitle: () => i18n.t('calendar.day.upNextEmptyIndicatorSubtitle'),
  upNextEmptyIndicatorTitle: () => i18n.t('calendar.day.upNextEmptyIndicatorTitle'),
  upNextSectionTitle: () => i18n.t('calendar.day.upNextSectionTitle'),
  upcomingItemEndingInSectionTitle: (duration: string) =>
    i18n.t('calendar.day.upcomingItemEndingInSectionTitle', { duration }),
  upcomingItemStartingInSectionTitle: (duration: string) =>
    i18n.t('calendar.day.upcomingItemStartingInSectionTitle', { duration }),
  upcomingWorkDueInSectionTitle: (duration: string) =>
    i18n.t('calendar.day.upcomingWorkDueInSectionTitle', { duration }),
  upcomingWorkDueNowSectionTitle: () => i18n.t('calendar.day.upcomingWorkDueNowSectionTitle'),
  workInstructionsRichTextTitle: () => i18n.t('calendar.day.workInstructionsRichTextTitle')
};
