import { Box, BoxProps } from '@mui/material';
import { captureException } from '@sentry/react';
import { observer } from 'mobx-react-lite';
import { ReactNode, useEffect, useState } from 'react';
import { isError, isSxArray } from '../../utils';
import { UpdatablePresenterError, UpdatablePresenterLoading } from './updatablepresenter';

export interface AsyncPresenterProps<Result> extends Omit<BoxProps, 'children'> {
  /**
   * The request to execute.
   */
  request: () => Promise<Result>;

  /**
   * Render function used when the request has succeeded.
   * @param value
   */
  children: (value: Result) => ReactNode;

  /**
   * The loading message. Optional. Used when `customLoadingComponent` is not specified.
   */
  loadingMessage?: string;

  /**
   * The loading and error indicators size. Optional. Default is `normal`.
   */
  size?: 'small' | 'normal';

  /**
   * The error message. Optional.
   * @param error The error from which to get the message.
   * @return The error message or undefined.
   */
  errorMessageSelector?: (error: Error) => string | undefined;

  /**
   * Indicates if loading component should have a minHeight of 200. Optional. Defaults to true.
   */
  hasLoadingMinimumHeight?: boolean;

  /**
   * The component to display when loading the data. Optional. `LoadingIndicator` will be used if not specified.
   */
  customLoadingComponent?: () => ReactNode;

  /**
   * Indicates if error component should have a minHeight of 200. Optional. Defaults to true.
   */
  hasErrorMinimumHeight?: boolean;

  /**
   * Indicates if the container for renderData should be of display flex. Defaults to true
   */
  isFlex?: boolean;

  /**
   * The component to display when an error occurs. Optional. `ErrorIndicator` will be used if not specified.
   * @param message The error message. Optional.
   */
  customErrorComponent?: (message?: string) => ReactNode;
}

export const AsyncPresenter = observer(
  <Result,>({
    sx = [],
    className,
    request,
    children,
    hasLoadingMinimumHeight,
    customLoadingComponent,
    loadingMessage,
    size,
    hasErrorMinimumHeight,
    customErrorComponent,
    errorMessageSelector,
    isFlex,
    ...props
  }: AsyncPresenterProps<Result>) => {
    const [result, setResult] = useState<Result | Error | undefined>();

    const executeRequest = async () => {
      setResult(undefined);
      try {
        const r = await request();
        setResult(r);
      } catch (e) {
        console.error('Async presenter failed with error', e);
        setResult(e as Error);
        captureException(e);
      }
    };

    useEffect(() => void executeRequest(), [request]);

    if (result == undefined) {
      return (
        <Box
          {...props}
          className={className}
          sx={[
            {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            },
            ...(isSxArray(sx) ? sx : [sx])
          ]}
        >
          <UpdatablePresenterLoading
            customLoadingComponent={customLoadingComponent}
            loadingMessage={loadingMessage}
            size={size}
            sx={{ minHeight: (hasLoadingMinimumHeight ?? true) ? 200 : undefined, aspectRatio: '1/1' }}
          />
        </Box>
      );
    } else if (isError(result)) {
      return (
        <Box
          {...props}
          className={className}
          sx={[
            {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            },
            ...(isSxArray(sx) ? sx : [sx])
          ]}
        >
          <UpdatablePresenterError
            error={result}
            customErrorComponent={customErrorComponent}
            errorMessageSelector={errorMessageSelector}
            reloadData={() => void executeRequest()}
            sx={{ minHeight: (hasErrorMinimumHeight ?? true) ? 200 : undefined, aspectRatio: '1/1' }}
          />
        </Box>
      );
    }

    return (
      <Box
        {...props}
        className={className}
        sx={[
          {
            display: (isFlex ?? true) ? 'flex' : undefined,
            position: 'relative'
          },
          ...(isSxArray(sx) ? sx : [sx])
        ]}
      >
        {children(result)}
      </Box>
    );
  }
);
