import { useViewModel } from '@/hooks';
import { Box, Checkbox, FormHelperText, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { captureException } from '@sentry/react';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useParams } from 'react-router';
import LocalizedStrings from 'strings';
import { SettingsCard, SettingsContent } from '../../shared';
import { UpdatablePresenter } from '../../utils';

export interface PlannerCurriculumCoursesProps {
  sx?: SxProps;
  className?: string;
}

export const PlannerCurriculumCourses = observer(({ sx = [], className }: PlannerCurriculumCoursesProps) => {
  const strings = LocalizedStrings.settings.planner.curriculumClasses;
  const params = useParams();
  const plannerId = params.plannerId ?? '';

  const [isSavingSelection, setIsSavingSelection] = useState(false);
  const [saveSelectionErrorCourseId, setSaveSelectionErrorCourseId] = useState<string | undefined>();

  const viewModel = useViewModel(
    (viewModels) => viewModels.createPlannerCurriculumCoursesViewModel(plannerId),
    [plannerId]
  );

  async function toggleSelectionOfCourse(id: string) {
    setIsSavingSelection(true);

    try {
      await viewModel.toggleEnrollmentOfCourse(id);
    } catch (e) {
      setSaveSelectionErrorCourseId(id);
      captureException(e);
    } finally {
      setIsSavingSelection(false);
    }
  }

  return (
    <SettingsContent sx={sx} className={className}>
      <Box>
        <UpdatablePresenter
          viewModel={viewModel}
          renderData={() => (
            <SettingsCard title={viewModel.title}>
              <Box
                sx={{
                  px: 3,
                  py: 2
                }}
              >
                <Stack spacing={2}>
                  {viewModel.courses.map((course) => (
                    <Stack key={course.course.id} spacing={1}>
                      <Stack
                        direction="row"
                        spacing={2}
                        sx={{
                          alignItems: 'flex-start'
                        }}
                      >
                        <Checkbox
                          checked={course.isEnrolled}
                          disabled={isSavingSelection || viewModel.isReadOnly}
                          onChange={() => void toggleSelectionOfCourse(course.course.id)}
                        />

                        <Stack spacing={0.5}>
                          <Typography>{course.course.title}</Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              color: (theme) => theme.palette.text.secondary,
                              whiteSpace: 'pre-line'
                            }}
                          >
                            {course.course.description}
                          </Typography>
                        </Stack>
                      </Stack>

                      {saveSelectionErrorCourseId === course.course.id && (
                        <FormHelperText error>{strings.errorMessage()}</FormHelperText>
                      )}
                    </Stack>
                  ))}

                  {viewModel.courses.length === 0 && (
                    <Typography
                      sx={{
                        color: (theme) => theme.palette.text.secondary
                      }}
                    >
                      {strings.emptyMessage()}
                    </Typography>
                  )}
                </Stack>
              </Box>
            </SettingsCard>
          )}
        />
      </Box>
    </SettingsContent>
  );
});
