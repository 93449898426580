import { InitializingScreen, ServerErrorScreen } from '@/components';
import { useServices } from '@/hooks';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';
import { Navigate } from 'react-router';
import { RouteTemplates } from '../RouteTemplates';
import { isError } from '../utils';

export interface OnboardingLayoutProps {
  children?: ReactNode;
}

export const OnboardingLayout = observer(({ children }: OnboardingLayoutProps) => {
  const { userStore, user } = useServices();

  if (user.currentUser == null) {
    return <Navigate to={RouteTemplates.root} replace />;
  }

  if (
    !userStore.plannersLoadable.hasData ||
    !userStore.participatingSchools.hasData ||
    !userStore.subscription.hasData ||
    !userStore.getPreference('seen-curriculum-course-ids').hasData
  ) {
    return <InitializingScreen />;
  }

  if (isError(userStore.plannersLoadable.state)) {
    return <ServerErrorScreen error={userStore.plannersLoadable.state} />;
  }

  if (isError(userStore.subscription.state)) {
    return <ServerErrorScreen error={userStore.subscription.state} />;
  }

  return <>{children}</>;
});
