import { computed, makeObservable, observable, runInAction } from 'mobx';
import {
  Environment,
  EnvironmentKind,
  EnvironmentService,
  FirebaseConfig,
  TransloaditConfig,
  UnleashConfig
} from '../contracts';

export class AppEnvironmentService implements EnvironmentService {
  @observable private _environment: Environment | undefined;

  constructor() {
    makeObservable(this);
    // The deployment-specific data is stored in an .env file that will be overwritten by the deployment.
    void this.loadEnvironment();
  }

  @computed
  get environmentKind(): EnvironmentKind {
    return this.environment === 'prod' ? 'production' : 'dev';
  }

  @computed
  get environment(): string {
    return this._environment!.environment;
  }

  @computed
  get isLoaded(): boolean {
    return this._environment != null;
  }

  @computed
  get googleClientId(): string {
    return this._environment!.googleClientId;
  }

  @computed
  get intercomAppId(): string {
    return this._environment!.intercomAppId;
  }

  @computed
  get mixpanelToken(): string {
    return this._environment!.mixpanelToken;
  }

  @computed
  get googleTagManagerId(): string {
    return this._environment!.googleTagManagerId;
  }

  @computed
  get version(): string {
    return this._environment!.version;
  }

  @computed
  get buildNumber(): string {
    return this._environment!.buildNumber;
  }

  @computed
  get todayApiUrl(): string {
    return this._environment!.todayApiUrl;
  }

  @computed
  get firebase(): FirebaseConfig {
    return this._environment!.firebase;
  }

  @computed
  get unleash(): UnleashConfig {
    return this._environment!.unleash;
  }

  @computed
  get transloadit(): TransloaditConfig {
    return this._environment!.transloadit;
  }

  @computed
  get unsplashAccessKey(): string {
    return this._environment!.unsplashAccessKey;
  }

  @computed
  get fileHostBasename(): string {
    return this._environment!.fileHostBasename;
  }

  private async loadEnvironment(): Promise<void> {
    const response = await fetch(`/environment.json`);
    const environment = (await response.json()) as Environment;
    runInAction(() => (this._environment = environment));
  }
}
