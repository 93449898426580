import { useServices } from '@/hooks';
import { BaseDialogActionButtonConfiguration, DialogActionButtonConfiguration } from '@/viewmodels';
import { OpenInFullRounded } from '@mui/icons-material';
import { Box, Button, Dialog, Divider, IconButton, SxProps } from '@mui/material';
import { RichTextEditor } from '@studyoco/rich-text-editor';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { MouseEvent, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import LocalizedStrings from 'strings';
import { ResponsiveDialogHeader } from '../dialog';
import { RichTextViewerTextSize } from './RichTextViewerUtils';

export interface RichTextViewerProps {
  sx?: SxProps;
  className?: string;
  id: string;
  label: string;
  content: string | undefined;
  textSize?: RichTextViewerTextSize;
  canSelectText?: boolean;
}

export const RichTextViewerHtml = observer(
  ({ sx, className, id, content, label, textSize, canSelectText }: RichTextViewerProps) => {
    const { intercom, localization } = useServices();
    const containerRef = useRef<HTMLDivElement>(null);
    const [showFullscreen, setShowFullscreen] = useState(false);
    const [hasContentHidden, setHasContentHidden] = useState(false);

    useEffect(() => {
      if (showFullscreen) {
        intercom.hide();
      } else {
        intercom.show();
      }
    }, [showFullscreen, intercom]);

    const onShowFullScreenButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setShowFullscreen(true);
    };

    const buttonAnimation = {
      hidden: {
        opacity: 0,
        transition: {
          type: 'easeInOut',
          duration: 0.2
        }
      },
      hover: {
        opacity: 1,
        transition: {
          type: 'easeInOut',
          duration: 0.2
        }
      }
    };

    const fullScreenButtonConfig = useMemo(
      () =>
        new BaseDialogActionButtonConfiguration(
          'secondary',
          'top-only',
          'hidden',
          'reduce',
          undefined,
          () => '', // No title, will always be shown as an icon.
          'contained',
          () => {
            setShowFullscreen(false);
            return Promise.resolve();
          }
        ),
      []
    );

    const rightDialogActions: DialogActionButtonConfiguration[] = useMemo(
      () => [fullScreenButtonConfig],
      [fullScreenButtonConfig]
    );

    useLayoutEffect(() => {
      const element = document.getElementsByClassName('rte-editor-container-content').item(0) as
        | HTMLDivElement
        | undefined;
      const hasContentHidden = element != null && isContentHidden(element);
      setHasContentHidden(hasContentHidden);
    }, [containerRef.current]);

    return (
      <Box
        sx={sx}
        className={clsx(className, 'rich-text-viewer')}
        component={motion.section}
        initial="hidden"
        whileHover="hover"
      >
        <Box className="rich-text-viewer-editor-container" ref={containerRef}>
          <RichTextEditor
            id={`${id}-viewer`}
            className="rich-text-viewer-editor"
            content={content ?? ''}
            locale={localization.currentLocale}
            options={{ isReadOnly: true, textSize, canSelectText }}
            // Allows outline of elements to be displayed
            contentSx={{ p: '2px', width: '100%' }}
          />
        </Box>

        {hasContentHidden ? (
          <div className="rich-text-viewer-full-screen-button">
            <Button onClick={onShowFullScreenButtonClick} size="small" endIcon={<OpenInFullRounded fontSize="small" />}>
              {LocalizedStrings.utils.richTextViewerExpand()}
            </Button>
          </div>
        ) : (
          <motion.div className="rich-text-viewer-full-screen-button" variants={buttonAnimation}>
            <IconButton onClick={onShowFullScreenButtonClick} size="small">
              <OpenInFullRounded fontSize="small" />
            </IconButton>
          </motion.div>
        )}

        {showFullscreen && (
          <Dialog
            open
            onClose={(e) => {
              (e as Event).stopPropagation();
              setShowFullscreen(false);
            }}
            onContextMenu={(e) => e.stopPropagation()}
            PaperProps={{ className: 'rich-text-viewer-full-screen-dialog' }}
          >
            <ResponsiveDialogHeader
              title={label}
              leftButtons={[]}
              rightButtons={rightDialogActions}
              isDraggable={false}
              id={'rich-text-viewer-fullscreen'}
            />

            <Divider />

            <RichTextEditor
              id={`${id}-viewer-full-screen`}
              autofocus
              content={content ?? ''}
              locale={localization.currentLocale}
              options={{ isReadOnly: true }}
              className="rich-text-viewer-full-screen-dialog-editor"
              contentSx={{ p: 3, width: '100%' }}
            />
          </Dialog>
        )}
      </Box>
    );
  }
);

function isContentHidden(div: HTMLElement): boolean {
  return div.scrollHeight > div.clientHeight || div.scrollWidth > div.clientWidth;
}
