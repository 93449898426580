import { ServiceContainer } from '@/providers';
import { AttachmentService } from '@/services';
import { Attachment } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/attachment_pb';
import { ImportanceLevel } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/importance_level_pb';
import { RichText } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/rich_text_pb';
import { isSameMinute, startOfDay } from 'date-fns';
import { IObservableArray, action, computed, makeObservable, observable, override } from 'mobx';
import { AttachmentInfo } from '../AttachmentInfo';
import { PublishedWorkEditInfo } from '../PublishedWorkEditInfo';
import { EditableAttachmentInfo } from './EditableAttachmentInfo';
import { EditableModel, EditableProperty } from './core';

export class EditablePublishedWorkEditInfo extends EditableModel<PublishedWorkEditInfo> {
  private _title: EditableProperty<string>;
  private _iconId: EditableProperty<string>;
  private _description: EditableProperty<RichText | undefined>;
  private _importance: EditableProperty<ImportanceLevel>;
  private _dueDate: EditableProperty<Date>;
  private _isDueAllDay: EditableProperty<boolean>;
  private _courseSectionId: EditableProperty<string>;
  private _maxGrade: EditableProperty<number | undefined>;
  private _scheduledPublishTime: EditableProperty<Date | undefined>;
  private _alsoPublishInExternalSource: EditableProperty<boolean>;
  private readonly _attachmentsInfos: IObservableArray<EditableAttachmentInfo>;

  private readonly _initialDate: Date;

  constructor(
    defaultWorkIconId: string,
    defaultValues: Partial<PublishedWorkEditInfo> | undefined,
    publishedWorkInfo?: PublishedWorkEditInfo,
    private readonly _attachmentService: AttachmentService = ServiceContainer.services.attachmentService
  ) {
    const initialDate = startOfDay(new Date());

    super(
      publishedWorkInfo == null,
      publishedWorkInfo ?? {
        title: '',
        iconId: defaultWorkIconId,
        description: undefined,
        importance: ImportanceLevel.REGULAR,
        dueDate: initialDate,
        isDueAllDay: true,
        courseSectionId: '',
        maxGrade: undefined,
        schedulePublishTime: undefined,
        alsoPublishInExternalSource: false,
        attachments: [],
        ...defaultValues
      }
    );

    this._initialDate = initialDate;
    makeObservable(this);

    this._attachmentsInfos = observable.array(
      this.initialModel.attachments?.map((a) => {
        const info = _attachmentService.attachmentInfoFromSchoolAttachment(a);
        return new EditableAttachmentInfo(false, info);
      }) ?? []
    );

    this.setFields([
      (this._title = new EditableProperty<string>(this.initialModel.title, (v1, v2) => (v1 ?? '') === (v2 ?? ''))),
      (this._iconId = new EditableProperty<string>(this.initialModel.iconId)),
      (this._description = new EditableProperty<RichText | undefined>(
        this.initialModel.description,
        (v1, v2) => v1?.format === v2?.format && (v1?.text ?? '') === (v2?.text ?? '')
      )),
      (this._importance = new EditableProperty<ImportanceLevel>(this.initialModel.importance)),
      (this._dueDate = new EditableProperty<Date>(this.initialModel.dueDate, (value1, value2) => {
        if (value1 == null || value2 == null) {
          return true;
        }

        return isSameMinute(value1, value2);
      })),
      (this._isDueAllDay = new EditableProperty<boolean>(this.initialModel.isDueAllDay)),
      (this._courseSectionId = new EditableProperty<string>(this.initialModel.courseSectionId)),
      (this._maxGrade = new EditableProperty<number | undefined>(this.initialModel.maxGrade)),
      (this._scheduledPublishTime = new EditableProperty<Date | undefined>(
        this.initialModel.schedulePublishTime,
        (value1, value2) => {
          if (value1 == null && value2 == null) {
            return true;
          }

          return value1 != null && value2 != null ? isSameMinute(value1, value2) : false;
        }
      )),
      (this._alsoPublishInExternalSource = new EditableProperty<boolean>(this.initialModel.alsoPublishInExternalSource))
    ]);
  }

  @override
  get hasChanges(): boolean {
    return super.hasChanges || this._attachmentsInfos.some((a) => a.markedAsDeleted || a.shouldBeCreated);
  }

  // Editable properties

  @computed
  get title(): string {
    return this._title.value ?? '';
  }

  set title(value: string) {
    this._title.value = value;
  }

  @computed
  get iconId(): string {
    return this._iconId.value!;
  }

  set iconId(value: string) {
    this._iconId.value = value;
  }

  @computed
  get description(): RichText | undefined {
    return this._description.value;
  }

  set description(value: RichText | undefined) {
    this._description.value = value;
  }

  @computed
  get importance(): ImportanceLevel {
    return this._importance.value ?? ImportanceLevel.REGULAR;
  }

  set importance(value: ImportanceLevel) {
    this._importance.value = value;
  }

  @computed
  get dueDate(): Date {
    return this._dueDate.value ?? this._initialDate;
  }

  set dueDate(value: Date) {
    this._dueDate.value = value;
  }

  @computed
  get isDueAllDay(): boolean {
    return this._isDueAllDay.value ?? true;
  }

  set isDueAllDay(value: boolean) {
    this._isDueAllDay.value = value;
  }

  @computed
  get courseSectionId(): string {
    return this._courseSectionId.value ?? '';
  }

  set courseSectionId(value: string) {
    this._courseSectionId.value = value;
  }

  @computed
  get maxGrade(): number | undefined {
    return this._maxGrade.value;
  }

  set maxGrade(value: number | undefined) {
    this._maxGrade.value = value;
  }

  @computed
  get scheduledPublishTime(): Date | undefined {
    return this._scheduledPublishTime.value;
  }

  set scheduledPublishTime(value: Date | undefined) {
    this._scheduledPublishTime.value = value;
  }

  @computed
  get alsoPublishInExternalSource(): boolean {
    return this._alsoPublishInExternalSource.value ?? false;
  }

  set alsoPublishInExternalSource(value: boolean) {
    this._alsoPublishInExternalSource.value = value;
  }

  @computed
  get attachmentsInfos(): EditableAttachmentInfo[] {
    return this._attachmentsInfos;
  }

  @computed
  get attachments(): Attachment[] {
    return this.attachmentsInfos
      .filter((a) => !a.markedAsDeleted)
      .map((a) => this._attachmentService.attachmentInfoToSchoolAttachment(a.updatedModel));
  }

  @action
  addAttachment(attachment: AttachmentInfo) {
    this._attachmentsInfos.push(new EditableAttachmentInfo(true, attachment));
  }

  @computed
  get updatedModel(): PublishedWorkEditInfo {
    return {
      ...this.initialModel,
      title: this.title,
      iconId: this.iconId,
      description: this.description,
      importance: this.importance,
      dueDate: this.dueDate,
      isDueAllDay: this.isDueAllDay,
      courseSectionId: this.courseSectionId,
      maxGrade: this.maxGrade,
      schedulePublishTime: this.scheduledPublishTime,
      alsoPublishInExternalSource: this.alsoPublishInExternalSource,
      attachments: this.attachments
    };
  }
}
