import { useServices } from '@/hooks';
import { DialogActionButtonConfiguration } from '@/viewmodels';
import { DialogTitle, ListItemIcon, ListItemText, MenuItem, Stack, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { ReactNode, useRef, useState } from 'react';
import { ActionMenu } from '../ActionMenu';
import { ActionMenuButton } from '../ActionMenuButton';
import { MediaQuery } from '../MediaQuery';
import { DialogAdditionalAction } from './Dialog';
import { DialogActionButton } from './DialogActionButton';

type Width = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export interface ResponsiveDialogHeaderProps {
  title: string;
  leftButtons: DialogActionButtonConfiguration[];
  rightButtons: DialogActionButtonConfiguration[];
  /**
   * The icon displayed beside the title. Optional. Will only be displayed on desktop.
   */
  icon?: ReactNode;
  /**
   * The size at which the dialog is in full width. Optional. Default is `sm`.
   */
  fullScreenWidth?: Width;
  /**
   * Additional actions displayed as a more icon button in the header.
   */
  additionalActions?: DialogAdditionalAction[];
  /**
   * Indicates if the header should reflect a draggable element.
   */
  isDraggable: boolean;

  className?: string;
  sx?: SxProps;
  id: string;
}

export const ResponsiveDialogHeader = observer(
  ({
    title,
    leftButtons,
    rightButtons,
    icon,
    fullScreenWidth = 'sm',
    className,
    sx = [],
    additionalActions,
    isDraggable,
    id
  }: ResponsiveDialogHeaderProps) => {
    const visibleAdditionalActions = additionalActions?.filter((a) => !a.isHidden) ?? [];

    return (
      <DialogTitle
        className={className}
        sx={{ ...sx, m: 0, px: 2, py: 1.5, cursor: isDraggable ? 'move' : 'default' }}
        id={id}
      >
        <Stack
          direction="row"
          spacing={2}
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex'
          }}
        >
          <MediaQuery mediaQuery={(th) => th.breakpoints.down(fullScreenWidth)}>
            <Stack
              direction="row"
              spacing={1}
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex'
              }}
            >
              {leftButtons.map((config) => (
                <DialogActionButton key={config.title()} configuration={config} kind="icon" defaultColor="inherit" />
              ))}
            </Stack>
          </MediaQuery>

          <MediaQuery mediaQuery={(th) => th.breakpoints.up(fullScreenWidth)}>{icon}</MediaQuery>

          <Typography
            variant="h6"
            noWrap
            sx={{
              flex: 1
            }}
          >
            {title}
          </Typography>

          <MediaQuery mediaQuery={(th) => th.breakpoints.between('xs', fullScreenWidth)}>
            {rightButtons
              .filter((c) => c.mobilePlacement !== 'hidden')
              .map((config) => (
                <DialogActionButton key={config.title()} configuration={config} kind="icon" defaultColor="inherit" />
              ))}

            {visibleAdditionalActions.length > 0 && (
              <DialogHeaderMoreButton actions={visibleAdditionalActions} color="inherit" />
            )}
          </MediaQuery>

          <MediaQuery mediaQuery={(th) => th.breakpoints.up(fullScreenWidth)}>
            <Stack
              direction="row"
              spacing={1}
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex'
              }}
            >
              {rightButtons
                .filter((config) => config.kind === 'secondary' && config.placement === 'top-only')
                .map((config) => (
                  <DialogActionButton key={config.title()} configuration={config} kind="icon" />
                ))}

              {visibleAdditionalActions.length > 0 && <DialogHeaderMoreButton actions={visibleAdditionalActions} />}
            </Stack>
          </MediaQuery>
        </Stack>
      </DialogTitle>
    );
  }
);

interface DialogHeaderMoreButtonProps {
  actions: DialogAdditionalAction[];
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
}

const DialogHeaderMoreButton = observer(({ actions, color = 'primary' }: DialogHeaderMoreButtonProps) => {
  const { localization } = useServices();
  const [showMenu, setShowMenu] = useState(false);
  const menuAnchorRef = useRef<HTMLButtonElement>(null);
  const strings = localization.localizedStrings.utils;
  const theme = useTheme();

  return (
    <>
      <ActionMenuButton
        onClick={() => setShowMenu(true)}
        ref={menuAnchorRef}
        buttonColor={color}
        tooltip={showMenu ? strings.dialogMoreActionsTooltip : undefined}
      />

      <ActionMenu open={showMenu} anchorEl={menuAnchorRef.current} onClose={() => setShowMenu(false)}>
        {actions.map((action) => (
          <MenuItem
            key={action.title}
            onClick={() => {
              setShowMenu(false);
              void action.action();
            }}
          >
            <ListItemIcon
              sx={{ '& svg': { color: action.isDestructive === true ? theme.palette.error.main : undefined } }}
            >
              {action.icon}
            </ListItemIcon>
            <ListItemText
              primary={action.title}
              slotProps={{ primary: { color: action.isDestructive === true ? theme.palette.error.main : undefined } }}
            />
          </MenuItem>
        ))}
      </ActionMenu>
    </>
  );
});
