import { UserProfile } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/user_profile_pb';
import { Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { useNavigateAsync, useViewModel } from '../../hooks';
import { SubscriptionsProductsView } from '../subscriptions';
import { UpdatablePresenter } from '../utils';
import { StartTrialExistingSubscription } from './StartTrialExistingSubscription';

export interface StartTrialSubscriptionProps {
  sx?: SxProps;
  className?: string;
  user: UserProfile;
}

export const StartTrialSubscription = observer(({ sx, className, user }: StartTrialSubscriptionProps) => {
  const navigate = useNavigateAsync();

  const onSubscribe = () => {
    return navigate('/');
  };

  const viewModel = useViewModel((viewModels) => viewModels.createStartTrialSubscriptionViewModel(onSubscribe), []);
  const strings = LocalizedStrings.startTrial.subscribe;

  return (
    <UpdatablePresenter
      sx={sx}
      className={className}
      viewModel={viewModel}
      renderData={() => (
        <Stack sx={{ width: '100%' }} spacing={3}>
          {viewModel.subscription == null ? (
            <Stack spacing={1}>
              <Typography variant="h6">{strings.title()}</Typography>
              <SubscriptionsProductsView viewModel={viewModel} />
            </Stack>
          ) : (
            <StartTrialExistingSubscription />
          )}
          <Typography sx={{ textAlign: 'center' }} variant="caption" color="textSecondary">
            {strings.loggedInUserEmail(user.emailAddress)}
          </Typography>
        </Stack>
      )}
    />
  );
});
