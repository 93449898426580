import { ScheduleCycleCreateCopyDialog } from '@/components';
import { useActivePlannerId, useNavigateAsync, useServices } from '@/hooks';
import { dayToDate, isAdminOfSharedSchool } from '@/models';
import { ScheduleCycle } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/schedule_cycle_pb';
import { ContentCopyRounded, DeleteRounded, EditRounded } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { SxProps } from '@mui/system';
import { differenceInCalendarDays, format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Link } from 'react-router';
import LocalizedStrings from 'strings';
import { ActionMenu, ActionMenuButton, ConfirmationDialog } from '../../../utils';

export interface ScheduleCycleHeaderProps {
  sx?: SxProps;
  className?: string;
  scheduleCycle: ScheduleCycle;
  schoolId: string | undefined;
  onDelete: () => Promise<void>;
  isReadOnly: boolean;
}

export const ScheduleCycleHeader = observer(
  ({ sx = [], className, scheduleCycle, onDelete, isReadOnly, schoolId }: ScheduleCycleHeaderProps) => {
    const { dateService, route, userStore } = useServices();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(() => theme.breakpoints.down('md'));

    const plannerId = useActivePlannerId();
    const school = schoolId != null ? userStore.getSchoolForId(schoolId) : undefined;
    const isSchoolAndAdmin = school != null && isAdminOfSharedSchool(userStore.user.userId, school);

    const navigate = useNavigateAsync();
    const [actionMenuButtonRef, setActionMenuButtonRef] = useState<HTMLButtonElement | null>(null);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showCreateCopy, setShowCreateCopy] = useState(false);

    const startDate = scheduleCycle.startDay != null ? dayToDate(scheduleCycle.startDay) : undefined;
    const endDate = scheduleCycle.endDay != null ? dayToDate(scheduleCycle.endDay) : undefined;
    const subtitle =
      startDate != null && endDate != null ? `${format(startDate, 'PPP')} - ${format(endDate, 'PPP')}` : '';
    const scheduleIsOver = endDate != null && differenceInCalendarDays(dateService.now, endDate) > 0;

    function onDeleteOptionPress() {
      setActionMenuButtonRef(null);
      setShowDeleteConfirmation(true);
    }

    async function onDeleteScheduleCycleConfirmation(hasConfirmed: boolean) {
      setShowDeleteConfirmation(false);

      if (hasConfirmed) {
        await onDelete();
        const destination = route.resolvePlannerSettingsLocation('schedules', plannerId);
        await navigate(destination, { replace: true });
      }
    }

    function onCreateCopyOptionPress() {
      setActionMenuButtonRef(null);
      setShowCreateCopy(true);
    }

    const strings = LocalizedStrings.scheduleCycle.edit;
    let scheduleTitle = scheduleCycle.name;
    if (school?.school?.name != null) {
      scheduleTitle += ` (${strings.scheduleNameSchool(school.school.name)})`;
    }

    return (
      <Stack sx={sx} className={className} spacing={1}>
        {scheduleIsOver && (
          <Alert
            color="success"
            action={
              <Button startIcon={<ContentCopyRounded />} onClick={onCreateCopyOptionPress}>
                {strings.createNextYearCopyButtonTitle()}
              </Button>
            }
          >
            {strings.completedScheduleMessage()}
          </Alert>
        )}
        <Stack
          spacing={{ xs: 1, sm: 2 }}
          direction="row"
          sx={{
            alignItems: 'center'
          }}
        >
          <ListItem sx={{ flex: 1 }} disablePadding disableGutters>
            <ListItemText primary={scheduleTitle} slotProps={{ primary: { fontWeight: '500' } }} secondary={subtitle} />
          </ListItem>

          {!isReadOnly && (
            <Stack
              spacing={{ xs: 1, sm: 2 }}
              direction="row"
              sx={{
                alignItems: 'center'
              }}
            >
              {!isSmallScreen && isSchoolAndAdmin && (
                <Button
                  startIcon={<EditRounded />}
                  component={Link}
                  to={route.resolveAdminSchoolScheduleLocation(school.school!.id, scheduleCycle.id)}
                >
                  {strings.editInAdminConsoleButtonLabel()}
                </Button>
              )}

              <Box>
                <ActionMenuButton onClick={(e) => setActionMenuButtonRef(e.currentTarget)} />
                <ActionMenu
                  open={actionMenuButtonRef != null}
                  anchorEl={actionMenuButtonRef}
                  onClose={() => setActionMenuButtonRef(null)}
                >
                  {isSmallScreen && isSchoolAndAdmin && (
                    <MenuItem
                      component={Link}
                      to={route.resolveAdminSchoolScheduleLocation(school.school!.id, scheduleCycle.id)}
                    >
                      <ListItemIcon>
                        <EditRounded />
                      </ListItemIcon>
                      <ListItemText>{strings.editInAdminConsoleButtonLabel()}</ListItemText>
                    </MenuItem>
                  )}

                  <MenuItem onClick={onCreateCopyOptionPress}>
                    <ListItemIcon>
                      <ContentCopyRounded />
                    </ListItemIcon>

                    <ListItemText>{strings.createScheduleCopyOptionTitle()}</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={onDeleteOptionPress}>
                    <ListItemIcon>
                      <DeleteRounded color="error" />
                    </ListItemIcon>

                    <ListItemText slotProps={{ primary: { color: 'error' } }}>
                      {strings.deleteScheduleOptionTitle()}
                    </ListItemText>
                  </MenuItem>
                </ActionMenu>

                <ConfirmationDialog
                  isOpen={showDeleteConfirmation}
                  title={strings.deleteScheduleConfirmationTitle()}
                  message={strings.deleteScheduleConfirmationMessage()}
                  isDestructive
                  confirmButtonLabel={strings.deleteScheduleConfirmationSubmitButtonTitle()}
                  onSubmit={(hasConfirmed) => void onDeleteScheduleCycleConfirmation(hasConfirmed)}
                />

                {/* Ensure view-model is reloaded every time */}
                {showCreateCopy && (
                  <ScheduleCycleCreateCopyDialog
                    isOpen={true}
                    scheduleCycle={scheduleCycle}
                    schoolId={schoolId}
                    plannerId={plannerId}
                    onCancel={() => setShowCreateCopy(false)}
                  />
                )}
              </Box>
            </Stack>
          )}
        </Stack>
      </Stack>
    );
  }
);
