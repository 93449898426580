import { PlannerRelationshipKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_relationship_kind_pb';
import { makeObservable } from 'mobx';
import { ServiceContainer } from '../../providers';
import { AppBaseOnboardingScreenViewModel, BaseOnboardingScreenViewModel } from './BaseOnboardingScreenViewModel';
import { OnboardingViewModel } from './OnboardingViewModel';

export interface ParentOnboardingOptionsViewModel extends BaseOnboardingScreenViewModel {
  createPlanner(): Promise<void>;
  useCode(): void;
}

export class AppParentOnboardingOptionsViewModel
  extends AppBaseOnboardingScreenViewModel
  implements ParentOnboardingOptionsViewModel
{
  readonly canSubmit = true;

  constructor(
    onboardingState: OnboardingViewModel,
    private readonly _userStore = ServiceContainer.services.userStore
  ) {
    super(onboardingState);
    makeObservable(this);
  }

  submit(): Promise<void> {
    // No submit button
    return Promise.resolve();
  }

  async createPlanner(): Promise<void> {
    this._onboardingState.plannerId = await this._userStore.createPlanner(
      PlannerRelationshipKind.PARENT,
      undefined,
      false
    );

    this._onboardingState.displayScreenOfKind('share-planner');
  }

  useCode(): void {
    this._onboardingState.displayScreenOfKind('use-code');
  }
}
