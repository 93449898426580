import { useServices } from '@/hooks';
import { StudentInsightsWorkViewModel } from '@/viewmodels';
import { ListItem, ListItemText } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { WorkIcon } from '../../../lists';

export interface StudentInsightsWorksListItemProps {
  sx?: SxProps;
  className?: string;
  work: StudentInsightsWorkViewModel;
}

export const StudentInsightsWorksListItem = observer(
  ({ sx = [], className, work }: StudentInsightsWorksListItemProps) => {
    const { localization } = useServices();
    const strings = localization.localizedStrings.workList;

    return (
      <ListItem sx={{ ...sx, display: 'flex' }} className={className}>
        <WorkIcon icon={work.icon} size={40} />
        <ListItemText
          sx={{ ml: 1 }}
          primary={work.title}
          secondary={work.description ?? strings.noDescription}
          slotProps={{
            primary: {
              noWrap: true,
              color: work.completionDate != null ? 'textSecondary' : 'textPrimary'
            },
            secondary: { noWrap: true }
          }}
        />
      </ListItem>
    );
  }
);
