import { useEventEmitter } from '@/contexts';
import { BackButtonListenerEvent } from '@capacitor/app';
import { useEffect } from 'react';
import { CustomEvent } from '../events';

export function useBackButtonListener(
  listener: (event: CustomEvent, backButtonEvent: BackButtonListenerEvent) => void
) {
  const emitter = useEventEmitter();

  const callback = (event: CustomEvent) => {
    const backButtonEvent = event.payload as BackButtonListenerEvent;
    listener(event, backButtonEvent);
  };

  useEffect(() => {
    emitter.on('back-button', callback);
    return () => emitter.off('back-button', callback);
  }, [emitter, listener]);
}
