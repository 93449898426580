import { UserDashboardCalendarMonthDayItemInfo } from '@/viewmodels';
import { Box, CardActionArea, Popover, Stack, Theme, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import LocalizedStrings from 'strings';
import { useBackButtonListener } from '../../../../hooks';
import { ColorIndicator } from '../../../lists';
import { Subheader } from '../../../utils';
import { renderUserDashboardCalendarMonthItem } from './UserDashboardCalendarMonthUtils';

export interface UserDashboardCalendarMonthDayMoreItemsProps {
  sx?: SxProps;
  className?: string;
  items: UserDashboardCalendarMonthDayItemInfo[];
  isAllItems: boolean;
}

export const UserDashboardCalendarMonthDayMoreItems = observer(
  ({ sx = [], className, items, isAllItems }: UserDashboardCalendarMonthDayMoreItemsProps) => {
    const theme = useTheme();
    // Only showing a maximum of 3 colors.
    const colors = items.map((item) => getColorForItem(item, theme)).slice(0, 3);
    const strings = LocalizedStrings.calendar.month;
    const [moreItemsPopoverRef, setMoreItemsPopoverRef] = useState<HTMLButtonElement | undefined>();

    useBackButtonListener((e) => {
      if (moreItemsPopoverRef != null) {
        e.stopPropagation();
        setMoreItemsPopoverRef(undefined);
      }
    });

    return (
      <Box className={className} sx={sx}>
        <CardActionArea
          sx={{
            borderRadius: 0.5,
            px: 0.5,
            py: '2px'
          }}
          onClick={(e) => setMoreItemsPopoverRef(e.currentTarget)}
        >
          <Stack
            direction="row"
            spacing={0.5}
            sx={{
              alignItems: 'center'
            }}
          >
            {colors.length > 0 && (
              <Stack
                direction="row"
                spacing="2px"
                sx={{
                  height: 14
                }}
              >
                {colors.map((color, index) => (
                  <ColorIndicator key={index} color={color} isRounded />
                ))}
              </Stack>
            )}

            <Typography
              noWrap
              sx={{
                fontSize: 12,
                flex: 1,
                color: theme.palette.text.secondary
              }}
            >
              {isAllItems ? strings.dayItems(items.length) : strings.dayMoreItems(items.length)}
            </Typography>
          </Stack>
        </CardActionArea>
        <Popover
          open={moreItemsPopoverRef != null}
          anchorEl={moreItemsPopoverRef}
          onClose={() => setMoreItemsPopoverRef(undefined)}
          slotProps={{ paper: { sx: { width: 320 } } }}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        >
          <Stack
            sx={{
              p: 2,
              pt: 0
            }}
          >
            <Subheader disableGutters>{strings.dayMorePopoverTitle()}</Subheader>
            <Stack spacing={0.5}>{items.map(renderUserDashboardCalendarMonthItem)}</Stack>
          </Stack>
        </Popover>
      </Box>
    );
  }
);

function getColorForItem(item: UserDashboardCalendarMonthDayItemInfo, theme: Theme): string {
  switch (item.case) {
    case 'period':
      return item.value.courseSection?.courseSection?.color ?? theme.palette.action.disabled;
    default:
      return item.value.color;
  }
}
