import { ContextMenuAction, ContextMenuActionsGroup } from '@/viewmodels';
import { observer } from 'mobx-react-lite';
import { MouseEvent, ReactNode, useState } from 'react';
import { ContextMenu } from './ContextMenu';

function isGroups(item: ContextMenuAction[] | ContextMenuActionsGroup[]): item is ContextMenuActionsGroup[] {
  const firstItem = item[0];
  if (firstItem == null) {
    return false;
  }

  return (firstItem as ContextMenuActionsGroup)?.actions != null;
}

export interface ContextMenuHandlerProps {
  actions?: ContextMenuAction[] | ContextMenuActionsGroup[];
  children: (contextMenuHandler: ((e: MouseEvent) => void) | undefined) => ReactNode;
}

export const ContextMenuHandler = observer(({ actions = [], children }: ContextMenuHandlerProps) => {
  const hasActions = isGroups(actions)
    ? actions.some((g) => g.actions.some((a) => a.hidden !== true))
    : actions.some((a) => a.hidden !== true);

  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const handleContextMenu = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  let resolvedActionsGroup: ContextMenuActionsGroup[];

  if (isGroups(actions)) {
    resolvedActionsGroup = actions;
  } else {
    resolvedActionsGroup = [{ actions }];
  }

  return (
    <>
      {children(hasActions ? handleContextMenu : undefined)}

      <ContextMenu
        isOpen={contextMenu != null}
        actionsGroups={resolvedActionsGroup}
        onClose={handleClose}
        anchorPosition={contextMenu != null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
      />
    </>
  );
});
