import { ListItemText, ListItemTextProps } from '@mui/material';

export function ListItemTextMultiline({ slotProps, ...props }: ListItemTextProps) {
  return (
    <ListItemText
      {...props}
      slotProps={{
        ...slotProps,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-member-access
        primary: { ...slotProps?.primary, sx: { ...(slotProps?.primary as any)?.sx, whiteSpace: 'pre-line' } },
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-member-access
        secondary: { ...slotProps?.secondary, sx: { ...(slotProps?.secondary as any)?.sx, whiteSpace: 'pre-line' } }
      }}
    />
  );
}
