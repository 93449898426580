import { useActivePlannerId, useNavigateAsync, useServices } from '@/hooks';
import { CourseSectionListViewModel } from '@/viewmodels';
import { Box, Button, List, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import { CourseSectionListItem } from './CourseSectionListItem';

export interface CourseSectionListProps {
  sx?: SxProps;
  className?: string;
  viewModel: CourseSectionListViewModel;
  canManageConnectedApps: boolean;
  editCourse: (id: string) => void;
  canEditCourse?: boolean;
}

export const CourseSectionList = observer(
  ({
    sx = [],
    className,
    viewModel,
    editCourse,
    canManageConnectedApps,
    canEditCourse = true
  }: CourseSectionListProps) => {
    const { localization, route } = useServices();
    const plannerId = useActivePlannerId();
    const strings = localization.localizedStrings.courseSection.lists;

    const navigate = useNavigateAsync();

    function showManageConnectedApps() {
      const destination = route.resolvePlannerSettingsLocation('connected-apps', plannerId);
      void navigate(destination);
    }

    return (
      <Box sx={sx} className={className}>
        <Stack sx={{ width: '100%' }}>
          {viewModel.courseSections.length > 0 ? (
            <Box sx={{ width: '100%' }}>
              <List>
                {viewModel.courseSections.map((course) => (
                  <Fragment key={course.courseSection.id}>
                    <CourseSectionListItem
                      courseSection={course}
                      editCourse={() => editCourse(course.courseSection.id)}
                      canEdit={viewModel.canEditCourseSections}
                    />
                  </Fragment>
                ))}
              </List>
            </Box>
          ) : (
            <Typography p={3} fontStyle="italic" color="textSecondary">
              {strings.emptyIndicatorTitle}
            </Typography>
          )}

          {canManageConnectedApps &&
            (viewModel.canEditCourseSections && canEditCourse ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column-reverse', sm: 'row' },
                  px: 2,
                  pb: 1
                }}
              >
                <Button sx={{ mx: { xs: 2, sm: 0 } }} onClick={showManageConnectedApps}>
                  {strings.manageClassroomCourseSections}
                </Button>
              </Box>
            ) : (
              !viewModel.canEditCourseSections && (
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: (theme) => theme.palette.text.secondary,
                    px: 2,
                    pb: 1
                  }}
                >
                  {strings.readOnlyLabel}
                </Typography>
              )
            ))}
        </Stack>
      </Box>
    );
  }
);
