import { useNavigateAsync, useServices } from '@/hooks';
import {
  UserDashboardCalendarItemState,
  UserDashboardCalendarWeekPeriodInfo,
  UserDashboardCalendarWeekPeriodInfoItem
} from '@/viewmodels';
import { Badge, Box, BoxProps, Card, CardActionArea, Stack, Tooltip, Typography, styled } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import { useLocation } from 'react-router';
import AutoSizer from 'react-virtualized-auto-sizer';
import strings from '../../../../../resources/strings';
import { ApplicationSettingsService } from '../../../../../services';
import { ColorIndicator } from '../../../../lists';
import { MultilineTooltipTitle } from '../../../../utils';
import { ContextMenuHandler } from '../../../ContextMenuHandler';
import {
  UserDashboardPeriodInfoPopover,
  formatCalendarItemTimes,
  formatCalendarItemTimesLong,
  resolvedPeriodBackgroundColor
} from '../../shared';
import { UserDashboardCalendarWeekPeriodMoreItems } from './UserDashboardCalendarWeekPeriodMoreItems';
import { UserDashboardCalendarWeekPeriodNote } from './UserDashboardCalendarWeekPeriodNote';
import { UserDashboardCalendarWeekPeriodWork } from './UserDashboardCalendarWeekPeriodWork';

interface LocationState {
  userDashboardCalendarWeekPeriodViewShowInfo?: string;
}

export interface UserDashboardCalendarWeekPeriodViewProps {
  sx?: SxProps;
  className?: string;
  period: UserDashboardCalendarWeekPeriodInfo;
  isOverlapping: boolean;
}

export const UserDashboardCalendarWeekPeriodView = observer(
  ({ sx = [], className, period, isOverlapping }: UserDashboardCalendarWeekPeriodViewProps) => {
    const { settings } = useServices();
    const smallPeriodHeight = 36;
    const regularPeriodHeight = 52;
    const { height } = period.position;
    const formattedTime = formatCalendarItemTimes(period.startTime, period.endTime, height >= smallPeriodHeight);
    const color = period.courseSection?.courseSection?.color;
    const textColor = getColorForText(period.state);
    const [showTooltip, setShowTooltip] = useState(false);

    const actionAreaRef = useRef<HTMLButtonElement>(null);
    const location = useLocation();
    const state = (location.state ?? {}) as LocationState;
    const navigate = useNavigateAsync();

    const itemsTitle = period.items.map(titleForItem).filter((t) => t.length > 0);

    const resolvedCourseTitle =
      period.courseSection?.courseSection?.title ?? strings.calendar.week.periodOccurrenceNoClass();
    const showPeriodLabel = settings.calendarShowPeriodLabels && period.periodLabel.length > 0;

    const tooltipItems = [
      resolvedCourseTitle,
      period.courseSection?.courseSection?.section ?? '',
      formatCalendarItemTimesLong(period.startTime, period.endTime),
      period.roomName,
      period.ordinal > 0 ? `#${period.ordinal}` : '',
      ...itemsTitle.map((t) => `• ${t}`)
    ];

    return (
      <ContextMenuHandler actions={period.contextMenuActions()}>
        {(contextMenuHandler) => (
          <>
            <Tooltip
              title={<MultilineTooltipTitle lines={tooltipItems.filter((item) => item.length > 0)} />}
              disableInteractive
              open={showTooltip && state.userDashboardCalendarWeekPeriodViewShowInfo == null}
              disableHoverListener
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
              placement={isOverlapping ? 'bottom-start' : undefined}
            >
              <StyledPeriodView
                sx={{ ...sx, height }}
                periodColor={color}
                isOverlapping={isOverlapping}
                className={className}
                // Prevents background click event when user clicks on the left or right padding of the period.
                onClick={(e) => e.stopPropagation()}
                onContextMenu={contextMenuHandler}
                settings={settings}
              >
                <Badge
                  className="badge"
                  badgeContent={height < regularPeriodHeight ? period.items.length : 0}
                  showZero={false}
                  color="primary"
                >
                  <Card className="card" elevation={0}>
                    <CardActionArea
                      ref={actionAreaRef}
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowTooltip(false);

                        const newState: LocationState = {
                          ...state,
                          userDashboardCalendarWeekPeriodViewShowInfo: period.id
                        };
                        void navigate(location, { state: newState });
                      }}
                      className="cardAction"
                    >
                      <Box
                        className="mainContainer"
                        sx={{
                          alignItems: height < smallPeriodHeight ? 'center' : undefined
                        }}
                      >
                        <Box className="colorIndicatorContainer">
                          <ColorIndicator color={color} isRounded={false} sx={{ height: '100%' }} width={6} />
                        </Box>

                        <Stack className="contentContainer" spacing={height >= 60 ? 0 : -0.5}>
                          <Stack spacing={-0.5}>
                            <Stack
                              direction="row"
                              spacing={1}
                              sx={{
                                width: '100%',
                                overflow: 'hidden',
                                alignItems: 'center'
                              }}
                            >
                              <Stack
                                direction="row"
                                spacing={1}
                                sx={{
                                  flex: 1,
                                  alignItems: 'baseline',
                                  overflow: 'hidden'
                                }}
                              >
                                <Typography
                                  variant="caption"
                                  noWrap
                                  color={textColor}
                                  sx={{
                                    fontWeight: '600'
                                  }}
                                >
                                  {resolvedCourseTitle}
                                </Typography>
                                <Typography
                                  noWrap
                                  color={textColor}
                                  sx={{
                                    fontSize: 10
                                  }}
                                >
                                  {period.courseSection?.courseSection?.section}
                                </Typography>
                              </Stack>

                              {(height < smallPeriodHeight || showPeriodLabel) && (
                                <Typography
                                  noWrap
                                  color={textColor}
                                  variant="caption"
                                  sx={{
                                    fontSize: 10,
                                    fontWeight: height >= smallPeriodHeight ? '600' : undefined
                                  }}
                                >
                                  {height >= smallPeriodHeight ? period.periodLabel : formattedTime}
                                </Typography>
                              )}
                            </Stack>

                            {height >= smallPeriodHeight && (
                              <Stack
                                direction="row"
                                spacing={0.5}
                                sx={{
                                  alignItems: 'baseline'
                                }}
                              >
                                <Typography
                                  noWrap
                                  variant="caption"
                                  color={textColor}
                                  sx={{
                                    fontSize: 10
                                  }}
                                >
                                  {formattedTime}
                                </Typography>

                                {period.roomName.length > 0 && (
                                  <Typography
                                    noWrap
                                    color={textColor}
                                    variant="caption"
                                    sx={{
                                      fontSize: 10
                                    }}
                                  >
                                    ({period.roomName})
                                  </Typography>
                                )}

                                <Box
                                  sx={{
                                    flex: 1
                                  }}
                                />

                                {period.ordinal > 0 && (
                                  <Typography
                                    noWrap
                                    variant="caption"
                                    color={textColor}
                                    sx={{
                                      fontSize: 10
                                    }}
                                  >
                                    #{period.ordinal}
                                  </Typography>
                                )}
                              </Stack>
                            )}
                          </Stack>

                          {height >= regularPeriodHeight && period.items.length > 0 && (
                            <>
                              {height >= regularPeriodHeight && (
                                <Box
                                  sx={{
                                    flex: 1
                                  }}
                                >
                                  <AutoSizer>
                                    {(size) => {
                                      let totalHeight = 0;
                                      let nbOfRowsAvailable = 0;
                                      for (
                                        let i = 0;
                                        i < period.items.length && totalHeight <= (size.height ?? 0);
                                        i++
                                      ) {
                                        const item = period.items[i];
                                        totalHeight +=
                                          item.case === 'note'
                                            ? 29
                                            : (item.value.description?.plainText ?? '').length > 0
                                              ? 29
                                              : 18;

                                        if (totalHeight <= (size.height ?? 0)) {
                                          nbOfRowsAvailable += 1;
                                        }
                                      }

                                      const hasMoreItems = nbOfRowsAvailable < period.items.length;
                                      const items = hasMoreItems
                                        ? nbOfRowsAvailable > 1
                                          ? period.items.slice(0, nbOfRowsAvailable - 1)
                                          : []
                                        : period.items;

                                      const moreItems = hasMoreItems
                                        ? period.items.slice(nbOfRowsAvailable - 1, undefined)
                                        : [];

                                      return (
                                        <Stack
                                          sx={{
                                            width: size.width,
                                            height: size.height
                                          }}
                                        >
                                          {items.map(renderItem)}
                                          {hasMoreItems && (
                                            <UserDashboardCalendarWeekPeriodMoreItems
                                              items={moreItems}
                                              isAllItems={items.length === 0}
                                            />
                                          )}
                                        </Stack>
                                      );
                                    }}
                                  </AutoSizer>
                                </Box>
                              )}
                            </>
                          )}
                        </Stack>
                      </Box>
                    </CardActionArea>
                  </Card>
                </Badge>
              </StyledPeriodView>
            </Tooltip>

            {state.userDashboardCalendarWeekPeriodViewShowInfo === period.id && (
              <UserDashboardPeriodInfoPopover
                isOpen={true}
                date={period.date}
                startTime={period.startTime}
                endTime={period.endTime}
                periodLabel={period.periodLabel}
                courseSectionId={period.courseSection?.courseSection?.id}
                onClose={() => void navigate(-1)}
                anchorEl={actionAreaRef.current}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'left'
                }}
              />
            )}
          </>
        )}
      </ContextMenuHandler>
    );
  }
);

interface StyledPeriodViewProps extends BoxProps {
  periodColor: string | undefined;
  isOverlapping: boolean;
  settings: ApplicationSettingsService;
}

const StyledPeriodView = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'periodColor' && prop !== 'isOverlapping' && prop !== 'settings'
})<StyledPeriodViewProps>(({ theme, periodColor, isOverlapping, settings }) => {
  const color = periodColor ?? theme.palette.action.disabled;
  const bgColor = resolvedPeriodBackgroundColor(periodColor, settings, theme);

  return {
    '.badge': {
      height: '100%',
      width: '100%'
    },

    '.card': {
      height: '100%',
      width: '100%',
      backgroundImage: 'none',
      borderRadius: 0,
      backgroundColor: 'transparent'
    },

    '.cardAction': {
      paddingRight: '6px',
      overflow: 'hidden',
      height: '100%',
      border: isOverlapping ? `1px solid ${color}` : undefined,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: bgColor
    },

    '.mainContainer': {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row'
    },

    '.colorIndicatorContainer': {
      marginRight: theme.spacing(0.5),
      height: '100%'
    },

    '.contentContainer': {
      flex: 1,
      display: 'flex',
      overflow: 'hidden',
      paddingTop: '2px',
      paddingBottom: '2px',
      minWidth: 120
    }
  };
});

function getColorForText(state: UserDashboardCalendarItemState) {
  switch (state) {
    case 'completed':
      return 'textSecondary';
    default:
      return 'textPrimary';
  }
}

function titleForItem(item: UserDashboardCalendarWeekPeriodInfoItem) {
  switch (item.case) {
    case 'note':
      return '';

    case 'publishedWork':
    case 'work':
      return item.value.title;
  }
}

function renderItem(item: UserDashboardCalendarWeekPeriodInfoItem) {
  switch (item.case) {
    case 'note': {
      return <UserDashboardCalendarWeekPeriodNote key={item.value.id} note={item.value} />;
    }

    case 'publishedWork':
    case 'work': {
      return <UserDashboardCalendarWeekPeriodWork key={item.value.id} work={item.value} />;
    }
  }
}
