import { makeObservable } from 'mobx';
import { AppBaseOnboardingScreenViewModel, BaseOnboardingScreenViewModel } from './BaseOnboardingScreenViewModel';
import { OnboardingViewModel } from './OnboardingViewModel';

export interface TeacherOnboardingOptionsViewModel extends BaseOnboardingScreenViewModel {
  createSchool(): void;
  joinSchool(): void;
}

export class AppTeacherOnboardingOptionsViewModel
  extends AppBaseOnboardingScreenViewModel
  implements TeacherOnboardingOptionsViewModel
{
  readonly canSubmit = true;

  constructor(onboardingState: OnboardingViewModel) {
    super(onboardingState);
    makeObservable(this);
  }

  submit(): Promise<void> {
    // No submit button
    return Promise.resolve();
  }

  createSchool() {
    this._onboardingState.displayScreenOfKind('create-school');
  }

  joinSchool() {
    this._onboardingState.displayScreenOfKind('use-code');
  }
}
