import { useBackButtonListener, useNavigateAsync, useServices, useViewModel } from '@/hooks';
import { Capacitor } from '@capacitor/core';
import { Box, CardActionArea, Checkbox, FormControlLabel, Popover, Stack, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { useLocation } from 'react-router';
import { DefaultDarkMuiThemePrimaryColor, DefaultLightMuiThemePrimaryColor } from '../../../resources/themes';
import { BackgroundImageDialog, LanguagePicker, ThemeVariantPicker } from '../../preferences';
import { FormPopoverActions, NavigationButton, Subheader } from '../../utils';
import { SettingsContent } from './SettingsContent';

interface LocationState {
  settingsShowBackgroundImageSelection?: boolean;
}

export interface PreferencesProps {
  className?: string;
  sx?: SxProps;
}

export const Preferences = observer(({ className, sx }: PreferencesProps) => {
  const { localization, settings } = useServices();
  const strings = localization.localizedStrings.settings;
  const theme = useTheme();

  const location = useLocation();
  const state = (location.state ?? {}) as LocationState;
  const navigate = useNavigateAsync();
  const [primaryColorPickerAnchor, setPrimaryColorPickerAnchor] = useState<HTMLElement | null>(null);

  const viewModel = useViewModel((viewModels) => viewModels.createPreferencesViewModel());
  const defaultThemeColor =
    theme.palette.mode === 'dark' ? DefaultDarkMuiThemePrimaryColor : DefaultLightMuiThemePrimaryColor;
  const [accentColor, setAccentColor] = useState(settings.customPrimaryColor ?? defaultThemeColor);

  function showBackgroundImageSelection() {
    const newState: LocationState = { ...state, settingsShowBackgroundImageSelection: true };
    void navigate(location, { state: newState });
  }

  useBackButtonListener((e) => {
    if (primaryColorPickerAnchor != null) {
      e.stopPropagation();
      setPrimaryColorPickerAnchor(null);
    }
  });

  return (
    <SettingsContent sx={sx} className={className}>
      <Stack spacing={2}>
        <LanguagePicker
          sx={{ width: '100%' }}
          locale={viewModel.locale}
          onChange={(locale) => (viewModel.locale = locale)}
        />

        <ThemeVariantPicker
          variant={viewModel.selectedThemeVariant}
          onChange={(variant) => (viewModel.selectedThemeVariant = variant)}
        />

        {!Capacitor.isNativePlatform() && (
          <Stack>
            <Subheader>{strings.backgroundImageSectionTitle}</Subheader>
            <Stack
              spacing={1}
              sx={{
                alignItems: 'flex-start'
              }}
            >
              <NavigationButton title={strings.selectBackgroundImage} onClick={() => showBackgroundImageSelection()} />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={viewModel.reduceTransparency}
                    onChange={(event) => (viewModel.reduceTransparency = event.target.checked)}
                  />
                }
                label={strings.reduceTransparency}
                sx={{ userSelect: 'none' }}
              />
            </Stack>
          </Stack>
        )}

        <Stack>
          <Subheader>{strings.customThemeSectionTitle}</Subheader>
          <Stack
            spacing={2}
            direction="row"
            sx={{
              alignItems: 'center'
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={viewModel.useCustomPrimaryColor}
                  onChange={(event) => (viewModel.useCustomPrimaryColor = event.target.checked)}
                />
              }
              label={strings.useCustomPrimaryColor}
              sx={{ userSelect: 'none' }}
            />

            {viewModel.useCustomPrimaryColor && (
              <Box>
                <CardActionArea
                  sx={{
                    width: 34,
                    height: 34,
                    borderRadius: '50%',
                    backgroundColor: settings.customPrimaryColor ?? defaultThemeColor,
                    border: `2px solid ${theme.palette.divider}`
                  }}
                  onClick={(e) => setPrimaryColorPickerAnchor(e.currentTarget)}
                />

                <Popover
                  open={primaryColorPickerAnchor != null}
                  anchorEl={primaryColorPickerAnchor}
                  slotProps={{ paper: { sx: { width: 400, overflow: 'visible' } } }}
                  onClose={() => setPrimaryColorPickerAnchor(null)}
                >
                  <Box>
                    <HexColorPicker
                      color={settings.customPrimaryColor ?? defaultThemeColor}
                      onChange={setAccentColor}
                      style={{ width: 'inherit', height: 300 }}
                    />
                  </Box>

                  <FormPopoverActions
                    sx={{ pt: 2 }}
                    onSubmit={() => {
                      setPrimaryColorPickerAnchor(null);
                      requestAnimationFrame(() => {
                        settings.customPrimaryColor = accentColor;
                      });
                      return Promise.resolve();
                    }}
                    canSubmit
                    onCancel={() => {
                      setPrimaryColorPickerAnchor(null);
                      return Promise.resolve();
                    }}
                  />
                </Popover>
              </Box>
            )}
          </Stack>
        </Stack>
      </Stack>
      {(state.settingsShowBackgroundImageSelection ?? false) && <BackgroundImageDialog isOpen={true} />}
    </SettingsContent>
  );
});
