import { Badge, Chip, ListItemButton, ListItemIcon, ListItemText, Theme, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { HTMLAttributeAnchorTarget, ReactElement } from 'react';
import { Link, PathPattern } from 'react-router';
import { useIsMatchPath } from '../../hooks';

type ColorVariant = 'default' | 'error' | 'primary';

export interface DrawerItemBadgeOptions {
  readonly label: string;
  readonly color?: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  readonly icon?: ReactElement;
}

export interface DrawerItemProps {
  sx?: SxProps;
  className?: string;
  icon?: ReactElement;
  title: string;
  subtitle?: string;
  badge?: DrawerItemBadgeOptions;
  iconBadgeVariant?: 'warning' | 'error';
  colorVariant?: ColorVariant;
  onClick?: () => void;
  selected?: boolean;
  disabled?: boolean;
  dense?: boolean;
  disablePadding?: boolean;
  link?: {
    to: string;
    state?: unknown;
    replace?: boolean;
    highlightPattern?: string | PathPattern;
  };

  externalLink?: {
    url: string;
    target?: HTMLAttributeAnchorTarget;
  };
}

export function DrawerItem({
  link,
  icon,
  title,
  subtitle,
  colorVariant = 'default',
  badge,
  iconBadgeVariant,
  externalLink,
  selected = false,
  disablePadding = false,
  ...props
}: DrawerItemProps) {
  const theme = useTheme();
  const matchesLink = useIsMatchPath(link?.highlightPattern);
  const itemStyle = {
    mx: !disablePadding ? `${theme.spacing(1)} !important` : undefined,
    borderRadius: 1,
    font: theme.typography.body2,
    color: matchesLink || selected ? theme.palette.primary.main : undefined,
    backgroundColor: matchesLink || selected ? theme.palette.action.hover : undefined,
    [':hover']: {
      backgroundColor: theme.palette.action.focus
    }
  };

  const content = () => (
    <>
      {icon != null && (
        <ListItemIcon sx={{ color: getColor(colorVariant, theme) }}>
          <Badge color={iconBadgeVariant} invisible={iconBadgeVariant == null} variant="dot">
            {icon}
          </Badge>
        </ListItemIcon>
      )}

      <ListItemText
        primary={title}
        slotProps={{
          primary: { color: getColor(colorVariant, theme) }
        }}
        secondary={subtitle}
      />

      {renderBadge(badge)}
    </>
  );

  if (link != null) {
    return (
      <ListItemButton
        {...props}
        sx={{ ...props.sx, ...itemStyle }}
        component={Link}
        to={link.to}
        state={link.state}
        replace={link.replace}
      >
        {content()}
      </ListItemButton>
    );
  }

  if (externalLink != null) {
    return (
      <ListItemButton
        {...props}
        sx={{ ...props.sx, ...itemStyle }}
        href={externalLink.url}
        target={externalLink.target}
        rel={externalLink.target === '_blank' ? 'noreferrer' : undefined}
      >
        {content()}
      </ListItemButton>
    );
  }

  return (
    <ListItemButton {...props} sx={{ ...props.sx, ...itemStyle }}>
      {content()}
    </ListItemButton>
  );
}

function getColor(variant: ColorVariant, theme: Theme): string | undefined {
  switch (variant) {
    case 'error':
      return theme.palette.error.main;
    case 'primary':
      return theme.palette.primary.main;
    case 'default':
      return undefined;
  }
}

function renderBadge(options: DrawerItemBadgeOptions | undefined) {
  if (options == null) {
    return null;
  }

  return (
    <Chip
      label={options.label}
      variant="filled"
      size="small"
      icon={options.icon}
      color={options.color}
      sx={{ cursor: 'pointer' }}
    />
  );
}
