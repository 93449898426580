import { InitializingScreen, ServerErrorScreen } from '@/components';
import { useBackButtonListener, useNavigateAsync, useServices } from '@/hooks';
import { App as CapacitorApp } from '@capacitor/app';
import { observer } from 'mobx-react-lite';
import { Outlet } from 'react-router';

export const InitialStateLayout = observer(() => {
  const { authentication } = useServices();
  const navigate = useNavigateAsync();

  useBackButtonListener((event, backButtonEvent) => {
    event.stopPropagation();
    if (backButtonEvent.canGoBack) {
      void navigate(-1);
    } else {
      void CapacitorApp.exitApp();
    }
  });

  // If there is an error with the authentication, we display the ServerErrorScreen.
  if (authentication.error != null) {
    return <ServerErrorScreen error={authentication.error} />;
  }

  if (!authentication.isInitialized) {
    return <InitializingScreen />;
  }

  return <Outlet />;
});
