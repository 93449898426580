import { useBackButtonListener, useServices } from '@/hooks';
import { Locale } from '@/utils';
import { css } from '@emotion/css';
import { DarkModeRounded, LightModeRounded, TranslateRounded } from '@mui/icons-material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import {
  AppBar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Tooltip,
  useTheme
} from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { MouseEvent, useState } from 'react';
import { Logo, MediaQuery } from '../utils';

export interface WelcomeAppBarProps {
  sx?: SxProps;
  className?: string;
}

export const WelcomeAppBar = observer(({ sx = [], className }: WelcomeAppBarProps) => {
  const { theme, localization } = useServices();
  const muiTheme = useTheme();

  const logoStyle = css([
    {
      height: 'auto',
      width: 80,

      [muiTheme.breakpoints.down('sm')]: {
        width: 50
      }
    }
  ]);

  const [localeAnchorEl, setLocaleAnchorEl] = useState<null | HTMLElement>(null);

  function handleLocaleButtonClick(event: MouseEvent<HTMLButtonElement>) {
    setLocaleAnchorEl(event.currentTarget);
  }

  function handleLocaleMenuClose(locale: Locale | undefined) {
    if (locale) {
      localization.trySetCurrentLocale(locale);
    }

    setLocaleAnchorEl(null);
    setCompactMenuAnchorEl(null);
  }

  const [compactMenuAnchorEl, setCompactMenuAnchorEl] = useState<null | HTMLElement>(null);

  function handleCompactMenuButtonClick(event: MouseEvent<HTMLButtonElement>) {
    setCompactMenuAnchorEl(event.currentTarget);
  }

  function handleCompactMenuClose() {
    setCompactMenuAnchorEl(null);
  }

  useBackButtonListener((e) => {
    if (localeAnchorEl != null) {
      e.stopPropagation();
      setLocaleAnchorEl(null);
    } else if (compactMenuAnchorEl != null) {
      e.stopPropagation();
      setCompactMenuAnchorEl(null);
    }
  });

  return (
    <>
      <AppBar
        sx={{ ...sx, borderBottom: `1px solid ${muiTheme.palette.divider}` }}
        className={className}
        elevation={0}
        position="relative"
        color="transparent"
      >
        <Toolbar>
          <Logo className={logoStyle} />

          <Box
            sx={{
              flex: 1
            }}
          />

          <MediaQuery mediaQuery={(th) => th.breakpoints.up('sm')}>
            <>
              <Stack direction="row" spacing={1}>
                <IconButton onClick={handleLocaleButtonClick}>
                  <TranslateRounded color={localeAnchorEl != null ? 'primary' : 'inherit'} />
                </IconButton>

                {theme.variant === 'light' && (
                  <Tooltip title={localization.localizedStrings.utils.splashSwitchToDarkModeTooltip}>
                    <IconButton onClick={() => theme.setVariant('dark')}>
                      <DarkModeRounded />
                    </IconButton>
                  </Tooltip>
                )}

                {theme.variant === 'dark' && (
                  <Tooltip title={localization.localizedStrings.utils.splashSwitchToLightModeTooltip}>
                    <IconButton onClick={() => theme.setVariant('light')}>
                      <LightModeRounded />
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
            </>
          </MediaQuery>

          <MediaQuery mediaQuery={(th) => th.breakpoints.down('sm')}>
            <IconButton onClick={handleCompactMenuButtonClick}>
              <MenuRoundedIcon color={compactMenuAnchorEl != null ? 'primary' : 'inherit'} />
            </IconButton>
          </MediaQuery>
        </Toolbar>
      </AppBar>
      <Menu
        open={localeAnchorEl != null}
        anchorEl={localeAnchorEl}
        onClose={() => handleLocaleMenuClose(undefined)}
        slotProps={{ paper: { sx: { width: 200 } } }}
      >
        <EnglishMenuItem onClick={() => handleLocaleMenuClose('en')} />
        <FrenchMenuItem onClick={() => handleLocaleMenuClose('fr')} />
      </Menu>
      <Menu
        open={compactMenuAnchorEl != null}
        anchorEl={compactMenuAnchorEl}
        slotProps={{ paper: { sx: { width: 300 } } }}
        onClose={() => handleCompactMenuClose()}
      >
        <EnglishMenuItem onClick={() => handleLocaleMenuClose('en')} />
        <FrenchMenuItem onClick={() => handleLocaleMenuClose('fr')} />

        <Divider />

        {theme.variant === 'dark' && (
          <MenuItem onClick={() => theme.setVariant('light')}>
            <ListItemIcon>
              <LightModeRounded />
            </ListItemIcon>
            <ListItemText>{localization.localizedStrings.utils.splashSwitchToLightModeTooltip}</ListItemText>
          </MenuItem>
        )}

        {theme.variant === 'light' && (
          <MenuItem onClick={() => theme.setVariant('dark')}>
            <ListItemIcon>
              <DarkModeRounded />
            </ListItemIcon>
            <ListItemText>{localization.localizedStrings.utils.splashSwitchToDarkModeTooltip}</ListItemText>
          </MenuItem>
        )}
      </Menu>
    </>
  );
});

function EnglishMenuItem({ onClick }: { onClick?: () => void }) {
  const { localization } = useServices();
  const theme = useTheme();

  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon sx={{ color: theme.palette.text.primary }}>🇺🇸</ListItemIcon>
      <ListItemText slotProps={{ primary: { color: localization.currentLocale === 'en' ? 'primary' : 'inherit' } }}>
        {/* Not localized */}
        English
      </ListItemText>
    </MenuItem>
  );
}

function FrenchMenuItem({ onClick }: { onClick?: () => void }) {
  const { localization } = useServices();
  const theme = useTheme();

  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon sx={{ color: theme.palette.text.primary }}>🇫🇷</ListItemIcon>
      <ListItemText slotProps={{ primary: { color: localization.currentLocale === 'fr' ? 'primary' : 'inherit' } }}>
        {/* Not localized */}
        Français
      </ListItemText>
    </MenuItem>
  );
}
