import { AllDayOfWeek, Day, DayOfWeek } from '@/models';
import { SpecialDayOccurrenceWhen } from '@/stores';
import { ScheduleCycleKind, SpecialDayOccurrenceInfo } from '@/viewmodels';
import { AddRounded } from '@mui/icons-material';
import {
  Box,
  Chip,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Popover,
  Stack,
  useTheme
} from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import LocalizedStrings from 'strings';
import { useBackButtonListener } from '../../../../../../hooks';
import { ScheduleCycleSpecialDayOccurrenceDayPicker } from '../ScheduleCycleSpecialDayOccurrenceDayPicker';
import { iconForSpecialDayOccurrence } from '../SpecialDayUtils';

export interface SpecialDayEditOccurrencesSectionProps {
  sx?: SxProps;
  className?: string;
  occurrences: SpecialDayOccurrenceInfo[];
  scheduleCycleKind: ScheduleCycleKind;
  possibleCycleDays: { title: string; value: number }[];
  minDay: Day | undefined;
  maxDay: Day | undefined;
  addOccurrence: (when: SpecialDayOccurrenceWhen) => void;
  removeOccurrence: (when: SpecialDayOccurrenceWhen) => void;
}

export const SpecialDayEditOccurrencesSection = observer(
  ({
    sx = [],
    className,
    occurrences,
    scheduleCycleKind,
    possibleCycleDays,
    minDay,
    maxDay,
    addOccurrence,
    removeOccurrence
  }: SpecialDayEditOccurrencesSectionProps) => {
    const theme = useTheme();
    const [addOccurrenceButtonRef, setAddOccurrenceButtonRef] = useState<HTMLDivElement | undefined>();
    const [addCycleDayOccurrenceButtonRef, setAddCycleDayOccurrenceButtonRef] = useState<HTMLLIElement | undefined>();
    const [addDayOfWeekOccurrenceButtonRef, setAddDayOfWeekOccurrenceButtonRef] = useState<HTMLLIElement | undefined>();
    const [addDayOccurrenceButtonRef, setAddDayOccurrenceButtonRef] = useState<HTMLLIElement | undefined>();

    function addOccurrenceForCycleDay(cycleDay: number) {
      setAddCycleDayOccurrenceButtonRef(undefined);
      setAddOccurrenceButtonRef(undefined);
      addOccurrence({ case: 'cycleDay', value: cycleDay });
    }

    function addOccurrenceForDayOfWeek(dow: DayOfWeek) {
      setAddDayOfWeekOccurrenceButtonRef(undefined);
      setAddOccurrenceButtonRef(undefined);
      addOccurrence({ case: 'dayOfWeek', value: dow });
    }

    function addOccurrenceForDays(days: Day[]) {
      setAddDayOccurrenceButtonRef(undefined);
      setAddOccurrenceButtonRef(undefined);
      days.forEach((day) => addOccurrence({ case: 'day', value: day }));
    }

    function deleteOccurrence(occurrence: SpecialDayOccurrenceWhen) {
      removeOccurrence(occurrence);
    }

    const strings = LocalizedStrings.scheduleCycle.edit.specialDays.edit;

    useBackButtonListener((e) => {
      if (addCycleDayOccurrenceButtonRef != null) {
        e.stopPropagation();
        setAddCycleDayOccurrenceButtonRef(undefined);
      } else if (addDayOfWeekOccurrenceButtonRef != null) {
        e.stopPropagation();
        setAddDayOfWeekOccurrenceButtonRef(undefined);
      } else if (addDayOccurrenceButtonRef != null) {
        e.stopPropagation();
        setAddDayOccurrenceButtonRef(undefined);
      } else if (addOccurrenceButtonRef != null) {
        e.stopPropagation();
        setAddOccurrenceButtonRef(undefined);
      }
    });

    return (
      <Stack sx={sx} className={className}>
        <Box sx={{ height: 40, overflow: 'hidden' }}>
          <ListSubheader>{strings.assignedToSectionTitle()}</ListSubheader>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', listStyle: 'none' }}>
          {occurrences.map((o, index) => (
            <Chip
              key={`special-day-occurrence-${index}`}
              sx={{ m: 0.5 }}
              icon={o.occurrence.case != null ? iconForSpecialDayOccurrence(o.occurrence.case) : undefined}
              label={o.title}
              onDelete={() => deleteOccurrence(o.occurrence)}
            />
          ))}

          <Chip
            sx={{ m: 0.5 }}
            label={strings.assignedToAddOccurrenceButtonTitle()}
            icon={<AddRounded />}
            color={addOccurrenceButtonRef != null ? 'primary' : undefined}
            onClick={(e) => setAddOccurrenceButtonRef(e.currentTarget)}
          />

          <Menu
            open={addOccurrenceButtonRef != null}
            anchorEl={addOccurrenceButtonRef}
            onClose={() => setAddOccurrenceButtonRef(undefined)}
            elevation={2}
            slotProps={{ paper: { sx: { minWidth: 250 } } }}
          >
            <MenuItem disabled>{strings.assignedToOccurrencePickerTitle()}</MenuItem>

            <MenuItem onClick={(e) => setAddDayOccurrenceButtonRef(e.currentTarget)}>
              <ListItemIcon>{iconForSpecialDayOccurrence('day')}</ListItemIcon>
              <ListItemText>{strings.assignedToOccurrencePickerSpecificDate()}</ListItemText>
            </MenuItem>

            <MenuItem onClick={(e) => setAddCycleDayOccurrenceButtonRef(e.currentTarget)}>
              <ListItemIcon>{iconForSpecialDayOccurrence('cycleDay')}</ListItemIcon>
              <ListItemText>
                {scheduleCycleKind === 'cycle-day'
                  ? strings.assignedToOccurrencePickerCycleDay()
                  : strings.assignedToOccurrencePickerCycleDayOfWeek()}
              </ListItemText>
            </MenuItem>

            <MenuItem onClick={(e) => setAddDayOfWeekOccurrenceButtonRef(e.currentTarget)}>
              <ListItemIcon>{iconForSpecialDayOccurrence('dayOfWeek')}</ListItemIcon>
              <ListItemText>{strings.assignedToOccurrencePickerDayOfWeek()}</ListItemText>
            </MenuItem>
          </Menu>

          <Menu
            open={addCycleDayOccurrenceButtonRef != null}
            anchorEl={addCycleDayOccurrenceButtonRef}
            anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
            transformOrigin={{ vertical: 'center', horizontal: 'center' }}
            onClose={() => setAddCycleDayOccurrenceButtonRef(undefined)}
            elevation={theme.palette.mode === 'light' ? 1 : 8}
            slotProps={{ paper: { sx: { minWidth: 250 } } }}
          >
            <MenuItem disabled>{strings.assignedToCycleDayPickerTitle()}</MenuItem>
            {possibleCycleDays.map((c) => (
              <MenuItem key={`add-cycle-day-occurrence-${c.value}`} onClick={() => addOccurrenceForCycleDay(c.value)}>
                <ListItemText>{c.title}</ListItemText>
              </MenuItem>
            ))}
          </Menu>

          <Menu
            open={addDayOfWeekOccurrenceButtonRef != null}
            anchorEl={addDayOfWeekOccurrenceButtonRef}
            anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
            transformOrigin={{ vertical: 'center', horizontal: 'center' }}
            onClose={() => setAddDayOfWeekOccurrenceButtonRef(undefined)}
            elevation={theme.palette.mode === 'light' ? 1 : 8}
            slotProps={{ paper: { sx: { minWidth: 250 } } }}
          >
            <MenuItem disabled>{strings.assignedToDayOfWeekPickerTitle()}</MenuItem>
            {AllDayOfWeek.map((dow) => (
              <MenuItem key={`add-day-of-week-occurrence-${dow}`} onClick={() => addOccurrenceForDayOfWeek(dow)}>
                <ListItemText>{LocalizedStrings.dateTime.dayOfWeekTitle[dow]()}</ListItemText>
              </MenuItem>
            ))}
          </Menu>

          <Popover
            open={addDayOccurrenceButtonRef != null}
            anchorEl={addDayOccurrenceButtonRef}
            onClose={() => setAddDayOccurrenceButtonRef(undefined)}
            elevation={theme.palette.mode === 'light' ? 1 : 8}
            anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
            transformOrigin={{ vertical: 'center', horizontal: 'center' }}
            slotProps={{ paper: { sx: { minWidth: 250 } } }}
          >
            <ScheduleCycleSpecialDayOccurrenceDayPicker
              onSubmit={(days) => addOccurrenceForDays(days)}
              minDay={minDay}
              maxDay={maxDay}
            />
          </Popover>
        </Box>
      </Stack>
    );
  }
);
