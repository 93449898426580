import { OnboardingStudentOptionsViewModel } from '@/viewmodels';
import { LoadingButton } from '@mui/lab';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { captureException } from '@sentry/react';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import LocalizedStrings from 'strings';
import { NavigationButton } from '../utils';

export interface StudentOnboardingChoicesProps {
  sx?: SxProps;
  className?: string;
  viewModel: OnboardingStudentOptionsViewModel;
}

export const StudentOnboardingOptions = observer(({ sx, className, viewModel }: StudentOnboardingChoicesProps) => {
  const [isCreatingPlanner, setIsCreatingPlanner] = useState<boolean>(false);
  const [hasCreatePlannerError, setHasCreatePlannerError] = useState(false);
  const [isSigningOut, setIsSigningOut] = useState(false);
  const strings = LocalizedStrings.onboarding.studentOptions;

  const onCreatePlannerOptionPress = async () => {
    try {
      setHasCreatePlannerError(false);
      setIsCreatingPlanner(true);
      await viewModel.createPlanner();
    } catch (e) {
      captureException(e);
      setHasCreatePlannerError(true);
    } finally {
      setIsCreatingPlanner(false);
    }
  };

  const onUsePlannerCodeOptionPress = () => {
    viewModel.showUseCode();
  };

  const onSchoolOptionPress = () => {
    viewModel.showSchoolNoAccount();
  };

  const onSignOutPress = async () => {
    setIsSigningOut(true);
    await viewModel.signOut();
  };

  return (
    <Box sx={sx} className={className} width="100%" display="flex" flexDirection="column">
      <Stack spacing={2}>
        <NavigationButton
          title={strings.personalTitle()}
          subtitle={strings.personalSubtitle()}
          onClick={() => void onCreatePlannerOptionPress()}
          disabled={isSigningOut || isCreatingPlanner}
        />

        {isCreatingPlanner && (
          <Stack direction="row" spacing={1} alignItems="center" px={2}>
            <CircularProgress size={16} />
            <Typography variant="caption" color="textSecondary">
              {strings.creatingPlanner()}
            </Typography>
          </Stack>
        )}

        {hasCreatePlannerError && (
          <Typography variant="caption" color="error">
            {strings.createPlannerError()}
          </Typography>
        )}

        <NavigationButton
          title={strings.withParentsTitle()}
          subtitle={strings.withParentsSubtitle()}
          onClick={onUsePlannerCodeOptionPress}
          disabled={isSigningOut || isCreatingPlanner}
        />

        <NavigationButton
          title={strings.schoolTitle()}
          subtitle={strings.schoolSubtitle()}
          onClick={onSchoolOptionPress}
          disabled={isSigningOut || isCreatingPlanner}
        />

        <LoadingButton
          loading={isSigningOut}
          disabled={isSigningOut || isCreatingPlanner}
          onClick={() => void onSignOutPress()}
        >
          {strings.signOut()}
        </LoadingButton>
      </Stack>
    </Box>
  );
});
