import { BackgroundLocationState } from '@/BackgroundLocationState';
import { RouteNames, RouteTemplates } from '@/RouteTemplates';
import { action, computed, makeObservable, observable } from 'mobx';
import { Location, RouteObject, matchPath, matchRoutes } from 'react-router';
import { RouteService } from '../contracts';

interface RouteParameterInfo {
  /**
   * The name of the parameter. See `RouteParamNames` for the list of possible values.
   */
  name: string;
  /**
   * The value for the parameter.
   */
  value: string;
}

export class AppRouteService implements RouteService {
  @observable private _dialogPresentedCount = 0;

  constructor() {
    makeObservable(this);
  }

  @computed
  get dialogPresentedCount(): number {
    return this._dialogPresentedCount;
  }

  @action
  onDialogPresent() {
    this._dialogPresentedCount++;
  }

  @action
  onDialogDismiss() {
    this._dialogPresentedCount--;
  }

  resolvePlannerSettingsLocation(subPath: string, plannerId: string): string {
    const basePath = this.resolvePlannerLocation(plannerId);
    return `${basePath}/settings/${subPath}`;
  }

  resolvePlannerScheduleConfigLocation(
    scheduleCycleId: string,
    subPath: string | undefined,
    plannerId: string
  ): string {
    let basePath = this.resolvePlannerLocation(plannerId);
    basePath += `/settings/schedules/${scheduleCycleId}`;
    return subPath != null ? `${basePath}/${subPath}` : basePath;
  }

  // PLANNER

  resolvePlannerLocation(plannerId: string): string {
    return this.resolveLocation(RouteTemplates.planner, [{ name: 'plannerId', value: plannerId }]);
  }

  resolvePlannerSchedulesLocation(plannerId: string): string {
    return this.resolveLocation(RouteTemplates.plannerSchedules, [{ name: 'plannerId', value: plannerId }]);
  }

  resolvePlannerCourseSectionDetailLocation(plannerId: string, courseSectionId: string): string {
    return this.resolveLocation(RouteTemplates.plannerCourseSectionDetails, [
      { name: 'plannerId', value: plannerId },
      { name: 'courseSectionId', value: courseSectionId }
    ]);
  }

  resolvePlannerWorkLocation(plannerId: string, workId: string): string {
    return this.resolveLocation(RouteTemplates.plannerWork, [
      { name: 'plannerId', value: plannerId },
      { name: 'workId', value: workId }
    ]);
  }

  resolvePlannerCourseSectionsLocation(plannerId: string): string {
    return this.resolveLocation(RouteTemplates.plannerCourses, [{ name: 'plannerId', value: plannerId }]);
  }

  resolvePlannerItemsLocation(plannerId: string): string {
    return this.resolveLocation(RouteTemplates.plannerItems, [{ name: 'plannerId', value: plannerId }]);
  }

  resolvePlannerShareDetails(plannerId: string): string {
    return this.resolveLocation(RouteTemplates.plannerShare, [{ name: 'plannerId', value: plannerId }]);
  }

  resolvePublishedWorkLocation(plannerId: string, workId: string, schoolId: string): string {
    return this.resolveLocation(RouteTemplates.publishedWork, [
      { name: 'plannerId', value: plannerId },
      { name: 'workId', value: workId },
      { name: 'schoolId', value: schoolId }
    ]);
  }

  // SCHOOL

  resolveSchoolCourseSectionDetailsLocation(plannerId: string, schoolId: string, courseSectionId: string): string {
    return this.resolveLocation(RouteTemplates.schoolCourseSectionDetails, [
      { name: 'plannerId', value: plannerId },
      { name: 'schoolId', value: schoolId },
      { name: 'courseId', value: courseSectionId }
    ]);
  }

  resolveSchoolCourseSectionDetailsStudentListLocation(
    plannerId: string,
    schoolId: string,
    courseSectionId: string
  ): string {
    return this.resolveLocation(RouteTemplates.schoolCourseSectionDetailsStudentList, [
      { name: 'plannerId', value: plannerId },
      { name: 'schoolId', value: schoolId },
      { name: 'courseId', value: courseSectionId }
    ]);
  }

  resolveSchoolStudentPlannerLocation(
    plannerId: string,
    schoolId: string,
    courseSectionId: string,
    studentId: string
  ): string {
    const searchParams = new Map<string, string>();
    searchParams.set('view', 'planner');

    return this.resolveLocation(
      RouteTemplates.schoolStudentDetails,
      [
        { name: 'plannerId', value: plannerId },
        { name: 'schoolId', value: schoolId },
        { name: 'courseId', value: courseSectionId },
        { name: 'studentId', value: studentId }
      ],
      searchParams
    );
  }

  resolveSchoolStudentInsightsLocation(
    plannerId: string,
    schoolId: string,
    courseSectionId: string,
    studentId: string
  ): string {
    const searchParams = new Map<string, string>();
    searchParams.set('view', 'insights');

    return this.resolveLocation(
      RouteTemplates.schoolStudentDetails,
      [
        { name: 'plannerId', value: plannerId },
        { name: 'schoolId', value: schoolId },
        { name: 'courseId', value: courseSectionId },
        { name: 'studentId', value: studentId }
      ],
      searchParams
    );
  }

  // ADMIN

  resolveAdminSchoolsListLocation(): string {
    return this.resolveLocation(RouteTemplates.adminSchoolsList);
  }

  resolveAdminSchoolInformationLocation(schoolId: string): string {
    return this.resolveLocation(RouteTemplates.adminSchoolGeneralInfo, [{ name: 'schoolId', value: schoolId }]);
  }

  resolveAdminSchoolClassesLocation(schoolId: string): string {
    return this.resolveLocation(RouteTemplates.adminSchoolClasses, [{ name: 'schoolId', value: schoolId }]);
  }

  resolveAdminSchoolSchedulesLocation(schoolId: string): string {
    return this.resolveLocation(RouteTemplates.adminSchoolSchedules, [{ name: 'schoolId', value: schoolId }]);
  }

  resolveAdminSchoolScheduleLocation(schoolId: string, scheduleCycleId: string): string {
    return this.resolveLocation(RouteTemplates.adminSchoolSchedule, [
      { name: 'schoolId', value: schoolId },
      { name: 'scheduleCycleId', value: scheduleCycleId }
    ]);
  }

  resolveAdminSchoolTeachersLocation(schoolId: string): string {
    return this.resolveLocation(RouteTemplates.adminSchoolTeachers, [{ name: 'schoolId', value: schoolId }]);
  }

  resolveAdminSchoolStudentsLocation(schoolId: string): string {
    return this.resolveLocation(RouteTemplates.adminSchoolStudents, [{ name: 'schoolId', value: schoolId }]);
  }

  resolveAdminClassDetailsLocation(schoolId: string, courseSectionId: string): string {
    return this.resolveLocation(RouteTemplates.adminSchoolClassDetails, [
      { name: 'schoolId', value: schoolId },
      { name: 'courseSectionId', value: courseSectionId }
    ]);
  }

  resolveAdminTeacherDetailsLocation(schoolId: string, teacherId: string): string {
    return this.resolveLocation(RouteTemplates.adminSchoolTeacherDetails, [
      { name: 'schoolId', value: schoolId },
      { name: 'teacherId', value: teacherId }
    ]);
  }

  resolveAdminStudentDetailsLocation(schoolId: string, studentId: string): string {
    return this.resolveLocation(RouteTemplates.adminSchoolStudentDetails, [
      { name: 'schoolId', value: schoolId },
      { name: 'studentId', value: studentId }
    ]);
  }

  resolveAdminClassScheduleEditLocation(schoolId: string, scheduleCycleId: string, courseSectionId: string): string {
    return this.resolveLocation(
      RouteTemplates.adminSchoolSchedule,
      [
        { name: 'schoolId', value: schoolId },
        { name: 'scheduleCycleId', value: scheduleCycleId }
      ],
      new Map([
        ['display', 'class-schedules'],
        ['course', courseSectionId]
      ])
    );
  }

  resolveAdminTeacherScheduleEditLocation(schoolId: string, scheduleCycleId: string, teacherId: string): string {
    return this.resolveLocation(
      RouteTemplates.adminSchoolSchedule,
      [
        { name: 'schoolId', value: schoolId },
        { name: 'scheduleCycleId', value: scheduleCycleId }
      ],
      new Map([
        ['display', 'class-schedules'],
        ['teacher', teacherId]
      ])
    );
  }

  // SHARED

  resolveConnectedToExternalSourceLocation(plannerId: string): string {
    return this.resolveLocation(RouteTemplates.connectToExternalSource, [{ name: 'plannerId', value: plannerId }]);
  }

  resolveSubscribeLocation(plannerId: string): string {
    const baseLocation = this.resolvePlannerLocation(plannerId);
    return `${baseLocation}/subscribe`;
  }

  resolveConnectedAppsLocation(plannerId: string): string {
    const baseLocation = this.resolvePlannerLocation(plannerId);
    return `${baseLocation}/connected-apps`;
  }

  getLocationShouldDisplayIntercomMessenger(location: Location): boolean {
    const state = (location.state ?? {}) as BackgroundLocationState;

    if (state.backgroundLocation != null) {
      return false;
    }

    const dialogRoutes: RouteObject[] = [{ path: RouteTemplates.plannerWork }];
    return (matchRoutes(dialogRoutes, location)?.length ?? 0) === 0;
  }

  getLocationName(route: string, routesStrings: Record<string, () => string>): string | undefined {
    const foundRoute = RouteNames.find((r) => {
      const matchedRouted = matchPath({ path: r.routeTemplate, end: true, caseSensitive: true }, route);
      return matchedRouted != null;
    });

    if (foundRoute != null) {
      return routesStrings[foundRoute.localizationKey]();
    }

    return undefined;
  }

  // Private methods

  /**
   * Takes a route template and return a new location object to use with the navigation.
   * @param routeTemplate The route template to use
   * @param routeParameters The list of parameters (names and values) to replace in the route template.
   * @param searchParams The list of search params to append to the pathname.
   * @returns Returns a `LocationDescriptorObject` built using
   * the current location and the resolved route template as the pathname.
   */
  private resolveLocation(
    routeTemplate: string,
    routeParameters?: RouteParameterInfo[],
    searchParams = new Map<string, string>()
  ): string {
    let pathname = routeTemplate;

    if (routeParameters != null) {
      routeParameters.forEach((paramInfo) => (pathname = pathname.replace(`:${paramInfo.name}`, paramInfo.value)));
    }

    if (searchParams.size > 0) {
      pathname += '?';
      const params: string[] = [];
      searchParams.forEach((value, key) => params.push(`${key}=${value}`));
      pathname += params.join('&');
    }

    return pathname;
  }
}
