import { useServices } from '@/hooks';
import { AllFulfilledUserPersonas } from '@/models';
import { UserPropertiesViewModel } from '@/viewmodels';
import { UserPersona } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/user_persona_pb';
import {
  Avatar,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Slider,
  Stack,
  StackProps,
  TextField,
  Typography
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import Cropper from 'react-easy-crop';
import { Point } from 'react-easy-crop/types';
import { ControlledSelect, Subheader } from '../utils';
import { UserPropertiesFileInput } from './UserPropertiesFileInput';

export interface UserPropertiesProps extends StackProps {
  readonly className?: string;
  readonly viewModel: UserPropertiesViewModel;
}

export const UserProperties = observer(({ className, viewModel, ...props }: UserPropertiesProps) => {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const { localization } = useServices();
  const strings = localization.localizedStrings.user;

  return (
    <Stack {...props} className={className} spacing={2}>
      {viewModel.isForRequiredName && <Typography variant="body2">{strings.noFullNameNotice}</Typography>}
      <Stack spacing={1}>
        <Subheader>{strings.informationSectionTitle}</Subheader>

        <Stack spacing={2}>
          <TextField
            autoFocus
            label={strings.fullNameLabel}
            value={viewModel.fullName}
            fullWidth
            disabled={viewModel.isApplying}
            onChange={(e) => (viewModel.fullName = e.target.value)}
          />

          {!viewModel.isForRequiredName && (
            <FormControl disabled={viewModel.isApplying}>
              <InputLabel>{strings.personaPickerLabel}</InputLabel>
              <ControlledSelect
                value={viewModel.persona}
                label={strings.personaPickerLabel}
                onChange={(event) => (viewModel.persona = event.target.value as UserPersona)}
              >
                {AllFulfilledUserPersonas.map((persona) => (
                  <MenuItem key={persona} value={persona}>
                    {strings.personaLabel(persona)}
                  </MenuItem>
                ))}
              </ControlledSelect>
            </FormControl>
          )}
        </Stack>
      </Stack>
      <Stack
        spacing={1}
        sx={{
          alignItems: 'start'
        }}
      >
        <Subheader>{strings.pictureLabel}</Subheader>
        {viewModel.isEditingPicture ? (
          <Stack
            direction="row"
            spacing={2}
            sx={{
              alignItems: 'start'
            }}
          >
            <Cropper
              style={{ containerStyle: { height: 250, width: 250, position: 'relative' } }}
              image={viewModel.pictureFile}
              crop={crop}
              zoom={zoom}
              zoomSpeed={0.1}
              aspect={1}
              cropShape="round"
              showGrid={false}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={(area, areaPixels) => viewModel.onCropComplete(area, areaPixels)}
            />
            <Slider
              style={{ minHeight: 250 }}
              orientation="vertical"
              min={1}
              max={4}
              value={zoom}
              step={0.1}
              onChange={(e, value) => setZoom(Number(value))}
            />
            <UserPropertiesFileInput viewModel={viewModel} />
            <Button variant="text" onClick={() => viewModel.resetFile()}>
              {strings.resetImageButton}
            </Button>
          </Stack>
        ) : (
          <Stack
            direction="row"
            spacing={2}
            sx={{
              alignItems: 'center'
            }}
          >
            <Avatar src={viewModel.pictureUrl} alt={viewModel.fullName} sx={{ width: 80, height: 80 }} />
            <UserPropertiesFileInput viewModel={viewModel} />
            <Button variant="text" onClick={() => viewModel.clearImage()} disabled={!viewModel.canClear}>
              {strings.clearImageButton}
            </Button>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
});
