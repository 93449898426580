import {
  plannerHasAccessKindsForUser,
  schoolHasAccessKindsForUser,
  UserDashboard,
  UserDashboardKind,
  UserDashboardPlanner,
  UserDashboardSchool
} from '@/models';
import { ServiceContainer } from '@/providers';
import {
  Loadable,
  mergeLoadableStates,
  PlannerDataStore,
  PublishScheduleCycleChangesResult,
  ScheduleCycleDataStore,
  SchoolDataStore,
  UserDataStore
} from '@/stores';
import { AccessKind as PlannerAccessKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/access_kind_pb';
import { ScheduleCycle } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/schedule_cycle_pb';
import { AccessKind as SchoolAccessKind } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/access_kind_pb';
import { computed, makeObservable } from 'mobx';
import { NavigateFunctionAsync } from '../../../utils';
import { UpdatableViewModel, UpdatableViewModelState } from '../index';

export interface ScheduleCycleDetailsViewModel extends UpdatableViewModel {
  readonly isReadOnly: boolean;
  readonly scheduleCycle: ScheduleCycle;
  readonly hasChanges: boolean;
  readonly hasSaveDraftError: boolean;

  removeScheduleCycle(parentLocation: string, navigate: NavigateFunctionAsync): Promise<void>;
  retrySaveDraft(): Promise<void>;
  undo(): Promise<void>;
  cancel(): Promise<void>;
  save(): Promise<PublishScheduleCycleChangesResult>;
}

export class AppScheduleCycleDetailsViewModel implements ScheduleCycleDetailsViewModel {
  constructor(
    private readonly _scheduleCycleId: string,
    private readonly _dashboardId: string,
    private readonly _dashboardKind: UserDashboardKind,
    private readonly _plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    private readonly _schoolStore: SchoolDataStore = ServiceContainer.services.schoolStore,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore
  ) {
    makeObservable(this);
  }

  @computed
  private get scheduleCycleStore(): ScheduleCycleDataStore {
    return this._userStore.getScheduleCycleStore(this._scheduleCycleId, {
      id: this._dashboardId,
      kind: this._dashboardKind
    });
  }

  @computed
  private get courseSectionsLoadable(): Loadable<unknown> {
    switch (this._dashboardKind) {
      case 'planner':
        return this._plannerStore.getCourseSectionsInPlanner(this._dashboardId);
      case 'school':
        return this._schoolStore.getCourseSections(this._dashboardId);
    }
  }

  @computed
  private get currentDashboard(): UserDashboard {
    switch (this._dashboardKind) {
      case 'planner': {
        const planner = this._userStore.getPlannerForId(this._dashboardId)!;
        return { kind: 'planner', planner } as UserDashboardPlanner;
      }

      case 'school': {
        const school = this._userStore.getSchoolForId(this._dashboardId)!;
        return { kind: 'school', school } as UserDashboardSchool;
      }
    }
  }

  @computed
  get isReadOnly(): boolean {
    const { userId } = this._userStore.user;

    switch (this.currentDashboard.kind) {
      case 'planner':
        return !plannerHasAccessKindsForUser(userId, this.currentDashboard.planner, PlannerAccessKind.FULL_ACCESS);

      case 'school':
        return !schoolHasAccessKindsForUser(userId, this.currentDashboard.school, SchoolAccessKind.FULL_ACCESS);
    }
  }

  @computed
  get scheduleCycle(): ScheduleCycle {
    return this.scheduleCycleStore.scheduleCycle;
  }

  @computed
  get hasData(): boolean {
    return this.scheduleCycleStore.state === 'fulfilled' && this.courseSectionsLoadable.hasData;
  }

  @computed
  get state(): UpdatableViewModelState {
    return mergeLoadableStates([this.scheduleCycleStore.state, this.courseSectionsLoadable.state]);
  }

  @computed
  get hasChanges(): boolean {
    return this.scheduleCycleStore.draftSessionId.length > 0;
  }

  @computed
  get hasSaveDraftError(): boolean {
    return this.scheduleCycleStore.hasSaveDraftError;
  }

  async reloadData(): Promise<void> {
    await Promise.all([this.scheduleCycleStore.fetch(), this.courseSectionsLoadable.fetch(true)]);
  }

  async removeScheduleCycle(parentLocation: string, navigate: NavigateFunctionAsync) {
    await this._userStore.removeScheduleCycleFromUserDashboard(this.scheduleCycle.id, {
      kind: this._dashboardKind,
      id: this._dashboardId
    });
    await navigate(parentLocation, { replace: true });
  }

  async undo() {
    await this.scheduleCycleStore.undo();
  }

  async cancel() {
    await this.scheduleCycleStore.cancelChanges();
  }

  async save(): Promise<PublishScheduleCycleChangesResult> {
    return await this.scheduleCycleStore.saveChanges();
  }

  async retrySaveDraft(): Promise<void> {
    await this.scheduleCycleStore.retrySaveDraft();
  }
}
